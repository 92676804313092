import React from 'react';
import ReactDOM from 'react-dom';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import {
    SIGNUP_FORM_ACCOUNT_EXISTS_ERROR,
    SIGNUP_FORM_INVALID_CODE_ERROR
} from 'appEvents';

export default ({session, couponCode, location, submitSignUpHandler, errorType, error}) => (
    <div style={{minHeight: '43rem'}} className="flex flex-column w-100 h-100 bg-lightpink">
        <AuthTopBar location={location}/>
        <div className="flex flex-column center w-100 mw6">
            <div className="flex flex-row justify-center align-center center">
                <h1 className="title justify-center">Sign Up</h1>
            </div>
            <BlockContainer row className="items-center justify-center">
                <form className="w-100 pa5 flex flex-column items-center justify-center w-100 h-32rem">
                    <div className="h2 mistake flex flex-row w-100 items-center justify-center">
                        {(errorType === SIGNUP_FORM_ACCOUNT_EXISTS_ERROR || errorType === SIGNUP_FORM_INVALID_CODE_ERROR) && error}
                    </div>
                    <div className="pb1 w-100">
                        <div className="flex flex-row">
                            <p className="label">Name</p>
                            <p className="pl2 label-error">{session.shouldShowErrorMsg('username') ? session.state.errors.username : ''}</p>
                        </div>
                        <input
                            type="text"
                            name="username"
                            placeholder="Your Name"
                            onBlur={session.handleBlur('username')}
                            className={session.shouldMarkError('username') ? "error w-100" : "w-100"}
                            onChange={session.handleSignUpInputChange}
                            value={session.state.username}
                        />
                    </div>
                    <div className="pb1 w-100">
                        <div className="flex flex-row">
                            <p className="label">Email</p>
                            <p className="pl2 label-error">{session.shouldShowErrorMsg('email') ? session.state.errors.email : ''}</p>
                        </div>
                        <input
                            type="email"
                            name="email"
                            placeholder="john@example.com"
                            onBlur={session.handleBlur('email')}
                            className={session.shouldMarkError('email') ? "error w-100" : "w-100"}
                            onChange={session.handleSignUpInputChange}
                            value={session.state.email}
                        />
                    </div>
                    <div className="pb1 w-100">
                        <div className="flex flex-row">
                            <p className="label">Coupon</p>
                            <p className="pl2 label-error">{session.shouldShowErrorMsg('coupon') ? session.state.errors.coupon : ''}</p>
                        </div>
                        <input
                            type="text"
                            name="coupon"
                            placeholder="Coupon"
                            onBlur={session.handleBlur('coupon')}
                            className={session.shouldMarkError('coupon') ? "error w-100" : "w-100"}
                            onChange={session.changeHandler}
                            value={session.state.coupon}
                        />
                    </div>
                    <div className="pb1 w-100">
                        <div className="flex flex-row">
                            <p className="label">Stack Coupon</p>
                            <p className="pl2 label-error">{session.shouldShowErrorMsg('coupon') ? session.state.errors.company : ''}</p>
                        </div>
                        <input
                            type="text"
                            name="stackCoupon"
                            placeholder="Stack Coupon (optional)"
                            onBlur={session.handleBlur('stack_coupon')}
                            className={session.shouldMarkError('coupon') ? "error w-100" : "w-100"}
                            onChange={session.changeHandler}
                            value={session.state.stackCoupon}
                        />
                    </div>
                    <div className="pb3 w-100">
                        <div className="flex flex-row">
                            <p className="label">Password</p>
                            <p className="pl2 label-error">{session.shouldShowErrorMsg('password') ? session.state.errors.password : ''}</p>
                        </div>
                        <input
                            name="password"
                            type="password"
                            onBlur={session.handleBlur('password')}
                            className={session.shouldMarkError('password') ? "error w-100" : "w-100"}
                            onChange={session.handleSignUpInputChange}
                            value={session.state.password}
                        />
                    </div>
                    <div className="pb1 w-100">
                        <button
                            type="button"
                            onClick={session.submitSignupAppSumoHandler}
                            className="sessionButton">
                            Sign Up
                        </button>
                    </div>
                </form>
            </BlockContainer>
        </div>
        <style jsx="true">{`
          .error {
            border: 1px solid red;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: #F65763;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:1.0rem;
          }
          .h-32rem {
            height: 32rem;
          }
          .sessionButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.8rem;
            font-weight:600;
            width: 100%;
          }
          .step {
            width: 28px;
            height: 28px;
            padding-top: 3px;
            border: 2px solid white;
            border-radius: 2rem;
          }
          .active-step {
            color: #A94AEF;
            border: 2px solid #A94AEF;
            border-radius: 2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
          input {
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
          }
          input:hover {
            border: solid 1px #7049E2;
          }
          input {
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
            height: 3rem;
            line-height: 3rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
            padding-top:0.2rem;
          }
          .label-error {
            font-family: 'Open Sans', sans-serif;
            color: red;
            font-weight: 700;
            font-size: 0.6rem;
            padding-top:0.2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
    `}</style>
    </div>
)