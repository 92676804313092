import React from 'react';
import _ from 'lodash';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import FanPagesList from 'components/FanPagesList';
import ComponentDidMount from 'containers/ComponentDidMount';
import Loader from 'components/Loader';

const selectAll = () => {
  // TODO
  console.log('selectAll');
};

const deselectAll = () => {
  // TODO
  console.log('deselectAll');
};

const FacebookFanPageList = ({
  location,
  registration,
  fanPages,
  userContainer,
}) => {
  const fbPages = fanPages.state.list;
  const subscribedAny = fbPages.find(p => p.subscribed === true);
  const { nextStep, prevStep } = registration;
  const { stack_coupon, loading } = registration.state;
  const isTrial = !_.isEmpty(stack_coupon.code) || __trial === 'true';
  return (
    <ComponentDidMount exec={() => {
      fanPages.loadPagesSDK();
      fbq('trackCustom', 'ConnectedFBPage');
    }}
    >
      <div className="flex flex-column w-100 vh-100-l h-100 bg-lightpink">
        <AuthTopBar location={location} />
        <div className="flex flex-column justify-center items-center fg1 h-100 min-h-500px-l mb6-l">
          <div className="w-50rem-l w-100 mw7-l mw6 mt0-ns mt5-l mt4-m mt3 flex min-h-340px-l">
            <BlockContainer row>
              <div className="w-60-l w-100 pa5-ns pa4 pt3rem-m-s flex flex-column">
                <div className="dn-l flex flex-row justify-between mb-1-5-rem">
                  <div className="tc step moon-gray">1</div>
                  <div className="tc step active-step">2</div>
                  { !isTrial && <div className="tc step moon-gray">3</div>}
                </div>
                <div className="flex flex-row flex-wrap justify-between items-center w-100 pb4-l pb2">
                  <h1 className="title mr2 ml2-l ml0 mv0">Select the pages</h1>
                  <div className="flex flex-row justify-center mb2 mt-0-6-rem">
                    {/* {fbPages && <span onClick={() => { */}
                    {/*    selectAll() */}
                    {/* }} className="lineButton pointer mr1">Select all</span>} */}
                    {/* {fbPages && <span onClick={() => { */}
                    {/*    deselectAll() */}
                    {/* }} className="lineButton pointer">Deselect all</span>} */}
                  </div>
                </div>
                <div className="dn-l dib f7 moon-gray lh-copy mb4">Pick the ones that you'd like to connect</div>

                {loading && (
                <div className="pa4-ns pa3 pl3-m flex flex-column w-100 vh-100-l h-100 items-center justify-center">
                  <Loader center />
                </div>
                )}

                {!loading && <FanPagesList fanPages={fanPages} />}

                <div className="flex flex-row justify-between items-center w-100">
                  <button
                    className="ttu pointer outlineButton w-10rem-l w-100"
                    onClick={() => {
                      prevStep();
                    }}
                  >
                    previous step
                  </button>

                  <button
                    className={`ttu ${!subscribedAny ? '' : 'pointer'} filledButton w-10rem-l w-100`}
                    style={{ opacity: (!subscribedAny) ? 0.4 : 1 }}
                    onClick={() => {
                      if (isTrial) {
                        subscribedAny && registration.createAccount(fbPages, userContainer);
                      } else {
                        subscribedAny && nextStep();
                      }
                    }}
                  >
                    {isTrial ? 'finish' : 'next step'}
                  </button>

                </div>
              </div>
              <div className="w-40 pa5 shadow dib-l dn">
                <div className="flex flex-row justify-between">
                  <div className="tc step moon-gray">1</div>
                  <div className="tc step active-step">2</div>
                  {!isTrial && <div className="tc step moon-gray">3</div>}
                </div>
                <div className="tc mt5">
                  <img width="40px" src="/fanpages.svg" />
                  <h1 className="f7 mt3">Select the fanpages</h1>
                  <div
                    className="f7 mt3 moon-gray lh-copy mw10rem center"
                  >
                    Pick the ones that you'd like to connect
                  </div>
                </div>
              </div>
            </BlockContainer>
          </div>
        </div>
        <style jsx="true">
          {`
          .mt-0-6-rem {
            margin-top: 0.6rem;
          }
          .mw10rem {
            max-width: 10rem;
          }
          .lineButton {
            color: #7049E2;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 0.75rem;
          }
          .outlineButton {
            color: #7049E2;
            border: 1px solid #EAD7FB;
            border-radius: 5rem;
            background: white;
            height: 3rem;
            line-height: 2.9rem;
            outline: none;
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight:600;
          }
          .filledButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight:600;
          }
          .mb-1-5-rem {
            margin-bottom: 1.5rem;
          }
          @media screen and (max-width: 60em) {
            .pt3rem-m-s {
              padding-top: 3rem;
            }
            .step {
              width: 35px;
              height: 35px;
              font-size: 20px;
              padding-top: 3px;
              border: 2px solid white;
              border-radius: 2rem;
            }
            .active-step {
              color: #A94AEF;
              border: 2px solid #A94AEF;
              border-radius: 2rem;
            }
          }
          @media screen and (min-width: 60em) {
            .w-10rem-l {
              width: 10rem;
            }
            .step {
              width: 28px;
              height: 28px;
              padding-top: 3px;
              border: 2px solid white;
              border-radius: 2rem;
            }
            .w-50rem-l {
              width: 50rem;
            }
            .min-h-500px-l {
              min-height:500px;
            }
            .min-h-340px-l {
              min-height:340px;
            }
            .active-step {
              color: #A94AEF;
              border: 2px solid #A94AEF;
              border-radius: 2rem;
            }
          }
        `}
        </style>
        <style global="true" jsx="true">
          {`
          div[data-react-class="App"], div[data-react-class="App"] > div {
            height: 100%;
            width: 100%;
          }
        `}
        </style>
      </div>
    </ComponentDidMount>
  );
};

export default FacebookFanPageList;
