import { Container } from 'unstated';
import _ from 'lodash';

const request = require('superagent');

const errorModalNoAdminRights = {
  showErrorModal: true,
  modalErrorMessage: 'Admin permissions are required on at least one page to use Sour Grapes. Currently none of your pages have admin permissions. Please ask your page administrator to assign proper permissions so you are able to use Sour Grapes.',
};

const sortByName = pages => pages.sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

const hasAdminRights = scopes => ['CREATE_CONTENT', 'MODERATE', 'MANAGE'].some(scope => scopes.includes(scope));

const getPageSubState = (pageId, pageToken) => {
  const appId = process.env.FB_APP_ID || 0;
  return new Promise(async (resolve, reject) => {
    await FB.api(`${pageId}?fields=subscribed_apps`, 'GET', { access_token: pageToken }, (response) => {
      if (response && !response.error) {
        if (_.has(response.subscribed_apps, 'data')) {
          resolve(!_.isNil(_.find(response.subscribed_apps.data, app => app.id === appId)));
        } else {
          resolve(false);
        }
      } else {
        console.log('Error: ', response);
        reject();
        return false;
      }
    });
  });
};

const addSubState = (pageList, cb) => {
  const promiseList = pageList.map(item => (
    getPageSubState(item.id, item.access_token)
  ));
  Promise.all(promiseList)
    .then((data) => {
      const result = pageList.map((page, i) => (
        { ...page, subscribed: data[i], hasAdminRights: _.has(page, 'tasks') ? hasAdminRights(page.tasks) : false }));
      cb && cb(result);
    })
    .catch(e => e);
};

const getAllPages = () => new Promise((resolve, reject) => {
  let data = [];
  function recursiveAPICall(url) {
    FB.api(url, (response) => {
      if (response && !response.error) {
        data = data.concat(response.data);
        if (response.paging && response.paging.next) {
          recursiveAPICall(response.paging.next);
        } else {
          resolve(data);
        }
      } else {
        reject();
      }
    });
  }
  recursiveAPICall('/me/accounts?limit=25');
});

class FanPagesContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      igAccount: null,
      list: [],
      subscribedPages: null,
      subscribedRequested: false,
      currentPage: null,
      error: null,
      showModal: false,
      showErrorModal: false,
      modalErrorMessage: '',
    };
    this.getAll = this.getAll.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
    this.loadPagesSDK = this.loadPagesSDK.bind(this);
    this.subscribeToggle = this.subscribeToggle.bind(this);
    this.subscribeToggleSDK = this.subscribeToggleSDK.bind(this);
    this.subscribeToggleLocal = this.subscribeToggleLocal.bind(this);
    this.adPostToggle = this.adPostToggle.bind(this);
    this.sentimentToggle = this.sentimentToggle.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.subscribetoAllSelected = this.subscribetoAllSelected.bind(this);
    this.getSubscribed = this.getSubscribed.bind(this);
    this.pageIconUrl = this.pageIconUrl.bind(this);
    this.unsubscribeAll = this.unsubscribeAll.bind(this);
  }

  unsubscribeAll() {
    request
      .post('/api/v1/pages/unsubscribe-all')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({})
      .end((error, response) => {
        console.log('response: ', response);
        if (error) {
        } else {
          console.log('Finished');
        }
      });
  }

  pageIconUrl(listItem) {
    const id = _.isNil(listItem.web_id) ? listItem.id : listItem.web_id;
    if (id) {
      return `https://graph.facebook.com/${id}/picture?type=square`;
    }
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsHm6+63u77HzM/f4+bP09bFycy8wMPi5+rr8fTAxMfM0NPX3N++wsXh5eiWmqd3AAACiUlEQVR4nO3b13KDMBBAUZpoovz/3yYIm1CEaZqR1rnnLZ7Eww1LsyGKAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADe1W74zthTR21WOJC1SaCNbZnGTqRp67vFqnXUZ+S+ayx67TAwjpXvnq1mXIXpc+Z9dHCbYmJWYdfkz5l3ShPfRWtVNyyWkz2EMoXBjWlfOlusyt1buUThBVIKk6qq7u0tZBQmbaO1zvL+xk5fQmFdvQ//XXs9UUKhKqdzk/T6+ZeAwmpxAnf5GBl+YZ0vzsEvn3+FXxgtLzIuL2v4hf2qMN9biYn9aBJ+4fpCMdsrbLQ18XsKszTVleX18AvPTWliLigLS2L4hef2NM1rT7sd1PALTx0tsvfvbAc1/MITR/yk+fsnbAZVQOHxWVuzWMmrQZVQeHTmnS231NWgSij8fPU0H1HboMoo/HQF3MQbi0GVUrgrs302Ph9U4YXbEd0MqvBCy4iuB1V2oXVEV4MqrFDNX9ob0eWgiiqsq07PDhi7I7oYVFGFavg2o3//9GFE54MqqLBW5tRGj69+HlFjPMWTU1ib76OGRDOoByMqsVB106L/Jh6OqLjC14i+1mJ7PKLSCqcRHZX2JMmFqjvKkV24GNFvLFyN6BcW3hpRQYU3R1RQ4c0RFVR4/wY3Cr2ikEIK/ftXharIbirM91ThF0bJfcOfCyh8iEJPxsXqj3/x7FuFV2ju884fbIKT1uxWbfeheJUUw3KVt3ejM+YzndR30Fbu8pGZOC6Ce95idQPGU6WTLdqx2Q0YzwPDfLRLaQePBBldcDvSl7p38EzQLxXeNjj57kdIAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOAf+QGg0Sz/T2GZ1QAAAABJRU5ErkJggg==';
  }

  openModal() {
    this.setState({ fanPages: null, showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  adPostToggle(id) {
    const { fanPages, subscribedPages, currentPage } = this.state;
    const adpostonly = !currentPage.adpostonly;
    request
      .post('/api/v1/pages/toggle_adpostonly')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({ id })
      .end((error, response) => {
        if (error) {
          console.log('Ad Post Toggle: ', error);
          localStorage.removeItem('authToken');
        } else if (response) {
          const newFanPages = sortByName([
            ...(fanPages || []).filter(el => (el.id !== id)), { ...currentPage, adpostonly },
          ]);
          const newSubscribedPages = sortByName([
            ...(subscribedPages || []).filter(el => (el.id !== id)), { ...currentPage, adpostonly },
          ]);
          const newCurrentPage = { ...currentPage, adpostonly };
          this.setState({
            list: newFanPages,
            subscribedPages: newSubscribedPages,
            currentPage: newCurrentPage,
          });
        }
      });
  }

  sentimentToggle(id) {
    const { fanPages, subscribedPages, currentPage } = this.state;
    const sentiment = !currentPage.sentiment;
    request
      .post('/api/v1/pages/toggle_sentiment')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({ id })
      .end((error, response) => {
        if (error) {
          console.log('Sentiment Toggle: ', error);
          localStorage.removeItem('authToken');
        } else if (response) {
          const newFanPages = sortByName([
            ...(fanPages || []).filter(el => (el.id !== id)), { ...currentPage, sentiment },
          ]);
          const newSubscribedPages = sortByName([
            ...(subscribedPages || []).filter(el => (el.id !== id)), { ...currentPage, sentiment },
          ]);
          const newCurrentPage = { ...currentPage, sentiment };
          this.setState({
            list: newFanPages,
            subscribedPages: newSubscribedPages,
            currentPage: newCurrentPage,
          });
        }
      });
  }

  selectAll() {
    const { fanPages, currentPage } = this.state;
    fanPages.forEach((item, i) => {
      this.subscribeToggle(item.id, true);
    });
  }

  deselectAll() {
    const { fanPages, currentPage } = this.state;
    fanPages.forEach((item, i) => {
      if (item.selected) {
        this.subscribeToggle(item.id, false);
      }
    });
  }

  closeErrorModal() {
    this.setState({
      showErrorModal: false,
      modalErrorMessage: '',
    });
  }

  loadPagesSDK() {
    const timerId = setInterval(() => {
      if (!_.isNil(FB)) {
        clearInterval(timerId);
        FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            getAllPages().then((result) => {
              const setPageList = (list) => {
                this.setState({ error: null, list });
              };
              addSubState(result, setPageList);
            }).catch((e) => {
              this.setState({
                error: `Pages load error: ${e}`,
                list: [],
              });
              console.log(`Load pages error: ${e}`);
            });
          } else {
            console.log('SDK Not connected');
          }
        });
      } else {
        console.log('FB SDK Not Initialized');
      }
    }, 100);
  }

  subscribeToggleSDK(id, value, token) {
    const { list } = { ...this.state };
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        const url = `${id}/subscribed_apps?subscribed_fields=feed,name,picture,category`;
        const method = value === true ? 'DELETE' : 'POST';
        FB.api(url, method, { access_token: token }, (response) => {
          if (response && !response.error) {
            const updatedFbPages = list.map(page => (page.id === id ? ({
              ...page,
              subscribed: page.subscribed === true ? false : response.success,
              subToggleLoading: false,
            }) : (page)));
            this.setState({
              list: updatedFbPages,
              showErrorModal: false,
            });
          } else {
            // TODO Show an error why it can not be subscribed in UI
            const updatedFbPages = list.map(page => (page.id === id ? ({
              ...page,
              subscribed: false,
              subToggleLoading: false,
            }) : (page)));
            console.log('Signup Page Subscribe Error: ', response);
            this.setState({
              list: updatedFbPages,
              showErrorModal: true,
              modalErrorMessage: `Error occurred during page subscription: ${JSON.stringify(response.body)}`,
            });
          }
        });
      } else {
        console.log('SDK Not connected');
      }
    });
  }

  subscribeToggleLocal(id, value) {
    const { list } = { ...this.state };
    request
      .post('/api/v1/pages/toggle_subscribed')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({ id })
      .end((error, response) => {
        if (error) {
          this.setState({
            subToggleLoading: false,
            showErrorModal: true,
            modalErrorMessage: `Error occurred during page subscription: ${JSON.stringify(response.body)}`,
          });
        } else {
          const updatedFbPages = list.map(page => (page.id === id ? ({
            ...page,
            subscribed: response.body.subscribed,
            subToggleLoading: false,
          }) : page));
          const currentPage = updatedFbPages.find(el => el.id === id);
          this.setState({
            list: updatedFbPages,
            currentPage: _.isNil(currentPage) ? _.first(updatedFbPages) : currentPage,
            showErrorModal: false,
          });
        }
      });
  }

  subscribeToggle(id) {
    const { list } = this.state;
    const page = list.find(el => (el.id === id));
    const toggleSubList = list.map(page => (page.id === id ? ({
      ...page,
      subToggleLoading: true,
    }) : page));
    this.setState({ list: toggleSubList });
    const { pathname } = window.location;
    if (!_.isNil(FB) && pathname.includes('signup')) {
      this.subscribeToggleSDK(page.id, page.subscribed, page.access_token);
    } else {
      this.subscribeToggleLocal(id, !page.subscribed);
    }
  }

  subscribetoAllSelected(cb) {
    const fanPages = this.state.list;
    const reqFunc = (page, subscribedVal) => new Promise((resolve, reject) => {
      request
        .patch(`/api/v1/pages/${page.id}`)
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({
          page: {
            subscribed: subscribedVal,
          },
        })
        .end((error, response) => {
          error && reject(error);
          !error && resolve(response);
        });
    });
    const selectedPagesReqs = [
      ...fanPages.filter(el => (el.selected === true)).map(p => (
        reqFunc(p, true)
      )),
      ...fanPages.filter(el => (el.selected === false && el.subscribed === true)).map(p => (
        reqFunc(p, false)
      )),
    ];
    Promise.all(selectedPagesReqs).then((values) => {
      cb();
    }).catch((err) => {
      cb(err);
    });
  }

  setCurrent(id, cb) {
    const { subscribedPages } = this.state;
    const page = subscribedPages.find(el => (el.id === id));
    const currentPage = page || subscribedPages[0];
    this.setState({
      currentPage,
    });
    cb && cb();
  }

  getSubscribed(cb) {
    const { currentPage } = this.state;
    request
      .get('/api/v1/pages?subscribed=true')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, res) => {
        if (error) {
          this.setState({ error });
          cb && cb(error);
        } else {
          this.setState({
            subscribedPages: sortByName(res.body),
            currentPage: currentPage === null ? _.first(res.body) : currentPage,
          });
          cb && cb();
        }
      });
  }

  getAll(cb) {
    const { currentPage } = this.state;
    const token = localStorage.getItem('authToken');
    if (token) {
      request
        .get('/api/v1/pages')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .end((error, res) => {
          if (error) {
            this.setState({ error });
          } else {
            const list = sortByName(res.body);
            const subscribedPages = list.filter(page => page.subscribed === true);
            const currentFilter = list.filter(page => page.current === true);
            const current = _.isEmpty(currentFilter) ? _.first(list) : currentPage === null ? _.first(currentFilter) : currentPage;
            const noAdminRights = _.size(list.filter(page => (page.hasAdminRights === true))) === 0;
            this.setState({
              list,
              subscribedPages,
              currentPage: current,
              ...(noAdminRights === true ? errorModalNoAdminRights : {}),
            });
            cb && cb();
          }
        });
    }
  }
}

export default FanPagesContainer;
