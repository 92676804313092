import React from 'react';
import Swiper from 'swiper';

class FromTheCommunity extends React.Component{
  componentDidMount() {
    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      }
    });
  }
  
  render() {
    return (
      <div className="relative from_the_community">
        <img className="absolute img1" src="/comm1.png"/>
        <img className="absolute img2" src="/comm2.png"/>
        <img className="absolute img3" src="/comm3.png"/>
        <img className="absolute img7" src="/comm7.png"/>
        <img className="absolute img5" src="/comm5.png"/>
        <img className="absolute img6" src="/comm6.png"/>
      
        <div className="center_block">
          <img className="absolute img_zap" src="/quotes.svg"/>
        
          <div className="title_grey">
            Take it from
          </div>
          <div className="title">
            the community
          </div>
          
          <div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <ul>
                    <li className="li_img">
                      <img src="/comm4.png"/>
                    </li>
                    <li className="li_text">
                      Sour Grapes helped me in maitaining hearty fanbase without fear
                      of waking up at 4 AM with an unexpected drama going on.
                    </li>
                    <li className="li_description">
                      Denis Olsen, Instagram influencer
                    </li>
                  </ul>
                </div>
                <div className="swiper-slide">
                  <ul>
                    <li className="li_img">
                      <img src="/comm4.png"/>
                    </li>
                    <li className="li_text">
                      Sour Grapes helped me in maitaining hearty fanbase without fear
                      of waking up at 4 AM with an unexpected drama going on.
                    </li>
                    <li className="li_description">
                      Denis Olsen, Instagram influencer
                    </li>
                  </ul>
                </div>
                <div className="swiper-slide">
                  <ul>
                    <li className="li_img">
                      <img src="/comm4.png"/>
                    </li>
                    <li className="li_text">
                      Sour Grapes helped me in maitaining hearty fanbase without fear
                      of waking up at 4 AM with an unexpected drama going on.
                    </li>
                    <li className="li_description">
                      Denis Olsen, Instagram influencer
                    </li>
                  </ul>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
      
      
        </div>
        <style jsx="true">{`
          .from_the_community{
            background: linear-gradient(to top, #f5f6fa, #ffffff);
            padding: 200px 0 300px;
          }
          
          .swiper-container {
            width: 100%;
            height: 100%;
          }
          .swiper-slide {
            text-align: center;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
          }
          
          /* ------------------ */
          
          
          .title_grey{
            text-align:center;
            color: #9892a9;
            font-size: 32px;
            line-height: 54px;
            font-weight: 300;
            font-family: 'Roboto',sans-serif;
          }
          
          .title{
            text-align:center;
            font-size: 32px;
            line-height: 54px;
            color: #382C58;
            margin-bottom:120px;
            font-weight:400;
            font-family: 'Roboto',sans-serif;
          }
          
          .img1{
            top:42%;
          }
          
          .img2{
            top:60%;
            left:70px;
          }
          
          .img3{
            top:71%;
            left:157px;
          }
          
          .img5{
            top:66%;
            right:15%;
          }
          
          .img6{
            top:43%;
            right:12%;
          }
          
          .img7{
            top:57%;
            right:1%;
          }
          
          .li_text{
            font-family: 'Roboto',sans-serif;
            color: #6d5f79;
            font-size: 24px;
            font-weight: 300;
            line-height: 48px;
            margin-top:36px;
            max-width:780px;
          }
          
          .li_description{
            color: #9892a9;
            font-size: 13px;
            /* font-weight: 900; */
            text-transform: uppercase;
            margin-top:48px;
          }
          
          .swiper-pagination{
            position:relative;
            margin-top:100px;
          }
          
          .swiper-pagination-bullet{
            margin:0 10px!important;
          }
          
          .img_zap{
            top:51%;
            height:180px;
            left:6%;
          }
          
          .center_block{
            padding:0 16px;
          }
          @media only screen and (max-width:1199px) {
            .img1,
            .img2,
            .img3,
            .img5,
            .img6,
            .img7{
              display:none;
            }
          }
        `}</style>
      </div>
    );
  }
}

export default FromTheCommunity;
