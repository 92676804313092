import React from 'react';

import _ from 'lodash';
import ComponentDidMount from 'containers/ComponentDidMount';
import PathValidator from 'components/PathValidator';
import MenuSidebar from 'components/MenuSidebar';
import SubscribeModal from 'components/SubscribeModal';
import MobileSidebar from 'components/MobileSidebar';
import FacebookAccountBlock from 'components/FacebookAccountBlock';
import InstagramAccountBlock from 'components/InstagramAccountBlock';
import CreditCardForm from 'components/CreditCardForm';
import CurrentTier from 'components/CurrentTier';
import FreeTrialTier from 'components/FreeTrialTier';
import PaymentHistory from 'components/PaymentHistory';
import Notifications from 'components/Notifications';
import AddCouponCode from 'components/AddCouponCode';
import AppSumoCommentsCounter from 'components/AppSumoCommentsCounter';

const Settings = ({
  location,
  getGlobalSidebarData,
  router,
  fanPages,
  canRenderSidebar,
  selectPage,
  userContainer,
  billing,
  userDataReady,
  couponCode,
  instagram,
  facebook,
}) => (
  <ComponentDidMount exec={() => {
    getGlobalSidebarData();
    billing.getStripeAccounts();
  }}
  >
    <PathValidator targetPath={router.state.pathname}>
      <Notifications
        userContainer={userContainer}
        billing={billing}
        fanPages={fanPages}
      >
        <SubscribeModal
          showModal={fanPages.state.showModal}
          closeModal={fanPages.closeModal}
          getAll={fanPages.getAll}
          selectAll={fanPages.selectAll}
          fanPagesCollection={fanPages.state.fanPages}
          error={fanPages.state.error}
          subscribetoAllSelected={fanPages.subscribetoAllSelected}
          subscribeToggle={fanPages.subscribeToggle}
          getSubscribed={getGlobalSidebarData}
          fanPages={fanPages}
        >
          <MobileSidebar
            open={router.state.sidebarOpen}
            selectPage={(id) => {
              selectPage(id);
            }}
            subscribedPages={fanPages.state.subscribedPages}
            currentPage={fanPages.state.currentPage}
            openModal={() => {
              fanPages.openModal();
            }}
            closeSidebar={() => {
              router.closeSidebar();
            }}
            goTo={(v) => {
              router.goTo(v);
            }}
            location={location}
            logout={() => {
              userContainer.submitLogoutHandler();
            }}
            onSetOpen={open => router.onSetSidebarOpen(open)}
          >
            <div className="flex flex-row w-100 h-100 bg-lightpink relative">
              {canRenderSidebar && (
                <MenuSidebar
                  className="flex-ns dn"
                  selectPage={(id) => {
                    selectPage(id);
                  }}
                  subscribedPages={fanPages.state.subscribedPages}
                  currentPage={fanPages.state.currentPage}
                  openModal={() => {
                    fanPages.openModal();
                  }}
                  goTo={(v) => {
                    router.goTo(v);
                  }}
                  location={location}
                  logout={() => {
                    userContainer.submitLogoutHandler();
                  }}
                />
              )}
              {/* Container */}
              <div className="pa4-ns pv3 ph2 flex flex-column w-100 h-100 pl6-m">
                <div className="flex flex-row justify-between pb4 items-center">
                  <h1 className="title ma2">Settings</h1>
                  <img
                    className="h26px w-auto dn-ns dib pl2 pt2 pointer"
                    src="/menu.svg"
                    onClick={() => router.openSidebar()}
                  />
                </div>
                {/* 1st ROW */}
                <div className="flex flex-row w-100 flex-nowrap-l flex-wrap">
                  <div className="pb3 ph2 w-60-l w-100">
                    <FacebookAccountBlock
                      facebook={facebook}
                      reloadData={getGlobalSidebarData}
                    />
                  </div>
                  {/* DIV */}
                  <div className="pb3 ph2 w-40-l w-100">
                    <InstagramAccountBlock
                      fanPages={fanPages}
                      instagram={instagram}
                      facebook={facebook}
                      reloadData={getGlobalSidebarData}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-100 flex-nowrap-l flex-wrap">
                  {/* {userDataReady && !_.isEmpty(userDataReady.stack_coupon) */}
                  {/*                  && ( */}
                  {/*                  <div className="ph2 pt2-l pt0 w-50-l w-100"> */}
                  {/*                    <AddCouponCode */}
                  {/*                      couponCode={couponCode} */}
                  {/*                      error={couponCode.state.error} */}
                  {/*                      errorType={couponCode.state.errorType} */}
                  {/*                      changeHandler={(e) => { */}
                  {/*                        couponCode.changeHandler(e); */}
                  {/*                      }} */}
                  {/*                      submitStackCouponHandler={(e) => { */}
                  {/*                        couponCode.submitStackCouponHandler(e); */}
                  {/*                      }} */}
                  {/*                    /> */}
                  {/*                  </div> */}
                  {/*                  )} */}
                  {userDataReady && !_.isEmpty(userDataReady.stack_coupon) && (
                  <div className="ph2 pt2-l pt3 w-50-l w-100">
                    <AppSumoCommentsCounter volume={userDataReady.stack_coupon_counter} />
                  </div>
                  )}
                </div>
                {userDataReady && userDataReady.stripe_customer_id
                 && (
                 <div className="flex flex-row w-100 flex-nowrap-l flex-wrap">
                   <div className="ph2 pt2-l pt0 w-50-l w-100">
                     <CreditCardForm userContainer={userContainer} billing={billing} />
                   </div>
                   {/* <div className="ph2 pt2-l pt3 w-50-l w-100"> */}
                   {/*  <PaymentHistory history={userContainer.state.history} /> */}
                   {/* </div> */}
                 </div>
                 )}

                {/* TRIAL */}
                {userDataReady && billing.state.currentAccount && billing.state.currentAccount.status === 'trialing'
                && (
                <div className="flex flex-row w-100 flex-nowrap-l flex-wrap">
                  <div className="pb3 pt3 ph2 w-50-l w-100">
                    <FreeTrialTier
                      trialDaysLeft={billing.state.currentAccount.trial_days_left}
                      trialCommentsLeft={billing.state.currentAccount.trial_comments_left}
                    />
                  </div>
                </div>
                )}

                {/* PAID */}
                {userDataReady && billing.state.currentAccount
                && ['active', 'canceled'].includes(billing.state.currentAccount.status)
                                && (
                                <div className="flex flex-row w-100 flex-nowrap-l flex-wrap">
                                  <div className="pb3 pt3 ph2 w-50-l w-100">

                                    <CurrentTier
                                      usedWords={userContainer.state.user.processed}
                                      usedWordsTotal={userContainer.state.user.processed_total}
                                      totalWords={userContainer.state.user.volume}
                                      nextPayment={userContainer.state.user.next_payment}
                                      accountStatus={billing.state.currentAccount.status}
                                      nextPaymentDate={billing.state.currentAccount.next_billing_date_formatted}
                                      subscriptionCancel={billing.state.currentAccount.cancel_at_period_end}
                                      showSubscriptionCancelModalHandler={() => {
                                        billing.showSubscriptionCancelModal();
                                      }}
                                    />
                                  </div>
                                </div>
                                )}
              </div>
              <style jsx="true">
                {`
                  .h26px {
                    height: 26px;
                  }
                  ::placeholder {
                    font-family: 'Open Sans', sans-serif;
                    color: #9A8AA8;
                    font-weight: 600;
                    font-size: 0.55rem;
                  }
                  .toTheLeft {
                    left: -3px !important;
                  }
                  div[data-react-class="App"] {
                    height: 100%;
                    width: 100%;
                  }
                  div[data-react-class="App"] > div {
                    height: auto;
                    width: 100%;
                  }
                `}
              </style>
            </div>
          </MobileSidebar>
        </SubscribeModal>
      </Notifications>
    </PathValidator>
  </ComponentDidMount>
);

export default Settings;
