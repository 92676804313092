import React from 'react';
import Checkmark from 'components/Checkmark';

const FanPagesItem = ({ listItem, subscribeToggle, pageIconUrl }) => {
  return (
    <div
      className="flex flex-column w-100 bb-lightgrey pv2 justify-center ml2"
      style={{
        height: '3rem',
        opacity: 1,
      }}
    >
      <div
        onClick={() => {
          subscribeToggle(listItem.id);
        }}
        className="flex flex-row justify-between items-center w-100 pointer"
      >
        <div className="flex flex-row justify-start items-center w-100">
          <div
            style={{
              width: '1.3rem',
              height: '1.3rem',
              borderRadius: '4rem',
              background: `url(${pageIconUrl(listItem)})`,
              backgroundPosition: 'center',
              backgroundSize: '100%',
            }}
            className="shadow-for-page mr3"
          />
          <span className="highlighted-word pr3">{listItem.name}</span>
        </div>

        <Checkmark
          isLoading={listItem.subToggleLoading}
          checked={(listItem.selected === undefined)
            ? listItem.subscribed : listItem.selected}
        />

      </div>
      <style jsx="true">
        {`
          .shadow-for-page {
            -webkit-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            -moz-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
          }
          .highlighted-word {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.75rem;
          }
          .word-menu {
            outline:none;
            font-size: 1.3rem;
            font-family: 'Open Sans', sans-serif;
            color: #9A8AA8;
          }
          .bb-lightgrey {
            border-bottom: 1px solid rgb(241, 241, 241);
          }
        `}
      </style>
    </div>
  );
};

export default FanPagesItem;
