import React from "react"

const BlockContainer = ({row, children, className}) => (
  <div className={`flex ${row !== undefined ? 'flex-row' : 'flex-column h-100'} br3 w-100 bg-white shadow ${className}`}>
    {children}
    <style jsx="true">
      {`
        .shadow {
          -webkit-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          -moz-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
        }
      `}
    </style>
  </div>
);

export default BlockContainer;
