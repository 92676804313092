import { Container } from 'unstated';

import {
  FORGOT_PASSWORD_FORM,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from 'appEvents';

const request = require('superagent');

class ForgotPasswordContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      status: FORGOT_PASSWORD_FORM,
      showModal: false,
      email: '',
      error: '',
      errorType: '',
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.submitEmailHandler = this.submitEmailHandler.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    const close = this.closeModal;
    window.addEventListener('keydown', (e) => {
      if ((e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27)) {
        close();
        return false;
      }
    }, true);
  }

  submitEmailHandler() {
    this.setState({
      status: FORGOT_PASSWORD_PROCESSING,
    });
    const { email } = this.state;
    this.setState({ status: FORGOT_PASSWORD_PROCESSING });
    request
      .get(`/api/forgot_password?email=${email}`)
      .set('Content-Type', 'application/json')
      .end((error, res) => {
        if (error) {
          this.setState({
            status: FORGOT_PASSWORD_ERROR,
            error: 'Provided email does not exists!',
            errorType: FORGOT_PASSWORD_ERROR,
          });
        } else {
          const { id } = res.body;
          if (id) {
            this.setState({
              status: FORGOT_PASSWORD_SUCCESS,
              error: null,
              errorType: null,
            });
          } else {
            this.setState({
              status: FORGOT_PASSWORD_ERROR,
              error: 'Provided email does not exists!',
              errorType: FORGOT_PASSWORD_ERROR,
            });
          }
        }
      });
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  closeModal() {
    this.setState({ showModal: false, status: FORGOT_PASSWORD_FORM });
  }
}

export default ForgotPasswordContainer;
