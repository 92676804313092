import React, { Fragment } from 'react';
import propTypes, { func, string } from 'prop-types';

import FormState from 'components/ResetPasswordForm/FormState';
import ProcessingState from 'components/ResetPasswordForm/ProcessingState';
import SuccessState from 'components/ResetPasswordForm/SuccessState';

import {
  RESET_PASSWORD_FORM,
  RESET_PASSWORD_PROCESSING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from 'appEvents';

const ResetPasswordForm = ({
  status,
  submitResetPassword,
  onPasswordChange,
  onconfirmPasswordChange,
  password,
  confirmPassword,
  validateFormInput,
  error,
  router,
}) => (
  <Fragment>
    {(status === RESET_PASSWORD_FORM || status === RESET_PASSWORD_ERROR) && (
      <FormState
        submitResetPassword={submitResetPassword}
        onPasswordChange={onPasswordChange}
        onconfirmPasswordChange={onconfirmPasswordChange}
        password={password}
        confirmPassword={confirmPassword}
        validateFormInput={validateFormInput}
        error={error}
        router={router}
      />
    )}
    {status === RESET_PASSWORD_PROCESSING && <ProcessingState />}
    {status === RESET_PASSWORD_SUCCESS && <SuccessState goTo={router.goTo} />}
  </Fragment>
);

ResetPasswordForm.propTypes = {
  status: propTypes.oneOf([
    null,
    RESET_PASSWORD_FORM,
    RESET_PASSWORD_PROCESSING,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS]).isRequired,
  submitResetPassword: func.isRequired,
  onPasswordChange: func.isRequired,
  onconfirmPasswordChange: func.isRequired,
  validateFormInput: func.isRequired,
  password: string.isRequired,
  confirmPassword: string.isRequired,
  error: string.isRequired,
};

export default ResetPasswordForm;
