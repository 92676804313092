import React from 'react';
import {number} from 'prop-types';

import BlockContainer from 'components/BlockContainer';

const Stats = ({all, thisWeek, sinceLastLogin}) => {
  return (
    <BlockContainer>
      <div className="pa4-ns pa3">
        <p className="subTitle ma0 pb2">Stats</p>
        <div className="flex flex-row pt3 pb2 items-start justify-center">
          <div className="flex flex-column items-center justify-center w-30 mw4">
            <p className="stats ma0 mb2 ph4">{all}</p>
            <p className="stats-label ma0">ALL HIDDEN</p>
          </div>
          <div className="" style={{width: '1px', borderLeft: 'solid 1px #f1f1f1', height: '1rem', marginTop: '0.6rem'}}>
          </div>
          <div className="flex flex-column items-center justify-center w-30 mw4">
            <p className="stats ma0 mb2 ph4">{thisWeek}</p>
            <p className="stats-label ma0">THIS WEEK</p>
          </div>
          <div className="" style={{width: '1px', borderLeft: 'solid 1px #f1f1f1', height: '1rem', marginTop: '0.6rem'}}>
          </div>
          <div className="flex flex-column items-center justify-center w-30 mw4">
            <p className="stats ma0 mb2 ph4">{sinceLastLogin}</p>
            <p className="stats-label ma0">SINCE LAST LOGIN</p>
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
          .stats {
            font-family: 'Montserrat', sans-serif;
            font-size: 2rem;
            font-weight: 500;
            background: -webkit-linear-gradient(#7049E2, #B235f4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .stats-label {
            color: #9A8AA8;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.5rem;
            font-weight: 700;
          }
        `}
      </style>
    </BlockContainer>
  )
}

Stats.propTypes = {
  all: number.isRequired,
  thisWeek: number.isRequired,
  sinceLastLogin: number.isRequired
}

export default Stats;
