import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'unstated';
import { PrivateRoute, AuthRoute } from 'routes';

import {
  UserLogin,
  Registration,
  Dashboard,
  Settings,
  Referral,
  Landing,
  ResetPassword,
} from 'pages';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider>
        <Router>
          <Switch>
            <AuthRoute path="/appsumo/:stackCoupon" component={Registration} />
            <AuthRoute path="/signup/:volume" component={Registration} />
            <AuthRoute path="/ref/:referralToken" component={UserLogin} />
            <AuthRoute path="/login" component={UserLogin} />
            <AuthRoute path="/appsumo" component={Registration} />
            <AuthRoute path="/signup" component={Registration} />
            <AuthRoute path="/password/reset/:token" component={ResetPassword} />
            <PrivateRoute exact strict path="/dashboard" component={Dashboard} />
            <PrivateRoute exact strict path="/settings" component={Settings} />
            <PrivateRoute exact strict path="/referral" component={Referral} />
            <Route exact strict path="/landing" component={Landing} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;