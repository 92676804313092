import React from 'react';
import ComponentDidMount from 'containers/ComponentDidMount';
import BlockContainer from 'components/BlockContainer';
import AddNewCard from 'components/AddNewCard';

const CreditCardForm = ({billing}) => (
  <BlockContainer>
    <ComponentDidMount exec={() => {
      billing.getAllPaymentMethods();
    }}>
      <div className="pa4-ns pa3">
        <p className="subTitle ma0">Credit Card Details</p>
        <div className="flex flex-column pt4-ns pt3 items-start-ns items-center">
          <div className="br9px">
            {(billing.state.paymentMethods || []).map((pm, k) => (
              <div key={`card-${k}`} className="onHoverLightBg flex flex-row flex-wrap bb-lightgrey pt2 pb3 ph2 items-end w-100 justify-between">
                <div className="flex flex-column items-start justify-center">
                  {pm.current && <div className="current-label pv1 br2 pointer ttu">current</div>}
                  <div className="f5 nowrap lightBlack">
                    {`**** **** **** ${pm.last4 || '****'}`}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end">
                  {!pm.current && <div className="current-button pa1 ph2 br2 ml2 pointer ttu white" onClick={()=>{billing.setCurrentPaymentMethod(pm.id)}}>set as current</div>}
                  {!pm.current && <div className="delete-button pa1 ph2 br2 ml2 pointer ttu white" onClick={()=>{billing.deletePaymentMethod(pm.id)}}>delete</div>}
                </div>
              </div>
            ))}
            {billing.state.paymentMethods !== null && (billing.state.paymentMethods||[]).length === 0 && <div>No card.</div>}
          </div>
        </div>
        <div className="tl-ns tc">
          <AddNewCard
            status={billing.state.actions.addNewCard.status}
            addNewClickHandler={()=>{billing.showAddNewCardForm()}}
            submitCardHandler={(e)=>{billing.submitNewCard(e)}}
            cancelHandler={() => {billing.cancelAddNewCardForm()}}
          />
        </div>
      </div>
      <style jsx="true">
        {`
          .lightBlack {
            color: #382C58;
          }
          .current-label {
            border: transparent;
            outline: none;
            font-size: 10px;
            background: -webkit-linear-gradient(#7049E2,#B235f4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .onHoverLightBg:hover {
            background-color: #fbf9fd;
          }
          .br9px {
            border-radius: 9px;
          }
          .current-button {
            background-image: linear-gradient(to right,#7049E2,#9A6FED);
            -webkit-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            border: transparent;
            outline: none;
            font-size: 10px;
          }
          .delete-button {
            background-color: white;
            color: rgb(154,138,168);
            /*
              -webkit-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              -moz-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            */
            border: transparent;
            outline: none;
            font-size: 10px;
          }
          .bb-lightgrey {
            border-bottom: 1px solid rgb(241,241,241);
          }
          .fg1 {
            flex-grow: 1;
          }
          .fshrink1 {
            flex-shrink: 1;
          }
          .fshrink2 {
            flex-shrink: 2;
          }
          button.save {
            border-radius: 5rem;
            height: 2rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.5rem;
            font-weight:600;
          }
          input {
            outline: none;
            padding-top:0;
            padding-bottom:0;
            width: auto;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.55rem;
            border: none;
            height: 0.8rem;
            line-height: 0.8rem;
          }
          div.inputWrapper {
            border-radius: 0.25rem;
            height: 2rem;
            padding-left:0.7rem;
            padding-right:0.7rem;
          }
          div.valid {
            border: solid 1px #EAD7FB;
          }
          div.active {
            border: solid 1px #7049E2;
          }
          div.invalid {
            border: solid 1px #F65763;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: #F65763;
            font-weight: 700;
            font-size: 0.40rem;
            padding-top:0.2rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.40rem;
            padding-top:0.2rem;
          }
        `}
      </style>
    </ComponentDidMount>
  </BlockContainer>
)

export default CreditCardForm;
