import React from 'react';
import {
  array, string, number, func,
} from 'prop-types';
import CommentsList from 'components/CommentsList';
import BlockContainer from 'components/BlockContainer';
import Select from 'components/Select';


const CommentsLog = ({
  comments,
  toggleComment,
  commentsContainer,
  currentEpoch,
  currentFilter,
  currentPageId,
  getAll,
}) => (
  <BlockContainer>
    <div className="ph4-ns ph3 pt4-ns pt3 w-100 min-h-200px flex flex-column">
      <div className="flex flex-row flex-nowrap-ns flex-wrap w-100 justify-between items-center pb2">
        <p className="subTitle ma0">Comment Log</p>
        <div className="flex flex-row items-center">
          <span className="text">Show comments:</span>
          <Select
            options={[
              { text: 'All', value: 'all' },
              { text: 'Passed only', value: 'passed' },
              { text: 'Hidden only', value: 'hidden' },
            ]}
            value={currentFilter}
            selectHandler={(e) => {
              getAll(currentPageId, currentEpoch, e.value);
            }}
          />
          <span className="text">Show comments from:</span>
          <Select
            options={[
              { text: 'This week', value: 'week' },
              { text: 'This month', value: 'month' },
              { text: 'All time', value: 'all' },
              { text: 'Today', value: 'today' },
            ]}
            value={currentEpoch}
            selectHandler={(e) => {
              getAll(currentPageId, e.value, currentFilter);
            }}
          />
        </div>
      </div>
      {(comments.length > 0) ? (
        <CommentsList
          toggleComment={(id) => {
            toggleComment(id);
          }}
          list={comments}
          commentsContainer={commentsContainer}
        />
      ) : (
        <div className="flex flex-column w-100 items-center justify-center pv3" style={{ height: '90%' }}>
          <img src="/feather.svg" style={{ width: '1.5rem', height: 'auto' }} />
          <p className="mh0 mv3 innerSubTitle">
            Hurray, no comments yet!
          </p>
          <span className="tc w-100 text" style={{ maxWidth: '11.5rem' }}>
            All hidden comments will appear here.
          </span>
        </div>
      )}
    </div>
    <style jsx="true">
      {`
      .min-h-200px {
        min-height: 200px;
      }
    `}
    </style>
  </BlockContainer>
);

export default CommentsLog;
