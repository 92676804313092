import React from 'react';
import _ from 'lodash';

import ComponentDidMount from 'containers/ComponentDidMount';
import FanPagesItem from 'components/FanPagesItem';
import Loader from 'components/Loader';

const FanPagesList = ({ fanPages }) => {
  const { list } = fanPages.state;
  return (
    <ComponentDidMount exec={() => {}}>
      {_.isEmpty(list) && <Loader />}
      <div className="relative">
        <div className="mh12rem h-auto overflow-y-auto fade-in pb4">
          {!_.isEmpty(list) && list.map(listItem => (
            <div key={`${listItem.name}-page`} className="pr3">
              <FanPagesItem
                listItem={listItem}
                subscribeToggle={fanPages.subscribeToggle}
                pageIconUrl={fanPages.pageIconUrl}
              />
            </div>
          ))}
        </div>
        {list.length > 4 && <div className="h2rem absolute b0 white-gradient-overlay" />}
        <style jsx="true">
          {`
          .mh12rem {
            max-height: 12rem;
          }
          .h2rem {
            height: 2rem;
          }
          .b0 {
            bottom: 0;
          }
          .white-gradient-overlay {
            background: transparent;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 46%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0);
            width:101%; // to overlay scrollbar
          }

          @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }

          .fade-in {
            opacity:0.6;
            -webkit-animation:fadeIn ease-in 0.3s;
            -moz-animation:fadeIn ease-in 0.3s;
            animation:fadeIn ease-in 0.3s;
            -webkit-animation-fill-mode:forwards;
            -moz-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
            -webkit-animation-duration:0.3s;
            -moz-animation-duration:0.3s;
            animation-duration:0.3s;
          }
          
           .highlighted-word-title {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.75rem;
          }
        `}
        </style>
      </div>
    </ComponentDidMount>
  );
};

export default FanPagesList;
