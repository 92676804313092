import React from 'react';
import {array} from 'prop-types';
import PlanPricesListItem from 'components/PlanPricesListItem';

const PlanPricesList = ({list}) => {
    return (
        <div className="flex flex-row">
            {list.map(listItem => {
                return (
                    <div key={`${listItem.name}-plan`}>
                        <PlanPricesListItem listItem={listItem}/>
                    </div>
                )
            })}
            <style jsx="true">
                {`
                  .mh12rem {
                    max-height: 12rem;
                  }
                  .h2rem {
                    height: 2rem;
                  }
                `}
            </style>
        </div>
    );
}

PlanPricesList.propTypes = {
    list: array.isRequired
}

export default PlanPricesList;