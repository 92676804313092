import React from 'react';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';

export default ({
  location,
  email,
  password,
  changeHandler,
  submitHandler,
  showForgotPassword,
  error,
}) => (
  <div style={{ minHeight: '43rem' }} className="flex flex-column w-100 h-100 bg-lightpink">
    <AuthTopBar location={location} />
    <div className="flex flex-column center mt5 w-100 mw6">
      <BlockContainer row className="items-center justify-center">
        <form className="flex flex-column items-center justify-center w-100 h-30rem">
          <h1 className="title ma2 mb4">Login</h1>
          <div className="h2 mistake flex flex-row w-100 items-center justify-center">
            {error && error}
          </div>
          <div className="pb3 w-70">
            <p className="label">Email</p>
            <input
              name="email"
              type="email"
              placeholder="john@example.com"
              className="w-100"
              onChange={changeHandler}
              value={email}
            />
          </div>
          <div className="w-70">
            <p className="label">Password</p>
            <input
              name="password"
              type="password"
              className="w-100"
              onChange={changeHandler}
              value={password}
            />
          </div>
          <div className="flex flex-row pb1 w-70 justify-end items-left">
            <div className="flex">
              <span className="boldText ma0 pb1">
                <a href="#" onClick={(e) => { e.stopPropagation(); showForgotPassword(); }} className="ttu no-underline forgot-password-link">Forgot Password?</a>
              </span>
            </div>
          </div>
          <div className="w-70">
            <button type="button" onClick={submitHandler} className="mt1 loginButton">
              Authorize
            </button>
          </div>
        </form>
      </BlockContainer>
    </div>
    <style jsx="true">
      {`
          .forgot-password-link {
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight: 600;
            background: -webkit-linear-gradient(#7049E2, #B235f4);
            -webkit-background-clip: text;
             -webkit-text-fill-color: transparent;
          }
          .h-30rem {
            height: 30rem;
          }
          .loginButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.8rem;
            font-weight:600;
            width: 100%;
          }
          .step {
            width: 28px;
            height: 28px;
            padding-top: 3px;
            border: 2px solid white;
            border-radius: 2rem;
          }
          .active-step {
            color: #A94AEF;
            border: 2px solid #A94AEF;
            border-radius: 2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
          input {
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
          }
          input:hover {
            border: solid 1px #7049E2;
          }
          input {
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
            height: 3rem;
            line-height: 3rem;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: #F65763;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:0.2rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
            padding-top:0.2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
    `}
    </style>
  </div>
);
