import React from 'react';
import _ from 'lodash';
import FacebookLogin from 'react-facebook-login';

export default ({ instagram, userContainer }) => {
  const { currentFbAccount } = userContainer.state;
  return (
    <div className="flex flex-row justify-start items-center pl2">
      <div className="flex flex-column items-center">
        {/*<span className="boldText ma0 pb1 black">Not Approved</span>*/}
        <div>
          <FacebookLogin
            appId={process.env.FB_APP_ID}
            autoLoad
            fields="email,name"
            cookie={false}
            scope={_.join(_.union(__fbAppScope.split(','), __igAppScope.split(',')))}
            textButton="Login with Facebook"
            cssClass="btnIG"
            size="small"
            onClick={() => { }}
            callback={(response) => {
              _.has(response, 'accessToken') && userContainer.updateAccount(response);
            }}
          />
        </div>
      </div>
      <style jsx="true">
        {`
                .boldText {
                  font-family: 'Open Sans', sans-serif;
                  color: #E33E5C;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
            `}
      </style>
    </div>
  );
};
