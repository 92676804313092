import React, { Fragment } from 'react';

import InitialState from 'components/UserLoginForm/InitialState';
import FbLoginState from 'components/UserLoginForm/FbLoginState';
import ProcessingState from 'components/UserLoginForm/ProcessingState';

import {
  LOGIN_USER_FORM_PROCESSING,
  LOGIN_FB_FORM,
} from 'appEvents';

const UserLoginForm = ({ location, userContainer }) => {
  const {
    email,
    password,
    error,
    status,
  } = userContainer.state;

  return (
    <Fragment>
      {status === null && (
        <InitialState
          location={location}
          email={email}
          password={password}
          changeHandler={userContainer.changeLoginHandler}
          submitHandler={userContainer.submitLoginHandler}
          showForgotPassword={userContainer.showForgotPassword}
          error={error}
        />
      )}
      {status === LOGIN_FB_FORM && (
        <FbLoginState
          userContainer={userContainer}
          location={location}
          error={error}
        />
      )}
      {status === LOGIN_USER_FORM_PROCESSING && <ProcessingState />}
    </Fragment>
  );
};

export default UserLoginForm;
