import React from 'react';

export default () => (
  <div className="relative reach_your_goals ph5-l ph4-m ph3">
    <div className="center_block">
      <div className="block1">
        <ul>
          <li className="title">
            Reach your goals
          </li>
          <li className="title_grey">
            with a peace of mind
          </li>
          <li className="li_btn">
            <a href="#" className="btn_transparent">COMPARE TIERS</a>
          </li>
        </ul>
      </div>
      <div className="block2">
        <ul>
          <li className="li_img flex flex-row justify-start-l justify-center items-center">
            <div className="flex flex-column items-center justify-center clean-bg square-4-5-rem br-1-7-rem">
              <img className="pt2" src="/clean_icon.svg"/>
            </div>
          </li>
          <li className="li_text mw6-m center-m ph3-l">
            <span>Let our algorithms do the job</span> — monitoring and analyzing sentiment to fight the negativity.
          </li>
          <li className="li_img flex flex-row justify-start-l justify-center items-center">
            <div className="flex flex-column items-center justify-center bullet-bg square-4-5-rem br-1-7-rem">
              <img className="pt2" src="/bullet-list_icon.svg"/>
            </div>
          </li>
          <li className="li_text mw6-m center-m ph3-l">
            <span>Specify black and white lists with</span> “trigger words” that would make the comment hide or unhide.
          </li>
          <li className="li_img flex flex-row justify-start-l justify-center items-center">
            <div className="flex flex-column items-center justify-center bargraph-bg square-4-5-rem br-1-7-rem">
              <img className="pt2" src="/bar-graph_icon.svg"/>
            </div>
          </li>
          <li className="li_text mw6-m center-m ph3-l">
            <span>Pricing model adjusts to you so that you always pay for what you hide</span> — no hidden fees with a money-back guarantee.
          </li>
        </ul>
      </div>
    </div>
    <style jsx="true">{`
      .square-4-5-rem {
        height: 4.5rem;
        width: 4.5rem;
      }
      .br-1-7-rem {
        border-radius: 1.7rem;
      }
      .clean-bg {
        background: #f3ecfd; /* Old browsers */
        background: -moz-linear-gradient(top, #f3ecfd 0%, #f6ecfe 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #f3ecfd 0%,#f6ecfe 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #f3ecfd 0%,#f6ecfe 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3ecfd', endColorstr='#f6ecfe',GradientType=0 ); /* IE6-9 */
      }
      .bargraph-bg {
        background: #ecf8fc;
      }
      .bullet-bg {
        background: #edfaf1;
      }
      .reach_your_goals{
        background:#ffffff;
      } 
      .li_text{
        min-height:148px;
        padding-top:4px;
        color: #6d5f79;
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        font-family: 'Roboto',sans-serif;
      }
      
      
      .title{
        color: #382C58;
        font-size: 32px;
        font-weight: 400;
        line-height: 54px;
        font-family: 'Roboto',sans-serif;
      }
      
      .title_grey{
        color: #9892a9;
        font-size: 32px;
        line-height: 54px;
        font-weight: 400;
        font-family: 'Roboto',sans-serif;
      }
      
      .li_btn{
        margin-top:72px;
      }
      
      
      .li_text span{
        color: #271f3e;
        font-weight: 500;
      }
      @media (min-width:1000px){
        .reach_your_goals{
          padding-top:200px;
        } 
        .block1,
        .block2{
          float:left;
          width:50%;
        }
        
        .block2{
          margin-top:16px;
        }
        .li_img{
          float:left;
          width:18%;
        }
        
        .li_text{
          float:left;
          width:82%;
        }
      }
      @media (max-width:1000px){
        .reach_your_goals{
          padding-top: 80px;
        }
      
        .block1{
          float:none;
          width:100%;
          text-align: center;
        }
      
        .block2{
          margin-top:80px;
          float:none;
          width:100%;
        }
      
        .li_img,
        .li_text{
          float: none;
          width: initial;
          text-align: center;
        }
      
        .li_text {
          margin-top: 10px;
        }
      }
  
    `}</style>
  </div>
)
