import React from 'react';

export default () => (
  <div className="bg-white ph4 pt3">
    <div className="center cf w-100 pt3 ph4-l ph3 mw1465px">
      <div className="tl-l tc fl w-100 w-30-l pt2 pb3">
        <img width="135" src="/sg-logo-text.svg"/>
      </div>
      <div className="fl w-100 w-40-l pb4">
        <ul className="btn_menu_block flex flex-row-l-custom flex-column justify-center">
          <li>
            <a className="btn_menu" href="/how_it_works">How&nbsp;it&nbsp;works</a>
          </li>
          <li>
            <a className="btn_menu" href="/key_features">Key&nbsp;features</a>
          </li>
          <li>
            <a className="btn_menu" href="/pricing">Pricing</a>
          </li>
        </ul>
      </div>
      <div className="tr-l tc fl w-100 w-30-l pb0-ns pb4">
        <a href="/login">
          <button className="btn_transparent ttu pointer authButton mb3 ml3-l mr0-l mh2 w8rem">Log in</button>
        </a>
        <a href="/signup">
          <button className="btn_blue ttu pointer authButton mb3 ml3-l mr0-l mh2 w8rem">Sign up</button>
        </a>
      </div>
    </div>
    <style jsx="true">{`
      .w8rem {
        width: 8rem;
      }
      .mw1465px {
        max-width: 1465px;
      }

      .btn_menu_block {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
        text-align: center;
        margin-top: 12px;
      }

      .btn_menu_block li {
        float: left;
      }

      .btn_menu {
        font-size: 16px;
        font-weight: 300;
        color: #322554;
        text-decoration: none;
        margin-left: 30px;
        margin-right: 30px;
      }
      @media only screen and (max-width:1180px){
        .btn_menu_block li {
          float: none;
          margin-bottom: 16px;
        }
      }
      @media only screen and (min-width:70em){
        .w-40-l-custom {
          width:40%;
        }
        .flex-row-l-custom {
          -webkit-box-orient: horizontal;
          -ms-flex-direction: row;
          flex-direction: row;
        }
      }
      @media only screen and (max-width:30em){
        .btn_menu_block li {
          float: none;
          margin-bottom: 16px;
        }
      }
    `}</style>
  </div>
)
