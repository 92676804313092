import React from 'react';
import ComponentDidMount from 'containers/ComponentDidMount';
import BlockContainer from 'components/BlockContainer';
import ResetPasswordForm from 'components/ResetPasswordForm';
import PathValidator from 'components/PathValidator';

const ResetPassword = ({ userContainer, router }) => {
  const {
    status, password, confirmPassword, error,
  } = userContainer.state.resetPassword;
  return (
    <ComponentDidMount exec={() => { }}>
      <PathValidator targetPath={router.state.pathname}>
        <div className="modalScreen db">
          <div className="tc flex flex-column justify-center items-center h-100">
            <div className="flex flex-column w-100 vh-100-l h-100">
              <div className="flex flex-column center mt5 w-100 mw6">
                <BlockContainer row className="items-center justify-center">
                  <ResetPasswordForm
                    status={status}
                    password={password}
                    confirmPassword={confirmPassword}
                    error={error}
                    submitResetPassword={userContainer.submitResetPassword}
                    onPasswordChange={userContainer.onPasswordChange}
                    onconfirmPasswordChange={userContainer.onconfirmPasswordChange}
                    validateFormInput={userContainer.validateFormInput}
                    router={router}
                  />
                </BlockContainer>
              </div>
            </div>
          </div>
          <style jsx="true">
            {`
        @media (min-width: 30em) {
          .min-w-25rem-ns {
            min-width: 25rem;
          }
        }
        @media (max-width: 30em) {
          .min-w-16rem {
            min-width: 16rem;
          }
        }
        .mw10rem {
          max-width: 10rem;
        }
        .step {
          width: 28px;
          height: 28px;
          padding-top: 3px;
          border: 2px solid white;
          border-radius: 2rem;
        }
        .active-step {
          color: #A94AEF;
          border: 2px solid #A94AEF;
          border-radius: 2rem;
        }
        .lineButton {
          color: #7049E2;
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          font-size: 0.75rem;
        }
        .outlineButton {
          color: #7049E2;
          border: 1px solid #EAD7FB;
          border-radius: 5rem;
          background: white;
          height: 3rem;
          line-height: 2.9rem;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        .filledButton {
          color: white;
          border-radius: 5rem;
          height: 3rem;
          line-height: 2.9rem;
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          border: 1px solid transparent;
          outline: none;
          -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        @media (min-width: 30em) {
          .close {
            position: absolute;
            top: 10%;
            right: 20%;
            color: white;
            cursor: pointer;
          }
        }
        @media (max-width: 30em) {
          .close {
            position: absolute;
            top: 10%;
            right: 10%;
            color: white;
            cursor: pointer;
          }
        }
        .modalScreen {
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: #3C315B;
          z-index: 4;
          opacity: 1;
        }
      `}
          </style>
        </div>
      </PathValidator>
    </ComponentDidMount>
  );
};

export default ResetPassword;
