import React from 'react';
import _ from 'lodash';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import { useForm } from 'react-hook-form';

require('babel-polyfill');

export default ({
  location,
  values,
  error,
  changeUserDetailsHandler,
  checkUserExists,
  nextStep,
}) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const switchStep = () => {
    if (_.isEmpty(errors)) {
      nextStep();
      fbq('track', 'email_signup');
    }
  };

  const onSubmit = (data) => {
    data && checkUserExists(switchStep);
  };

  return (
    <div style={{ minHeight: '43rem' }} className="flex flex-column w-100 h-100 bg-lightpink">
      <AuthTopBar location={location} />
      <div className="flex flex-column center w-100 mw6">
        <div className="flex flex-row justify-center align-center center">
          <h1 className="title justify-center">Sign Up</h1>
        </div>
        <BlockContainer row className="items-center justify-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-100 pa5 flex flex-column items-center justify-center w-100 h-30rem"
          >
            <div className="h2 mistake flex flex-row w-100 items-center justify-center">
              {error && error}
            </div>
            <div className="pb1 w-100">
              <div className="flex flex-row">
                <p className="label">Name</p>
                {errors.name && errors.name.type === 'required'
                                && <p className="pl2 label-error">This is required.</p>}
                {errors.name && errors.name.type === 'minLength'
                                && <p className="pl2 label-error">This is required min length of 3</p>}
                {errors.name && errors.name.type === 'maxLength'
                                && <p className="pl2 label-error">This is required max length of 50</p>}
              </div>

              <input
                {...register('name', {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                  pattern: /^[A-Za-z\d\s]+$/,
                })}
                className="w-100"
                type="text"
                name="name"
                placeholder="Your Name"
                onChange={(e) => {
                  changeUserDetailsHandler(e);
                }}
                value={values.name || ''}
              />
            </div>
            <div className="pb1 w-100">
              <div className="flex flex-row">
                <p className="label">Email</p>
                {errors.email && errors.email.type === 'required'
                                && <p className="pl2 label-error">This is required.</p>}
                {errors.email && errors.email.type === 'pattern'
                                && <p className="pl2 label-error">format not valid.</p>}
              </div>
              <input
                {...register('email', {
                  required: true,
                  pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                })}
                className="w-100"
                type="email"
                name="email"
                placeholder="john@example.com"
                onChange={(e) => {
                  changeUserDetailsHandler(e);
                }}
                value={values.email || ''}
              />
            </div>
            <div className="pb1 w-100">
              <div className="flex flex-row">
                <p className="label">Company (optional)</p>
                {errors.company && errors.company.type === 'pattern'
                                && <p className="pl2 label-error">Special characters not allowed.</p>}
              </div>
              <input
                {...register('company', {
                  required: false,
                  pattern: /^[A-Za-z\d\s]+$/,
                })}
                className="w-100"
                type="text"
                name="company"
                placeholder="Company Name"
                onChange={(e) => {
                  changeUserDetailsHandler(e);
                }}
                value={values.company || ''}
              />
            </div>
            <div className="pb3 w-100">
              <div className="flex flex-row">
                <p className="label">Password</p>
                {errors.password && errors.password.type === 'required'
                                && <p className="pl2 label-error">This is required.</p>}
                {errors.password && errors.password.type === 'minLength'
                                && <p className="pl2 label-error">This is required min length of 6</p>}
                {errors.password && errors.password.type === 'maxLength'
                                && <p className="pl2 label-error">This is rquired max length of 50</p>}
              </div>
              <input
                {...register('password', {
                  required: true,
                  minLength: 6,
                  maxLength: 50,
                })}
                className="w-100"
                name="password"
                type="password"
                onChange={(e) => {
                  changeUserDetailsHandler(e);
                }}
                value={values.password || ''}
              />
            </div>
            <div className="pb1 w-100">
              <button
                type="submit"
                className="sessionButton"
              >
                Sign Up
              </button>
            </div>
          </form>
        </BlockContainer>
      </div>
      <style jsx="true">
        {`
          .error {
            border: 1px solid red;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: #F65763;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:0.2rem;
          }
          .h-30rem {
            height: 30rem;
          }
          .sessionButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.8rem;
            font-weight:600;
            width: 100%;
          }
          .step {
            width: 28px;
            height: 28px;
            padding-top: 3px;
            border: 2px solid white;
            border-radius: 2rem;
          }
          .active-step {
            color: #A94AEF;
            border: 2px solid #A94AEF;
            border-radius: 2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
          input {
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
          }
          input:hover {
            border: solid 1px #7049E2;
          }
          input {
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
            height: 3rem;
            line-height: 3rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
            padding-top:0.2rem;
          }
          .label-error {
            font-family: 'Open Sans', sans-serif;
            color: red;
            font-weight: 700;
            font-size: 0.6rem;
            padding-top:0.2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
    `}
      </style>
    </div>
  );
};
