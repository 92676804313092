import React from 'react';
import {
  bool, func, array, object,
} from 'prop-types';
import ComponentDidMount from 'containers/ComponentDidMount';

import BlockContainer from 'components/BlockContainer';
import FanPagesList from 'components/FanPagesList';

const SubscribeScreen = ({
  showModal,
  closeModal,
  subscribeToggle,
  getAll,
  selectAll,
  fanPagesCollection,
  error,
  subscribetoAllSelected,
  getSubscribed,
  fanPages,
}) => (
  <ComponentDidMount exec={() => {
    getAll();
  }}
  >
    <div className={showModal === true ? 'modalScreen db' : 'dn'}>
      <div className="tc flex flex-column justify-center items-center h-100">
        <div className="close" onClick={() => closeModal()}>✕</div>
        <div>
          <div className="flex flex-column w-100 vh-100-l h-100">
            <div className="flex flex-column justify-center align-center center mt6">
              <div className="login-block-wrapper min-w-25rem-ns min-w-16rem">
                <BlockContainer row>
                  <div className="w-100 pa5-l pa4-m pa3 flex flex-column">
                    <div className="flex flex-row justify-between items-center w-100 pb4">
                      <h1 className="title ma2">Select the pages</h1>
                      {fanPagesCollection && (
                      <span
                        onClick={() => {
                          selectAll();
                        }}
                        className="lineButton pointer mv2"
                      >
                        {' '}
Select all
                        {' '}
                      </span>
                      )}
                    </div>
                    <FanPagesList fanPages={fanPages} />
                    <div
                      onClick={() => {
                        subscribetoAllSelected((err) => {
                          err && console.log(err);
                          !err && getSubscribed((err) => {
                            !err && closeModal();
                            err && console.log(err);
                          });
                        });
                      }}
                      className="flex flex-row justify-center items-center w-100"
                    >
                      <button className="ttu pointer filledButton">
                                                done
                      </button>
                    </div>
                  </div>
                </BlockContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
        @media (min-width: 30em) {
          .min-w-25rem-ns {
            min-width: 25rem;
          }
        }
        @media (max-width: 30em) {
          .min-w-16rem {
            min-width: 16rem;
          }
        }
        .mw10rem {
          max-width: 10rem;
        }
        .step {
          width: 28px;
          height: 28px;
          padding-top: 3px;
          border: 2px solid white;
          border-radius: 2rem;
        }
        .active-step {
          color: #A94AEF;
          border: 2px solid #A94AEF;
          border-radius: 2rem;
        }
        .lineButton {
          color: #7049E2;
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          font-size: 0.75rem;
        }
        .outlineButton {
          color: #7049E2;
          border: 1px solid #EAD7FB;
          border-radius: 5rem;
          background: white;
          height: 3rem;
          line-height: 2.9rem;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        .filledButton {
          color: white;
          border-radius: 5rem;
          height: 3rem;
          line-height: 2.9rem;
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          border: 1px solid transparent;
          outline: none;
          -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        @media (min-width: 30em) {
          .close {
            position: absolute;
            top: 10%;
            right: 20%;
            color: white;
            cursor: pointer;
          }
        }
        @media (max-width: 30em) {
          .close {
            position: absolute;
            top: 10%;
            right: 10%;
            color: white;
            cursor: pointer;
          }
        }
        .modalScreen {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #3C315B;
          z-index: 4;
          opacity: 1;
        }
      `}
      </style>
    </div>
  </ComponentDidMount>
);

SubscribeScreen.propTypes = {
  showModal: bool.isRequired,
  closeModal: func.isRequired,
  subscribeToggle: func.isRequired,
  getAll: func.isRequired,
  getSubscribed: func.isRequired,
  fanPagesCollection: array,
  error: object,
};

export default SubscribeScreen;
