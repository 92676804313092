import {Container} from 'unstated';

const request = require('superagent');

class InvitationsContainer extends Container {
  constructor(props) {
    super(props);
    this.state = { emailInput: '', friends: [] };
    this.invite = this.invite.bind(this);
    this.emailChangeHandler = this.emailChangeHandler.bind(this);
  }

  emailChangeHandler(e) {
    this.setState({emailInput: e.target.value});
  }

  invite() {
    const { emailInput } = this.state;
    request
     .post('/invitations')
     .set('Content-Type', 'application/json')
     .set('Authorization', localStorage.getItem('authToken'))
     .send({ invitation: { recipient_email: emailInput }})
     .end((error, res) => {
       this.setState({ emailInput: '' });

       // TODO: UI logic for the response
       if (error) {
       } else {
       }
     });
  }

  getFriends() {
      request
          .get('/invitations')
          .set('Content-Type', 'application/json')
          .set('Authorization', localStorage.getItem('authToken'))
          .end((error, response) => {
              if (error) {
                  this.setState({error});
              } else {
                  this.setState({
                      friends: response.body,
                  });
              }
          });
      return true;
    }
}

export default InvitationsContainer;
