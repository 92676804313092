import React from 'react';
import BlockContainer from 'components/BlockContainer';

const PaidTier = ({tier}) => (
  <div className="flex flex-row justify-between items-start w-100 bb-lightgrey pv2" style={{height: '3rem'}}>
      <div className="flex flex-column items-start justify-center">
      <span className="wordsUsedInfo pb2">{tier.words_amount} Words Tier</span>
          <div className="flex flex-row">
              <span className="paymentWord pr1">Paid</span>
              <span className="paymentPrice">{tier.price}</span>
              <span className="paymentDate pl1">on {tier.date}</span>
          </div>
    </div>
    <style jsx="true">
      {`
        .wordsUsedInfo {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.65rem;
          font-weight: 500;
        }
        .paymentPrice {
          color: #382C58;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 700;
        }
        .paymentWord {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }
        .paymentDate {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }
        .bb-lightgrey {
          border-bottom: 1px solid rgb(241, 241, 241);
        }
        .word-menu {
          outline:none;
          font-size: 1.3rem;
          font-family: 'Open Sans', sans-serif;
          color: #9A8AA8;
        }
      `}
    </style>
  </div>
);

const List = ({list}) => (
  <div className="relative">
    <div className="h-100 overflow-y-auto fade-in pb4">
      {list.map(tier => {
        return (
          <div key={tier.id} className="pr3">
            <PaidTier tier={tier}/>
          </div>
        )
      })}
    </div>
    {list.length > 2 && <div className="h2rem absolute b0 white-gradient-overlay"/>}
    <style jsx="true">
      {`
        .h8rem {
          height: 8rem;
        }
        .h2rem {
          height: 3rem;
        }
        .b0 {
          bottom: 0;
        }
        .white-gradient-overlay {
          background: transparent;
          background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 56%, rgba(255,255,255,1) 100%);
          background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 56%,rgba(255,255,255,1) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 56%,rgba(255,255,255,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0);
          width:101%; // to overlay scrollbar
        }

        @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
        @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
        @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }

        .fade-in {
          opacity:0.6;
          -webkit-animation:fadeIn ease-in 0.3s;
          -moz-animation:fadeIn ease-in 0.3s;
          animation:fadeIn ease-in 0.3s;
          -webkit-animation-fill-mode:forwards;
          -moz-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
          -webkit-animation-duration:0.3s;
          -moz-animation-duration:0.3s;
          animation-duration:0.3s;
        }
      `}
    </style>
  </div>
);

const PaymentHistory = ({history}) => (
  <BlockContainer className="overflow-y-hidden">
    <div className="ph4-ns ph3 pt4-ns pt3">
      <p className="subTitle ma0 pb4">Payment History</p>
      {history ? <List list={history}/> :
        <div className="flex flex-column w-100 items-center justify-center pb4" style={{height: '90%'}}>
          <img src="/sleepy.svg" style={{width: "1.5rem", height: "auto"}}/>
          <p className="mh0 mv3 innerSubTitle">
            No payments yet
          </p>
          <span className="tc w-100 text" style={{maxWidth: '6.5rem'}}>
            Connect a credit card to get started.
          </span>
        </div>
      }
    </div>
    <style jsx="true">
      {`
        .innerSubTitle {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 500;
          font-size: 0.7rem;
        }
      `}
    </style>
  </BlockContainer>
)

export default PaymentHistory;
