import React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const CardFormSettings = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log('Stripe.js has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((result) => {
      return props.handlePayload(result);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              color: '#424770',
              fontFamily: 'Open Sans,sans-serif',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: 'red',
            },
          },
        }}
      />

      <div className="flex flex-row justify-between items-center w-100 mt3">
        <button
            type="button"
            className="ttu pointer outlineButton"
            onClick={props.cancelHandler}>
          Cancel
        </button>
        <button className="ttu pointer filledButton">Add Card</button>
      </div>
    </form>
  );
};

export default CardFormSettings;
