import React from 'react';
import propTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

class StopWordItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    toggleMenu() {
        const {showMenu} = this.state;
        this.setState({showMenu: !showMenu});
    }

    hideMenu() {
        this.setState({showMenu: false});
    }

    render() {
        const {listItem, deleteItem} = this.props;
        const {showMenu} = this.state;
        const toggleButton = (showMenu === false) ? (
            <span onClick={this.toggleMenu} className="word-menu pointer">...</span>
        ) : (
            <span className="word-menu pointer">...</span>
        );

        const RenderDeleteMenu = () => {
            return (
                <OutsideClickHandler onOutsideClick={this.hideMenu}>
                    <div className="pb2 flex flex-row" onClick={() => {
                        deleteItem(listItem.id)
                    }}>
                        <img style={{width: "10px", height: "100%"}} src="/delete.png"/>
                        <span className="pl3">Delete from list</span>
                    </div>
                </OutsideClickHandler>
            )
        };

        return (
            <div className="h2 flex flex-row justify-between items-center bb-lightgrey">
                <span className="word">{listItem.text}</span>
                <div className="relative pb3">
                    {toggleButton}
                    <div className={`${showMenu === true ? 'flex' : 'dn'} absolute menu flex-column`}>
                        <RenderDeleteMenu/>
                    </div>
                </div>
                <style jsx="true">
                    {`
            .word {
              font-family: 'Open Sans', sans-serif;
              color: #9A8AA8;
              font-weight: 700;
              font-size: 0.65rem;
            }
            .bb-lightgrey {
              border-bottom: 1px solid rgb(241, 241, 241);
            }
            .word-menu {
              outline:none;
              font-size: 1.3rem;
              font-family: 'Open Sans', sans-serif;
              color: #9A8AA8;
            }
            .noselect {
              -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
            .menu {
              right:0;
              top:1.5rem;
              font-weight: 500;
              width:9rem;
              margin: 0;
              background: #fff;
              z-index: 12;
              overflow: hidden;
              -webkit-box-shadow: 0px 3px 22px -5px rgba(36,0,35,0.15);
              -moz-box-shadow: 0px 3px 22px -5px rgba(36,0,35,0.15);
              box-shadow: 0px 3px 22px -5px rgba(36,0,35,0.15);
              border-radius: .5rem;
              font-size: 0.6rem;
              color: #9A8AA8;
              font-family: 'Open Sans',sans-serif;
              cursor: pointer;
              padding: 0.7rem 1rem;
              margin: 0;
            }
          `}
                </style>
            </div>)

    }
}

StopWordItem.propTypes = {
    listItem: propTypes.shape({
        text: propTypes.string.isRequired,
        id: propTypes.number.isRequired
    }).isRequired,
    deleteItem: propTypes.func.isRequired
};

export default StopWordItem;
