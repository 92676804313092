import React from 'react';
import propTypes from 'prop-types';
import InvalidList from 'components/FbAccountListDataTable/InvalidList';

const ModalError = ({
  visible,
  confirmHandler,
  cancelHandler,
  errorTitle,
  errorText,
  data,
}) => (
  <div className={`${visible ? 'flex' : 'dn'} absolute modal-container flex-column justify-center items-center`}>
    <div className="flex flex-column h-auto br3 w-auto bg-white shadow pa4 pt5 items-center justify-center">
      <img className="w-100 h-auto mw3rem" src="/error.png" />
      <h1 className="errorTitle mt-1-5-rem mb0 tc">{errorTitle}</h1>
      <h2 className="errorText mt2 mw20rem tc">{errorText}</h2>
      {data && <InvalidList list={data} /> }
      <div className="flex flex-row justify-center">
        <button className="ttu pointer filledButton mt3 mb2" onClick={confirmHandler}>OKAY</button>
        {cancelHandler && <button className="ttu pointer filledButton mt3 mb2 ml3" onClick={cancelHandler}>CANCEL</button>}
      </div>
    </div>
    <style jsx="true">
      {`
        .mt-1-5-rem {
          margin-top:1.5rem;
        }
        .ma-1-5-rem {
          margin: 1.5rem;
        }
        .mw3rem {
          max-width: 3rem;
        }
        .mw20rem {
          max-width: 20rem;
        }
        .shadow {
          -webkit-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          -moz-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          box-shadow:0px 9px 51px -15px rgba(36,0,35,0.35);
        }
        .errorTitle {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 600;
          font-size: 1.2rem;
        }
        .errorText {
          font-family: 'Open Sans',sans-serif;
          color: #9A8AA8;
          font-weight: 600;
          font-size: 0.8rem;
          line-height:1.9;
        }
        .filledButton {
          color: white;
          border-radius: 5rem;
          height: 3rem;
          line-height: 2.9rem;
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          border: 1px solid transparent;
          outline: none;
          -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        .modal-container {
          background-color: rgba(249, 248, 253,0.7);
          top:0;
           bottom:0;
           left:0;
           right:0;
           z-index:99999;
        }

      `}
    </style>
  </div>
);

ModalError.propTypes = {
  visible: propTypes.bool.isRequired,
  confirmHandler: propTypes.func,
  cancelHandler: propTypes.func,
  errorTitle: propTypes.string,
  errorText: propTypes.string,
  data: propTypes.array,
};

export default ModalError;
