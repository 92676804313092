import React from 'react';
import {func, string} from 'prop-types';

const ErrorState = ({closeForgotPasswordHandler}) => (
    <div className=''>
      ERROR STATE
      <style jsx="true" global="true">{`
      `}
      </style>
    </div>
);

ErrorState.propTypes = {
  closeForgotPasswordHandler: func.isRequired
};

export default ErrorState;
