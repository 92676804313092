import { Container } from 'unstated';
import Cookies from 'js-cookie';

import {
  DASHBOARD,
} from 'appEvents';

const request = require('superagent');

const getPreSelectedPlan = (list) => {
  const volume = localStorage.getItem('selectedVolume');
  let plan = '';
  if (volume !== null && list.length) {
    const p = list.filter(plan => (parseInt(plan.volume) === parseInt(volume)));
    plan = p.length ? p[0].value : list[0].value;
  }
  return plan;
};

class RegistrationContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      status: null,
      error: null,
      user: {
        name: '',
        email: '',
        company: '',
        password: '',
      },
      account: {},
      pages: { list: [] },
      stack_coupon: { code: '' },
      stripe: {
        token: [],
        plan_id: '',
        coupon_id: '',
      },
      plan_list: [],
      fbSdkLoaded: false,
      loading: false,
      authToken: null,
    };

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.changeUserDetailsHandler = this.changeUserDetailsHandler.bind(this);
    this.fbCallBackHandler = this.fbCallBackHandler.bind(this);
    this.stripeCallbackHandler = this.stripeCallbackHandler.bind(this);
    this.getPlanList = this.getPlanList.bind(this);
    this.setPreSelectedPlan = this.setPreSelectedPlan.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.checkStackCoupon = this.checkStackCoupon.bind(this);
    this.checkUserExists = this.checkUserExists.bind(this);
  }

  checkUserExists(cb) {
    const { user } = this.state;
    request
      .get(`/search/${user.email}`)
      .set('Content-Type', 'application/json')
      .end((err, result) => {
        if (err) {
          this.setState({ error: result.body.message });
        } else {
          this.setState({ error: null });
          cb && cb();
        }
      });
  }

  checkStackCoupon() {
    const stackCoupon = localStorage.getItem('stackCoupon');
    if (stackCoupon) {
      request
        .get(`/check_stack/${stackCoupon}`)
        .set('Content-Type', 'application/json')
        .end((error, result) => {
          if (error) {
            this.setState({ error: result.body.message, stackCoupon: '' });
          } else {
            this.setState({ error: null, stackCoupon });
          }
          localStorage.removeItem('stackCoupon');
        });
    }
  }

  setPreSelectedPlan() {
    const { plan_list } = this.state;
    const preSelected = getPreSelectedPlan(plan_list);
    this.setState({ plan_id: preSelected });
  }

  getPlanList() {
    const { stripe } = this.state;
    request
      .get('/api/v1/plans?regular=true')
      .set('Content-Type', 'application/json')
      .end((error, result) => {
        if (error) {
          this.setState({ error });
        } else {
          const planList = result.body.sort((a, b) => a.volume - b.volume) || [];
          planList && this.setState({
            plan_list: planList,
            stripe: { ...stripe, plan_id: planList[0].value },
          });
          planList && this.setPreSelectedPlan();
        }
      });
    return true;
  }

  nextStep() {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  }

  prevStep() {
    const { step } = this.state;
    this.setState({
      step: step - 1,
      error: null,
    });
  }

  changeUserDetailsHandler(event) {
    this.setState({ user: { ...this.state.user, [event.target.name]: event.target.value } });
  }

  selectPlan(plan) {
    this.setState({ plan_id: plan.value });
  }

  fbCallBackHandler(response) {
    const {
      name,
      email,
      accessToken,
      userID,
      expiresIn,
    } = response;
    const fbResponse = [name, email, accessToken, userID, expiresIn];
    if (fbResponse.every(v => v != null)) {
      this.setState({ account: response });
      this.nextStep();
    } else {
      console.log('Error: ', response);
    }
  }

  stripeCallbackHandler(strResponse,
    fanPagesList,
    userContainer) {
    if (strResponse.token) {
      this.setState({ stripe_account: strResponse });
      this.createAccount(fanPagesList, userContainer);
    } else {
      console.log('Stripe ERROR: ', strResponse.error);
    }
  }

  createAccount(fanPagesList, userContainer) {
    const data = this.state;
    data.pages.list = fanPagesList;
    data.stripe.plan_id = Cookies.get('priceSelected');
    this.setState({ loading: true });
    request
      .post('/registrations/public/create')
      .set('Content-Type', 'application/json')
      .send(data)
      .end((error, result) => {
        if (error) {
          const msg = _.has(result.body, 'error') ? result.body.error : result.body.message;
          this.setState({
            authToken: null, error: msg, loading: false, status: null,
          });
        } else {
          const { token } = result.body;
          localStorage.setItem('authToken', token);
          this.setState({
            authToken: token, error: null, loading: false, status: DASHBOARD,
          });
          userContainer.setState({
            status: DASHBOARD,
          });
        }
      });
  }
}

export default RegistrationContainer;
