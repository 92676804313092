import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Subscribe } from 'unstated';
import FacebookContainer from 'containers/FacebookContainer';
import UserContainer from 'containers/UserContainer';
import ComponentDidMount from 'containers/ComponentDidMount';
import Observer from 'containers/Observer';

const PrivateRoute = ({ component: Component, path, ...rest }) => (
  <Subscribe to={[FacebookContainer, UserContainer]}>
    {(facebook, userContainer) => (
      <ComponentDidMount exec={() => {
        userContainer.state.user === null && userContainer.getUserData();
        facebook.state.currentAccount === null && facebook.getAccountList();
      }}
      >
        <Route
          {...rest}
          render={(props) => {
            if (localStorage.getItem('authToken')) {
              return <Observer><Component {...props} /></Observer>;
            } else {
              return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
            }
          }}
        />
      </ComponentDidMount>
    )}
  </Subscribe>
);

export default PrivateRoute;
