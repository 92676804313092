import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import FormState from 'components/AddCouponCode/FormState';
import ProcessingState from 'components/AddCouponCode/ProcessingState';

import {
  ADD_COUPON_CODE_FORM,
  ADD_COUPON_CODE_ERROR,
  ADD_COUPON_CODE_SUCCESS,
  ADD_COUPON_CODE_PROCESSING,
} from 'appEvents';

const AddCouponCode = ({
  couponCode,
  error,
  errorType,
  changeHandler,
  submitStackCouponHandler,
}) => (
  <Fragment>
    {(couponCode.state.status === null || couponCode.state.status === ADD_COUPON_CODE_SUCCESS) && (
      <FormState
        couponCode={couponCode}
        changeHandler={changeHandler}
        submitStackCouponHandler={submitStackCouponHandler}
        errorType={errorType}
        error={error}
      />
    )}
    {couponCode.state.status === ADD_COUPON_CODE_PROCESSING && <ProcessingState />}
    {couponCode.state.status === ADD_COUPON_CODE_ERROR && (
      <FormState
        couponCode={couponCode}
        changeHandler={changeHandler}
        submitStackCouponHandler={submitStackCouponHandler}
        errorType={errorType}
        error={error}
      />
    )}
  </Fragment>
);

AddCouponCode.propTypes = {
  status: propTypes.oneOf([null,
    ADD_COUPON_CODE_FORM,
    ADD_COUPON_CODE_ERROR,
    ADD_COUPON_CODE_SUCCESS,
    ADD_COUPON_CODE_PROCESSING]),
  couponCode: propTypes.object.isRequired,
  changeHandler: propTypes.func.isRequired,
  submitStackCouponHandler: propTypes.func.isRequired,
  error: propTypes.string.isRequired,
  errorType: propTypes.string.isRequired,
};

export default AddCouponCode;
