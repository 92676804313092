import React from 'react';
import { func, object, string } from 'prop-types';
import BlockContainer from 'components/BlockContainer';

import {
  ADD_COUPON_CODE_ERROR,
  ADD_COUPON_CODE_SUCCESS,
} from 'appEvents';

const FormState = ({
  couponCode,
  changeHandler,
  submitStackCouponHandler,
  errorType,
  error,
}) => (
  <BlockContainer>
    <div className="flex flex-row w-100 pl4-ns pl4 pa1-ns pa1">
      <div className="flex flex-column w-100 pl-2">
        <div className="flex flex-row pt4-ns pt3">
          <p className="subTitle ma0">Stacking Code Redemption</p>
        </div>

        <div className="flex flex-row w-100 pt1-ns pt2 flex-wrap justify-between">
          <div className="flex pr3 pr3-ns">

            <form className="w-100 flex flex-column items-center justify-center">
              <div className="mistake flex flex-row w-100 items-center justify-center">
                {(errorType === ADD_COUPON_CODE_ERROR) && error}
              </div>
              <div className="success flex flex-row w-100 items-center justify-center">
                {(couponCode.state.status === ADD_COUPON_CODE_SUCCESS) && 'Stack Code Added.'}
              </div>

              <div className="flex flex-column items-center justify-center">
                <div className="pb2 w-100">
                  <p className="label">Enter your stacking code:</p>
                  <input
                                       name="stackCoupon"
                                       type="text"
                                       placeholder="Enter code here"
                                       onChange={changeHandler}
                                       value={couponCode.state.stackCoupon}
                                     />
                </div>
                <div className="pt2 w-100">
                  <button onClick={submitStackCouponHandler} className="ttu pointer addCouponCodeButton">Redeem Code</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
    <style jsx global>
      {`
          .error {
            border: 1px solid red;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: red;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:0.2rem;
          }
          .success {
            font-family: 'Open Sans', sans-serif;
            color: green;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:0.2rem;
          }
          .h-30rem {
            height: 30rem;
          }
          .addCouponCodeButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight:600;
            width: 10rem;
          }
          .step {
            width: 28px;
            height: 28px;
            padding-top: 3px;
            border: 2px solid white;
            border-radius: 2rem;
          }
          .active-step {
            color: #A94AEF;
            border: 2px solid #A94AEF;
            border-radius: 2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
          input {
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
          }
          input:hover {
            border: solid 1px #7049E2;
          }
          input {
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
            height: 3rem;
            line-height: 3rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
          }
      `}
    </style>
  </BlockContainer>
);

FormState.propTypes = {
  couponCode: object.isRequired,
  changeHandler: func.isRequired,
  submitStackCouponHandler: func.isRequired,
  errorType: string.isRequired,
  error: string.isRequired,
};

export default FormState;
