import React from 'react';
import ReactDOM from 'react-dom';
import FacebookLogin from 'react-facebook-login';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import Intercom from 'react-intercom';

import {
    SESSION_SUBMIT_FB_ERROR,
    SESSION_PERMISSION_FB_GRANT_ERROR
} from 'appEvents';


export default ({location, session, errorType, error}) => (
    <div className="flex flex-column w-100 vh-100-l h-100 bg-lightpink">
        <AuthTopBar location={location}/>
        <div className="flex flex-column justify-center items-center fg1 h-100 min-h-500px-l mb6-l">
            <div className="h2 mistake flex flex-row w-100 items-center justify-center">
                {errorType === SESSION_SUBMIT_FB_ERROR && error}
            </div>
            <div className="w-50rem-l mw7-l mw6 mt0-ns mt5-l mt4-m mt3 flex min-h-340px-l">
                <BlockContainer row>
                    <div className="w-60-l w-100 pa5-ns pa4 pt3rem-m-s">
                        <div className="dn-l flex flex-row justify-between mb-1-5-rem">
                            <div className="tc step active-step">1</div>
                            <div className="tc step moon-gray">2</div>
                            <div className="tc step moon-gray">3</div>
                        </div>
                        <h1 className="title ma2 mb0 flex items-center ml2-l ml0">
                            Connect Your Facebook Account
                        </h1>
                        <div className="flex flex-row mb5-l">
                            <div className="h2 mistake_perm flex flex-row w-100 items-center justify-center">
                                {errorType === SESSION_PERMISSION_FB_GRANT_ERROR && error}
                            </div>
                        </div>
                        <div
                            className="dn-l dib f7 mt3 moon-gray lh-copy mb4">{"We need this only to filter your page's  comments."}</div>
                        <FacebookLogin
                            appId={process.env.FB_APP_ID}
                            returnScopes={true}
                            autoLoad={false}
                            fields="email,name"
                            cookie={false}
                            scope={__fbAppScope}
                            textButton="Signup with Facebook"
                            onClick={() => {}}
                            callback={resp => {session.signup_login(resp)}}
                        />
                        <div className="f7 mt4 moon-gray lh-copy">
                            By clicking "Login with Facebook",<br className="dn-ns dib"/> I agree to the <br/> <a target="_blank" rel="noopener noreferrer" className="tos-link" href="/tos.html"><b className="black">Terms of Service</b></a>, <a target="_blank" rel="noopener noreferrer" className="tos-link" href="/privacy_policy.html"><b className="black">Privacy Policy</b></a> and <a target="_blank" rel="noopener noreferrer" className="tos-link" href="/cookie_policy.html"><b className="black">Cookie Policy</b></a>.
                        </div>
                    </div>
                    <div className="w-40 pa5 shadow dib-l dn">
                        <div className="flex flex-row justify-between">
                            <div className="tc step active-step">1</div>
                            <div className="tc step moon-gray">2</div>
                            <div className="tc step moon-gray">3</div>
                        </div>
                        <div className="tc mt5">
                            <img width="40px" src="/lock.svg"/>
                            <h1 className="f7">Connect to Facebook</h1>
                            <div
                                className="f7 mt3 moon-gray lh-copy">{"We need this only to filter your page's  comments."}</div>
                        </div>
                    </div>
                </BlockContainer>
            </div>
            <Intercom appID="hsn27dfu"/>
        </div>
        <style global jsx="true">{`
            .error {
              border: 1px solid red;
            }
             .mistake_perm {
                font-family: 'Open Sans', sans-serif;
                color: #F65763;
                font-weight: 700;
                font-size: 0.6rem;
                padding-top:0.2rem;
            }
            .mistake {
                font-family: 'Open Sans', sans-serif;
                color: #F65763;
                font-weight: 700;
                font-size: 0.8rem;
                padding-top:0.2rem;
            }
            div[data-react-class="App"], div[data-react-class="App"] > div {
              height: 100%;
              width: 100%;
            }
            .kep-login-facebook {
              font-size: 12px !important;
              width: 100% !important;
              background-color: #3C5A96 !important;
              border-radius: 2rem !important;
              -webkit-box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
              -moz-box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
              box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
            }
          `}</style>
        <style jsx="true">{`
                                  .tos-link {
                                   text-decoration: none;
                                }
                                .mb-1-5-rem {
                                  margin-bottom: 1.5rem;
                                }
                                @media screen and (max-width: 60em) {
                                  .pt3rem-m-s {
                                    padding-top: 3rem;
                                  }
                                  .step {
                                    width: 35px;
                                    height: 35px;
                                    font-size: 20px;
                                    padding-top: 3px;
                                    border: 2px solid white;
                                    border-radius: 2rem;
                                  }
                                  .active-step {
                                    color: #A94AEF;
                                    border: 2px solid #A94AEF;
                                    border-radius: 2rem;
                                  }
                                }
                                @media screen and (min-width: 60em) {
                                  .step {
                                    width: 28px;
                                    height: 28px;
                                    padding-top: 3px;
                                    border: 2px solid white;
                                    border-radius: 2rem;
                                  }
                                  .w-50rem-l {
                                    width: 50rem;
                                  }
                                  .min-h-500px-l {
                                    min-height:500px;
                                  }
                                  .min-h-340px-l {
                                    min-height:340px;
                                  }
                                  .active-step {
                                    color: #A94AEF;
                                    border: 2px solid #A94AEF;
                                    border-radius: 2rem;
                                  }
                                }
                              `}</style>
    </div>
)