import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';

const RemoveButton = ({
  facebook, id, reloadData,
}) => (
  <a href="#" onClick={() => { facebook.accountRemove(id, reloadData); }}>
    {' '}
    <ClearIcon />
    {' '}
  </a>
);

export default RemoveButton;
