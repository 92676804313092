import React from 'react';
import {arrayOf, object, bool, func} from 'prop-types';
import BlockContainer from 'components/BlockContainer';
import StopWordList from 'components/StopWordList';

class StopWords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "blacklist"
    };
    this.buttonClickHandler = this.buttonClickHandler.bind(this);
  };

  buttonClickHandler(name) {
    this.setState({ currentTab: name });
  }

  render () {
    const { currentTab } = this.state;
    const {
      blackList,
      whiteList,
      isBlackList,
      isWhiteList,
      setList,
      openModal,
      getListsError,
      deleteItem
    } = this.props;

    return (
      <BlockContainer className='fg1'>
        <div className="pl4-ns pl3 pr3 pb4 fg1 flex flex-column">
          <div className="flex flex-row justify-between items-center pr2">
            <div className="flex flex-row">
              <div className={`${isBlackList === true ? 'fade-in bt-violet' : 'bt-none'} pv-1-5-rem mr3`}>
                <button onClick={() => setList("blacklist")} className={`${isBlackList === true ? 'fade-in subTitle' : 'inactiveSubTitle'} ma0 pa0`}>Blacklist</button>
              </div>
              <div className={`${isWhiteList === true  ? 'fade-in bt-violet' : 'bt-none'} pv-1-5-rem mr3 ml3`}>
                <button onClick={() => setList("whitelist")} className={`${isWhiteList === true ? 'fade-in subTitle' : 'inactiveSubTitle'} ma0 pa0`}>Whitelist</button>
              </div>
            </div>
            <button className="addWord br-pill white pointer" onClick={() => openModal()}><span className="br-pill">+</span></button>
          </div>
          {isBlackList === true && blackList.length > 0 && <StopWordList list={blackList} deleteItem={deleteItem}/>}
          {isWhiteList === true && whiteList.length > 0 && <StopWordList list={whiteList} deleteItem={deleteItem}/>}
          {((isWhiteList === true  && whiteList !== null && whiteList.length === 0) || (isBlackList === true  && blackList !== null && blackList.length === 0)) &&
            <div className="flex flex-column items-center justify-center fg1" style={{paddingTop: "0.7rem"}}>
              <img src="/feather.svg" style={{width: "1.5rem", height: "auto"}}/>
              <p className="mh0 mv3 innerSubTitle">
                No words yet
              </p>
              <span className="tc w-100 text" style={{maxWidth: '11rem'}}>
                Click the “plus” button and complete the list with “trigger words”.
              </span>
            </div>
          }
          {getListsError === null && ((isWhiteList === true && whiteList === null) || (isBlackList === true && blackList === null)) &&
            <div className="flex flex-column w-100 h-100 items-center justify-center" style={{paddingTop: "0.7rem"}}>
              <img src="/feather.svg" style={{width: "1.5rem", height: "auto"}}/>
              <p className="mh0 mv3 innerSubTitle">
                Loading words
              </p>
            </div>
          }
        </div>
        <style jsx="true">
          {`
            .innerSubTitle {
              font-family: 'Open Sans', sans-serif;
              color: #382C58;
              font-weight: 500;
              font-size: 0.7rem;
            }
            .pv-1-5-rem {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
            }
            button.subTitle, button.inactiveSubTitle {
              background-color:white;
              border:transparent;
              outline:none;
            }
            button.addWord {
              border-radius: 5rem;
              height:2rem;
              width: 2rem;
              background-color: #7049E2;
              background-image:
                -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
              background-image:
                -webkit-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                -moz-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                -o-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                linear-gradient(to right, #7049E2, #9A6FED);
              border:transparent;
              outline:none;
              -webkit-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              -moz-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            }
            .subTitle {
              font-family: 'Open Sans', sans-serif;
              color: #382C58;
              font-weight: 700;
              font-size: 0.75rem;
            }
            .inactiveSubTitle {
              font-family: 'Open Sans', sans-serif;
              color: #9A8AA8;
              font-weight: 700;
              font-size: 0.75rem;
            }
            .bt-violet {
              border-top: solid 2px #7049E2;
            }
            .bt-none {
              border-top: solid 2px transparent;
            }
            @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            .fade-in {
              opacity:0.6;
              -webkit-animation:fadeIn ease-in 0.3s;
              -moz-animation:fadeIn ease-in 0.3s;
              animation:fadeIn ease-in 0.3s;

              -webkit-animation-fill-mode:forwards;
              -moz-animation-fill-mode:forwards;
              animation-fill-mode:forwards;
              -webkit-animation-duration:0.3s;
              -moz-animation-duration:0.3s;
              animation-duration:0.3s;
            }
          `}
        </style>
      </BlockContainer>
    )
  }
}

StopWords.propTypes = {
  blacklist: arrayOf(object),
  whitelist: arrayOf(object),
  isBlackList: bool,
  isWhiteList: bool,
  setList: func.isRequired,
  openModal: func.isRequired,
  getListsError: object,
  deleteItem: func.isRequired
}

export default StopWords;
