import React from 'react';
import { animateScroll as scroll} from 'react-scroll'

export default () => (
  <div className="relative gradient_slider_dealing_comments pt50px">
    <div className="circles_right_slider"></div>
    <div className="slider_radius">
      <div className="hidden_block">
        <div className="base body_background"></div>
      </div>
      <div onClick={() => scroll.scrollTo(1200)} className="circle_btn">
        <div className="arrow"></div>
      </div>
    </div>
  
    <div className="relative mw1465px z10000 center w-100 ph4-l ph3 mt4-m cf">
      <div className="s_text ph3">
        <ul>
          <li>
            <p className="lh-title-ns lh-copy dark-purple font42px ma0">Dealing with hater comments</p>
            <p className="lh-title-ns lh-copy light-gray font42px ma0">has never been easier <img className="h43px" src="girla.jpg"/></p>
          </li>
          <li className="s_l3 mv5 mh0-l center">
            Automagically scan Facebook comments on your page posts
            <br className="dib-ns dn"/>
            and hide any negative comments — all within a simple app.
          </li>
          <li className="s_l4">
            <a className="btn_blue mb0-ns mb4">GET STARTED</a>
            <span className="sp1 ml4-ns mh4">Tiers&nbsp;starting&nbsp;from&nbsp;<span className="fw7">$19.99</span></span>
          </li>
        </ul>
      </div>
      <div className="monitor mw7-l mw6 center pr4-ns pr3 pt50px fr w-100">
        <img src="mockup.png" className="cf"/>
      </div>
    </div>
    <style jsx="true">{`
      .gradient_slider_dealing_comments{
        background: linear-gradient(to top, #f6f7fb, #ffffff);
      }
      .pt50px {
        padding-top: 50px;
      }
      .font42px {
        font-size: 36px;
      }
      .light-gray {
        color:#9892a9;
      }
      .lh-1-7 {
        line-height: 1.7;
      }
      .lh-1 {
        line-height: 1;
      }
      .dark-purple {
        color: #382C58;
      }
      .h43px {
        height: 43px;
      }
      .mw1465px {
        max-width:1465px;
      }
      .z10000 {
        z-index: 10000;
      }
      
      .s_text{
        font-family: 'Roboto', sans-serif;
        position:absolute;
        top: 170px;
        left: 100px;
      }
      
      .s_text ul{
        margin-left:0;
        padding-left:0;
      }
      
      .s_l3{
        color:#6d5f79;
        line-height: 30px;
        font-size: 15px;
      }
      
      .sp1{
        color:#6d5f79;
        font-size: 14px;
        line-height: 30px;
      }
      @media (max-width:1340px) {
        .s_text {
          top: 150px;
        }
      
        .monitor img{
          float: right;
        }
      }
      @media (max-width:1199px) {
        .monitor, .s_text{
          position: relative;
          width: 100%;
          float: none;
          top: initial;
          left: initial;
          text-align: center;
        }
      }
      .slider_radius{
        position:absolute;
        width:100%;
        top: calc(100% - 100px);
        clear: both;
        height: 200px;
        z-index: 100;
      }
      .slider_radius .base{
        position: absolute;
        display: block;
        width: 110%;
        height: 100%;
        left: -5%;
        border-radius: 100%;
      }
      
      .slider_radius .hidden_block{
        position: relative;
        overflow: hidden;
        width:100%;
        height:100%;
      }
      
      .slider_radius .circle_btn{
        position:absolute;
        width:58px;
        height:58px;
        background-color: #ffffff;
        left: calc(50% - 29px);
        top: calc(100% - 29px);
        border-radius:29px;
        box-shadow: 0 20px 30px rgba(50, 37, 84, 0.05);
        cursor:pointer;
      }
      
      .slider_radius .arrow{
        width:10px;
        height:10px;
        border-left:2px solid #6d5f79;
        border-bottom:2px solid #6d5f79;
        transform: rotate(-45deg);
        position:relative;
        top:calc(50% - 5px);
        left:calc(50% - 5px);
      }
      
      
      .slider_radius::before{
        content:'';
        display:block;
        width:100%;
        height:100px;
        background:#ffffff;
        position:absolute;
        top:100px;
      }
      
    `}</style>
  </div>
);
