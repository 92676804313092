import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from 'components/CardForm';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import CouponCodeElement from 'components/CouponCodeElement';
import SelectPlan from 'components/SelectPlan';
import Loader from 'components/Loader';

const PaymentDetails = ({
  location,
  registration,
  fanPages,
  userContainer,
}) => (
  <div className="flex flex-column w-100 vh-100-l h-100 bg-lightpink">
    <AuthTopBar location={location} />
    <div className="flex flex-column justify-center items-center fg1 mb6-l">
      <div className="w-50rem-l mw8-l mw6 mw21rem-xs w-100 mt0-ns mt4-m mt3 flex">
        <BlockContainer row>

            {/*<!-- Start 1 Column -->*/}
            <div className="w-50-l w-100 pa5-ns pl3-ns pr2-ns pa4 pt3rem-m-s flex flex-column">
              <div className="dn-l flex flex-row justify-between mb-1-5-rem">
                <div className="tc step moon-gray">1</div>
                <div className="tc step moon-gray">2</div>
                <div className="tc step active-step">3</div>
              </div>
              <h1 className="title mv2">Payment details</h1>
              <div className="dn-l dib f7 moon-gray lh-copy mb2">
                We'll use these only to deal with the subsciption
              </div>
              <div className="w-100 mt3">
                {registration.state.error
                && (
                    <div className="flex flex-row justify-center flex-wrap overflow-y-auto">
                      <p className="mistake">{registration.state.error}</p>
                    </div>
                )}
                {registration.state.loading && <Loader center />}
                <Elements stripe={getStripe()}>
                  <SelectPlan
                      options={registration.state.plan_list}
                      value={registration.state.plan_id}
                      selectHandler={e => registration.selectPlan(e)}
                  />
                  <CouponCodeElement registration={registration} />
                  <CardForm
                      registration={registration}
                      fanPagesList={fanPages.state.list}
                      userContainer={userContainer}
                  />
                </Elements>
              </div>
            </div>
            {/*<!-- End 1 Column -->*/}

            {/*<!-- Start 2 Column -->*/}
            <div className="pa5 pl2 pr2 shadow dib-l dn flex flex-column hideMin60">
              <div className="flex flex-row justify-center dn-m">
                <div className="tc step moon-gray mr5">1</div>
                <div className="tc step moon-gray mr5">2</div>
                <div className="tc step active-step">3</div>
              </div>
              <div className="tc mt4">
                <img width="40px" src="/wallet.svg" />
                <h1 className="f7">Payment details</h1>
                {/* <div className="f7 mt3 moon-gray lh-copy">{"We'll use these only to deal with the subsciption "}</div> */}
              </div>
              <div className="flex flex-auto flex-column flex-row-ns">
                <div className="tc ma2 price-block">
                  <div className="flex flex-column">
                    <div className="tier-nickname">Tier Free</div>
                    <div className="tier-price">
                      <span
                          className="tier-price-label"
                      >
                      7 Day Free Trial
                    </span>
                    </div>
                    <div className="tier-amount-label">Up to 100 analyzed comments/mo</div>
                  </div>
                </div>
                <div className="tc ma2 price-block">
                  <div className="flex flex-column">
                    <div className="tier-nickname">Tier 1</div>
                    <div className="tier-price">
                      $19
                      <span
                          className="tier-price-label"
                      >
                      per month
                    </span>
                    </div>
                    <div className="tier-amount-label">Up to 150 analyzed comments/mo</div>
                  </div>
                </div>
                <div className="tc ma2 price-block">
                  <div className="flex flex-column">
                    <div className="tier-nickname">Tier 2</div>
                    <div className="tier-price">
                      $49
                      <span
                          className="tier-price-label"
                      >
                      per month
                    </span>
                    </div>
                    <div className="tier-amount-label">Up to 500 analyzed comments/mo</div>
                  </div>
                </div>
                <div className="tc ma2 price-block">
                  <div className="flex flex-column">
                    <div className="tier-nickname">Tier 3</div>
                    <div className="tier-price">
                      $99
                      <span
                          className="tier-price-label"
                      >
                      per month
                    </span>
                    </div>
                    <div className="tier-amount-label">Up to 1500 analyzed comments/mo</div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- End 2 Column -->*/}
        </BlockContainer>
      </div>
    </div>
    <style jsx="true" global="true">
      {`
                    .tier-amount-label{
                        position: relative;
                        color: #4e5c66;
                        font-size: 12px;
                        font-weight: 400;
                        padding-top: 40px;
                    }
                    .tier-price-label{
                        position: relative;
                        color: #4e5c66;
                        font-size: 12px;
                        font-weight: 400;
                    }
                    .tier-price {
                        position:relative;
                        color:#293039;
                        font-size:22px;
                        font-weight:500;
                        border-bottom:1px solid #edecf1;
                        padding-bottom: 20px;
                    }
                    .tier-nickname {
                        color: #293039;
                        font-size: 14px;
                    }
                    .price-block {
                        position:relative;
                        padding:42px 2px;
                        text-align:center;
                        border-radius:10px;
                        background-color:#ffffff;
                        -webkit-transition:all 300ms ease;
                        -ms-transition:all 300ms ease;
                        -o-transition:all 300ms ease;
                        -moz-transition:all 300ms ease;
                        transition:all 300ms ease;
                        -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                        -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                        box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                    }
            .StripeElement {
              padding: 14px;
              border-radius: 0.25rem;
              border: solid 1px #EAD7FB;
            }
            .StripeElement:hover {
              border: solid 1px #7049E2;
            }
            div[data-react-class="App"], div[data-react-class="App"] > div {
              height: 100%;
              width: 100%;
            }
            .outlineButton {
              margin-top:0.7rem;
              margin-right:0.3rem;
              color: #7049E2;
              border: 1px solid #EAD7FB;
              border-radius: 5rem;
              background: transparent;
              height: 3rem;
              line-height: 2.9rem;
              outline: none;
              font-family: 'Montserrat', sans-serif;
              font-size: 0.6rem;
              font-weight:600;
              width: 10rem;
            }
            .filledButton {
              margin-left:0.3rem;
              margin-top:0.7rem;
              color: white;
              border-radius: 5rem;
              height: 3rem;
              line-height: 2.9rem;
              background-color: #7049E2;
              background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
              background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
              background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
              background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
              background-image: linear-gradient(to right, #7049E2, #9A6FED);
              border: 1px solid transparent;
              outline: none;
              -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
              -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
              box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
              font-family: 'Montserrat', sans-serif;
              font-size: 0.6rem;
              font-weight:600;
              width: 10rem;
            }
            input {
              outline: none;
              padding-top:0;
              padding-bottom:0;
              width: auto;
              font-family: 'Open Sans', sans-serif;
              color: #382C58;
              font-weight: 600;
              font-size: 0.65rem;
              border: none;
              height: 1rem;
              line-height: 1rem;
            }
            div.inputWrapper {
              height: 3rem;
              padding-left: 1rem;
              padding-right:1rem;
              border-radius: 0.25rem;
              border: solid 1px #EAD7FB;
            }
            div.valid {
              border: solid 1px #EAD7FB;
            }
            div.inputWrapper:hover {
              border: solid 1px #7049E2;
            }
            div.active {
              border: solid 1px #7049E2;
            }
            div.invalid {
              border: solid 1px #F65763;
            }
            .mistake {
              font-family: 'Open Sans', sans-serif;
              color: #F65763;
              font-weight: 700;
              font-size: 0.6rem;
              padding-top:0.2rem;
            }
            .label {
              font-family: 'Open Sans', sans-serif;
              color: #382C58;
              font-weight: 700;
              font-size: 0.6rem;
              padding-top:0.2rem;
            }
            @media screen and (max-width: 60em) and (min-width: 25em) {
              .outlineButton {
                margin-top:0.7rem;
                margin-right:1rem;
                color: #7049E2;
                border: 1px solid #EAD7FB;
                border-radius: 5rem;
                background: transparent;
                height: 3rem;
                line-height: 2.9rem;
                outline: none;
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 100%;
                white-space:nowrap;
                padding-left:2rem;
                padding-right:2rem;
              }
              .filledButton {
                margin-left:1rem;
                margin-top:0.7rem;
                color: white;
                border-radius: 5rem;
                height: 3rem;
                line-height: 2.9rem;
                background-color: #7049E2;
                background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
                background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
                background-image: linear-gradient(to right, #7049E2, #9A6FED);
                border: 1px solid transparent;
                outline: none;
                -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 100%;
              }
            }
            @media screen and (max-width: 25em) {
              .hideMax25 {
                display: none;
              }
              .outlineButton {
                margin-top:0.7rem;
                margin-right:0.3rem;
                color: #7049E2;
                border: 1px solid #EAD7FB;
                border-radius: 5rem;
                background: transparent;
                height: 3rem;
                line-height: 2.9rem;
                outline: none;
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 100%;
                white-space:nowrap;
                padding-left:1rem;
                padding-right:1rem;
              }
              .filledButton {
                margin-left:0.3rem;
                margin-top:0.7rem;
                color: white;
                border-radius: 5rem;
                height: 3rem;
                line-height: 2.9rem;
                background-color: #7049E2;
                background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
                background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
                background-image: linear-gradient(to right, #7049E2, #9A6FED);
                border: 1px solid transparent;
                outline: none;
                -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 100%;
              }
            }
            @media screen and (min-width: 60em) {
              .outlineButton {
                margin-top:0.7rem;
                margin-right:0.3rem;
                color: #7049E2;
                border: 1px solid #EAD7FB;
                border-radius: 5rem;
                background: transparent;
                height: 3rem;
                line-height: 2.9rem;
                outline: none;
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 10rem;
              }
              .filledButton {
                margin-left:0.3rem;
                margin-top:0.7rem;
                color: white;
                border-radius: 5rem;
                height: 3rem;
                line-height: 2.9rem;
                background-color: #7049E2;
                background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
                background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
                background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
                background-image: linear-gradient(to right, #7049E2, #9A6FED);
                border: 1px solid transparent;
                outline: none;
                -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
                box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
                font-family: 'Montserrat', sans-serif;
                font-size: 0.6rem;
                font-weight:600;
                width: 10rem;
              }
            }
          `}
    </style>
    <style jsx="true">
      {`
            @media screen and (max-width: 25em) {
              .mw21rem-xs {
                max-width: 21rem;
              }
            }
            .mb-1-5-rem {
              margin-bottom: 1.5rem;
            }
            @media screen and (max-width: 60em) {
               .hideMin60 {
                display: none;
              }
              .pt3rem-m-s {
                padding-top: 3rem;
              }
              .step {
                width: 35px;
                height: 35px;
                font-size: 20px;
                padding-top: 3px;
                border: 2px solid white;
                border-radius: 2rem;
              }
              .active-step {
                color: #A94AEF;
                border: 2px solid #A94AEF;
                border-radius: 2rem;
              }
            }
            @media screen and (min-width: 60em) {
              .step {
                width: 28px;
                height: 28px;
                padding-top: 3px;
                border: 2px solid white;
                border-radius: 2rem;
              }
              .w-50rem-l {
                width: 60rem;
              }
              .min-h-500px-l {
                min-height:500px;
              }
              .min-h-330px-l {
                min-height:330px;
              }
              .active-step {
                color: #A94AEF;
                border: 2px solid #A94AEF;
                border-radius: 2rem;
              }
            }
          `}
    </style>

  </div>

);

export default PaymentDetails;

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(__stPk);
  }
  return stripePromise;
};
