import React from 'react';
import { func, string } from 'prop-types';
import _ from 'lodash';

const disableBtn = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const FormState = ({
  submitEmail,
  closeModal,
  onEmailChange,
  validateFormInput,
  email,
  error,
}) => (
  <div className="flex flex-column center mt5 w-100 mw6">
    <form className="w-100 flex flex-column items-center justify-center">
      <div className="mistake flex flex-row w-100 items-center justify-center">
        {error && error}
      </div>
      <div className="pb3 w-70">
        <p className="label">Type Your Email</p>
        <input
          type="email"
          name="email"
          placeholder="john@example.com"
          className="w-100"
          onChange={onEmailChange}
          onBlur={validateFormInput}
          value={email}
        />
      </div>

      <div className="pb3 w-70 flex flex-row items-center justify-center">
        <div className="pa1">
          <button type="button" onClick={closeModal} className="ttu pointer filledButton">Cancel</button>
        </div>
        <div className="pa1">
          <button
            type="button"
            onClick={(e) => { error ? disableBtn(e) : submitEmail(); }}
            className={`ttu ${error ? '' : 'pointer'} filledButton w-10rem-l w-100`}
            style={{ opacity: error ? 0.4 : 1 }}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
    <style jsx="true" global="true">
      {`
          .error {
            border: 1px solid red;
          }
          .mistake {
            font-family: 'Open Sans', sans-serif;
            color: #F65763;
            font-weight: 700;
            font-size: 0.8rem;
            padding-top:0.2rem;
          }
          .h-30rem {
            height: 30rem;
          }
          .filledButton {
            color: white;
            border-radius: 5rem;
            height: 3rem;
            line-height: 2.9rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: 1px solid transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight:600;
            width: 10rem;
          }
          .step {
            width: 28px;
            height: 28px;
            padding-top: 3px;
            border: 2px solid white;
            border-radius: 2rem;
          }
          .active-step {
            color: #A94AEF;
            border: 2px solid #A94AEF;
            border-radius: 2rem;
          }
          .login-block-wrapper {
            width: 50rem;
          }
          input {
            padding-left: 14px;
            padding-right: 14px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
          }
          input:hover {
            border: solid 1px #7049E2;
          }
          input {
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
            height: 3rem;
            line-height: 3rem;
          }
          .label {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
          }
      `}
    </style>
  </div>
);

FormState.propTypes = {
  submitEmail: func.isRequired,
  closeModal: func.isRequired,
  onEmailChange: func.isRequired,
  validateFormInput: func.isRequired,
  email: string.isRequired,
  error: string.isRequired,
};

export default FormState;
