import React from 'react';
import AuthTopBar from 'components/AuthTopBar';
import Loader from 'components/Loader';
import BlockContainer from 'components/BlockContainer'

export default () => (
    <div style={{minHeight: '43rem'}} className="flex flex-column w-100 h-100 bg-lightpink">
        <AuthTopBar location={location} />
        <div className="flex flex-column justify-center align-center center mt5">
            <div className="login-block-wrapper mw6">
                <BlockContainer row>
                    <div className="w-100 pa5 flex flex-column items-center justify-center h-30rem">
                        <h1 className="title ma2 mb4">Sign Up</h1>
                        <div className="fg1 flex flex-column items-center justify-center">
                            <Loader/>
                        </div>
                    </div>
                </BlockContainer>
            </div>
        </div>
        <style jsx="true">{`
      .h-30rem {
        height: 30rem;
      }
      .login-block-wrapper {
        width: 50rem;
      }
    `}</style>
    </div>
)
