import React, { Fragment } from 'react';
import UserSignupDetails from 'components/UserSignupDetails';
import FacebookSignupDetails from 'components/FacebookSignupDetails';
import FacebookFanPageList from 'components/FacebookFanPageList';
import ComponentDidMount from 'containers/ComponentDidMount';
import PaymentDetails from 'pages/PaymentDetails';

const Registration = ({
  location,
  registration,
  fanPages,
  userContainer,
}) => {
  const { step, error } = registration.state;
  const {
    name, email, company, password,
  } = registration.state.user;
  const values = {
    name, email, company, password,
  };
  return (
    <ComponentDidMount exec={() => {
      registration.getPlanList();
      registration.checkStackCoupon();
    }}
    >
      <Fragment>
        {step === 1 && (
          <UserSignupDetails
            location={location}
            values={values}
            changeUserDetailsHandler={registration.changeUserDetailsHandler}
            checkUserExists={registration.checkUserExists}
            error={error}
            nextStep={registration.nextStep}
          />
        )}
        {step === 2 && (
          <FacebookSignupDetails
            location={location}
            fbCallBackHandler={registration.fbCallBackHandler}
            nextStep={registration.nextStep}
          />
        )}
        {step === 3 && (
          <FacebookFanPageList
            location={location}
            registration={registration}
            fanPages={fanPages}
            userContainer={userContainer}
          />
        )}
        {step === 4 && __trial !== 'true' && (
          <PaymentDetails
            location={location}
            registration={registration}
            fanPages={fanPages}
            userContainer={userContainer}
          />
        )}
      </Fragment>
    </ComponentDidMount>
  );
};

export default Registration;
