import React from 'react';

export default ({ profileUrl, profileName }) => (
  <div className="flex flex-column justify-start items-center">
    <p className="boldText ma0 pb1">Connected Account</p>
    <div
      style={{
        width: '5rem',
        height: '5rem',
        borderRadius: '4rem',
        backgroundImage: `url(${profileUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      className="mr2"
    />

    <div className="boldText ma0 pt2 pb1">{_.capitalize(`${profileName}`)}</div>

    <style jsx="true">
      {`
                .boldText {
                  font-family: 'Open Sans', sans-serif;
                  color: #382C58;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
                .statusText {
                  font-family: 'Open Sans', sans-serif;
                  color: #C2BECC;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
            `}
    </style>
  </div>
);
