import React from 'react';
import propTypes from 'prop-types';

const InvalidList = ({ list }) => (
  <div>
    <ul className="list pl0 boldText">
      {list && list.map(l => (
        <li key={l.id}>
          {l.name}
          {' - '}
          {l.email}
        </li>
      ))}
    </ul>
    <style jsx="true">
      {`
        .boldText {
          font-family: 'Open Sans', sans-serif;
          color: #FF0000;
          font-size: 0.8rem;
          line-height:1.9;
        }      
      `}
    </style>
  </div>
);

InvalidList.propTypes = {
  list: propTypes.array,
};

export default InvalidList;
