import React from 'react';
import {
  number, string, bool, func,
} from 'prop-types';

import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const PageItem = ({
  id, name, image, active, selectPage, closeSidebar,
}) => (
  <div className="flex flex-row items-center mb0-ns mb3 mw5 pl0-ns pl2">
    <Tooltip
      title={name}
      position="right"
      trigger="mouseenter"
      distance={-40}
    >
      <div
        id={id}
        onClick={() => {
          closeSidebar();
          selectPage(id);
        }}
        className={`page-icon size-37px-ns size-53px mb-1-and-3-rem-ns br-0-7-rem pointer shadow ${active === true ? 'b-violet' : ''}`}
        style={{ background: `url(${image})`, backgroundPosition: 'center', backgroundSize: '100%' }}
      />
    </Tooltip>
    <div className="dn-ns dib pl2 ellipsis">{name}</div>
    <style jsx="true">
      {`
          .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-rendering: auto;
          }
          .br-0-7-rem {
            border-radius: 0.7rem;
          }
          @media (min-width: 30em) {
            .size-37px-ns {
              width: 37px;
              height: 37px;
            }
            .mb-1-and-3-rem-ns {
              margin-bottom: 1.3rem;
            }
          }
          @media (max-width: 30em) {
            .size-53px {
              width: 53px;
              height: 53px;
            }
          }
          .shadow {
            -webkit-box-shadow: 0px 6px 15px 0px rgba(239,224,252,1);
            -moz-box-shadow: 0px 6px 15px 0px rgba(239,224,252,1);
            box-shadow: 0px 6px 15px 0px rgba(239,224,252,1);
          }
          .page-icon {
            border: solid 2px transparent;
          }
          .page-icon:hover, .b-violet {
            border: solid 2px #7049E2 !important;
          }
          .size-1-and-9-rem {
            width: 37px;
            height: 37px;
          }
        `}
    </style>
  </div>
);

PageItem.propTypes = {
  id: number.isRequired,
  image: string.isRequired,
  active: bool.isRequired,
  selectPage: func.isRequired,
};

export default PageItem;
