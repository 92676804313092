import { Container } from 'unstated';

const request = require('superagent');

class CommentsContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      pageId: null,
      comments: [],
      currentEpoch: 'week',
      currentFilter: 'all',
      error: null,
      showErrorModal: false,
    };
    this.getAll = this.getAll.bind(this);
    this.toggleComment = this.toggleComment.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.showReplayFormHandler = this.showReplayFormHandler.bind(this);
    this.submitReplayHandler = this.submitReplayHandler.bind(this);
    this.loadReplaysList = this.loadReplaysList.bind(this);
    this.showReplaysListHandler = this.showReplaysListHandler.bind(this);
  }

  showReplaysListHandler(event, parentId) {
    event.preventDefault();
    const { comments } = this.state;
    const updComments = comments.map(cmt => (cmt.id === parentId ? {
      ...cmt,
      replayListDisplay: 'replayListDisplay' in cmt ? !cmt.replayListDisplay : true,
    } : cmt));
    this.setState({comments: updComments});
    const reloadReplays = updComments.filter(cmt => cmt.id === parentId && cmt.replayListDisplay === true).length;
    reloadReplays && this.loadReplaysList(parentId);
  }

  loadReplaysList(parentId) {
    this.setState({
      comments: this.state.comments.map(cmt => (cmt.id === parentId ? {
        ...cmt,
        replaysLoading: true,
      } : cmt)),
    });
    request
      .get(`/api/v1/comments/${parentId}/get-replays`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        this.setState({
          comments: this.state.comments.map(cmt => (cmt.id === parentId ? {
            ...cmt,
            replaysLoading: false,
            replays: response.body,
          } : cmt)),
        });
        if (error) {
          console.log('Error: ', error);
        }
      });
  }

  showReplayFormHandler(comment) {
    const { comments } = this.state;
    this.setState({
      comments: comments.map(cmt => (cmt.id === comment.id ? {
        ...cmt,
        replayFormDisplay: 'replayFormDisplay' in cmt ? !cmt.replayFormDisplay : true,
      } : cmt)),
    });
  }

  submitReplayHandler(event, parentId) {
    event.preventDefault();
    const text = event.currentTarget.textContent;
    if (text) {
      this.setState({
        comments: this.state.comments.map(cmt => (cmt.comment_fbid === parentId ? {
          ...cmt,
          replayListDisplay: true,
          replays: [{ message: text }],
        } : cmt)),
      });
      request
        .post('/api/v1/comments/submit-replay')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({ replay: { web_id: parentId, text } })
        .end((error, response) => {
          this.setState({
            comments: this.state.comments.map(cmt => (cmt.comment_fbid === parentId ? {
              ...cmt,
              replayListDisplay: true,
              replays: cmt.replays.map(rpl => ('id' in rpl ? rpl : response.body)),
            } : cmt)),
          });

          if (error) {
            console.log('Error: ', error);
          }
        });
    }
    return true;
  }

  closeErrorModal() {
    this.setState({ showErrorModal: false });
  }

  handleNewComment(comment) {
    const { pageId, comments } = this.state;
    if (pageId && comment.page_id === pageId) {
      this.setState({ comments: [comment, ...(comments || []).filter(c => (c.id !== comment.id))].sort((a, b) => b.id - a.id) });
    }
  }

  toggleComment(item) {
    const { id } = item;
    const commentState = this.state.comments.find(c => c.id === id);
    const status = commentState.is_hidden === true ? 'unhide' : 'hide';
    request
      .patch(`/api/v1/comments/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({ comment: { status } })
      .end((error, response) => {
        if (error) {
          this.setState({
            showErrorModal: true,
            error: response.body.error && JSON.stringify(response.body.error),
          });
        } else {
          const { comments } = this.state;
          this.setState({
            comments: comments.map((c) => {
              if (c.id === id) {
                c.is_hidden = response.body.is_hidden;
                return { ...c };
              }
              return c;
            }),
            error: null,
            showErrorModal: false,
          });
        }
      });
    return true;
  }

  getAll(pageId, timeframe, filterBy) {
    const { currentEpoch } = this.state;
    const sinceEpoch = (timeframe) => {
      const epochMap = {
        week: (new Date()).setDate((new Date()).getDate() - 7),
        month: (new Date()).setDate((new Date()).getDate() - 31),
        all: (new Date()).setDate((new Date()).getDate() - 1000),
        today: (new Date()).setDate((new Date()).getDate() - 1),
      };
      return parseInt(epochMap[timeframe || currentEpoch] / 1000);
    };
    const url = `/api/v1/comments/?filter_by=${filterBy}&page_id=${pageId}&since_epoch=${sinceEpoch(timeframe)}&limit=200`;
    request
      .get(url)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({
            pageId,
            comments: response.body,
            currentEpoch: timeframe,
            currentFilter: filterBy,
          });
        }
      });
    return true;
  }
}

export default CommentsContainer;
