import React from 'react';
import propTypes from 'prop-types';
import StopWordItem from 'components/StopWordItem';

const StopWordList = ({list, deleteItem}) => {
  return (
    <div className="relative flex flex-column" style={{flexShrink:60}}>
      <div className="overflow-y-auto fade-in h-95 pb2">
        {list.map(listItem => {
          return (
            <div key={listItem.id} className="pr3">
              <StopWordItem listItem={listItem} deleteItem={deleteItem}/>
            </div>
          )
        })}
      </div>
      {list.length > 3 && <div className="h-1-5-rem absolute b0 white-gradient-overlay"/>}
      <style jsx="true">
        {`
          .h-95 {
            height: 95%;
          }
          .h6rem {
            height: 6.2rem;
          }
          .h-1-5-rem {
            height: 1.5rem;
          }
          .b0 {
            bottom: 0;
          }
          .white-gradient-overlay {
            background: transparent;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 46%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            width: 101%; // to overlay the scrollbar
          }
          @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          .fade-in {
            opacity:0.6;
            -webkit-animation:fadeIn ease-in 0.3s;
            -moz-animation:fadeIn ease-in 0.3s;
            animation:fadeIn ease-in 0.3s;
            -webkit-animation-fill-mode:forwards;
            -moz-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
            -webkit-animation-duration:0.3s;
            -moz-animation-duration:0.3s;
            animation-duration:0.3s;
          }
        `}
      </style>
    </div>
  );
}

StopWordList.propTypes = {
  list: propTypes.arrayOf(propTypes.object).isRequired,
  deleteItem: propTypes.func.isRequired
};

export default StopWordList;
