import React from 'react';
import {
  shape, string, func, array, object,
} from 'prop-types';
import Menu from 'components/Menu';
import Pages from 'components/Pages';

const MenuSidebar = ({
  location,
  goTo,
  subscribedPages,
  currentPage,
  openModal,
  closeSidebar,
  selectPage,
  logout,
  className,
}) => {
  const menuItems = [
    {
      name: 'Home',
      active: location.pathname === '/dashboard',
      path: '/dashboard',
    },
    {
      name: 'Settings',
      active: location.pathname === '/settings',
      path: '/settings',
    },
    {
      name: 'Logout',
      active: location.pathname === '/logout',
      path: '/logout',
    },
  ];

  return (
    <div className={`${className} h-100 w-105px-ns w-100 justify-between bg-white sidebar-shadow-ns flex-column items-center pt-2-and-half-rem-ns pt4 relative fixed-m`} style={{ minHeight: '100vh', left: 0 }}>
      <div className="flex flex-column items-center mh3 min-h-15rem-ns fg1 w-100">
        <div className="flex flex-row justify-center-ns justify-between w-100 ph0-ns ph4 pb0-ns pb3 items-center">
          <a onClick={() => { goTo('/dashboard'); }}>
            <img className="w-1-and-4-rem pb-2-and-half-rem-ns pointer" src="/sg-logo.svg" />
          </a>
          <span className="f3 fw6 dn-ns dib pointer w2 h2 tc" onClick={closeSidebar}>×</span>
        </div>
        {subscribedPages && (
        <Pages
          subscribedPages={subscribedPages}
          currentPage={currentPage}
          openModal={openModal}
          selectPage={selectPage}
          closeSidebar={() => { closeSidebar(); }}
        />
        )}
      </div>
      <div className="flex flex-column-ns flex-row w-100 justify-end min-h-15rem-ns">
        <Menu
          menuItems={menuItems}
          goTo={(l) => { goTo(l); }}
          logout={() => { logout(); }}
          closeSidebar={() => { closeSidebar(); }}
        />
      </div>
      <style jsx="true">
        {`
          @media (min-width:30em) {
            .min-h-15rem-ns {
              min-height: 15rem;
            }
            .w-105px-ns {
              width: 105px;
            }
            .pb-2-and-half-rem-ns {
              padding-bottom: 2.5rem;
            }
            .pt-2-and-half-rem-ns {
              padding-top: 2.5rem;
            }
            .sidebar-shadow-ns {
              -webkit-box-shadow: 5px 0px 6px 0px rgb(240,238,246);
              -moz-box-shadow: 5px 0px 6px 0px rgb(240,238,246);
              box-shadow: 5px 0px 6px 0px rgb(240,238,246);
            }
          }
          .w-1-and-4-rem {
            width: 1.4rem;
          }
        `}
      </style>
    </div>
  );
};

MenuSidebar.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  goTo: func.isRequired,
  openModal: func.isRequired,
  selectPage: func.isRequired,
  logout: func.isRequired,
  subscribedPages: array,
  currentPage: object,
  className: string,
  closeSidebar: func,
};

MenuSidebar.defaultProps = {
  subscribedPages: [],
  currentPage: null,
  className: 'flex',
  closeSidebar: () => {},
};

export default MenuSidebar;
