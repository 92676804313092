import React from 'react';
import BlockContainer from 'components/BlockContainer';
import FacebookLogin from 'react-facebook-login';
import FbAccountListDataTable from '../FbAccountListDataTable';

const FacebookAccountBlock = ({ facebook, reloadData }) => {
  return (
    <BlockContainer>
      <div className="pa4-ns pa3">
        <p className="subTitle ma0">Connected Facebook Accounts</p>
        <div className="tc flex-row pt4-ns pt3 items-center flex-nowrap-ns flex-wrap">
          <FbAccountListDataTable facebook={facebook} reloadData={reloadData} />
          <div className="tr">
            <FacebookLogin
              appId={process.env.FB_APP_ID}
              autoLoad
              fields="email,name"
              cookie={false}
              scope={__fbAppScope}
              textButton="Add or Update Facebook Account"
              cssClass="btnFB"
              size="large"
              onClick={() => {
              }}
              callback={(response) => {
                if(_.has(response, 'accessToken')) {
                  facebook.dataLoadStart();
                  facebook.updateAccount(response, reloadData);
                }
              }}
            />
          </div>
          {/* <div className="flex flex-row justify-start items-center"> */}
          {/*  <div className="flex flex-row justify-start items-center"> */}
          {/*      <div */}
          {/*        style={{ */}
          {/*          width: '5rem', */}
          {/*          height: '5rem', */}
          {/*          borderRadius: '4rem', */}
          {/*          background: `url(${avatar})`, */}
          {/*          backgroundPosition: 'center', */}
          {/*          backgroundSize: '100%' */}
          {/*        }} */}
          {/*        className="mr2" */}
          {/*      /> */}
          {/*    <div className="flex flex-column justify-start items-left"> */}
          {/*      <span className="boldText ma0 pl2 pb1"> */}
          {/*        <a className="no-underline black" href={profileUrl} target="_blank" >{name}</a> */}
          {/*      </span> */}
          {/*      <span className="emailText ma0 pl2 no-underline">{email}</span> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </div> */}
          <button className="ml3 ph3 fw6 ttu remove pointer dn">Remove this connection</button>
        </div>
      </div>
      <style jsx="true">
        {`
        .boldText {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 700;
          font-size: 0.6rem;
        }
        .emailText {
          font-family: 'Open Sans', sans-serif;
          color: #C2BECC;
          font-weight: 700;
          font-size: 0.6rem;
        }
        .social-icon {
          border-radius: 5rem;
          height: 2rem;
          width: 2rem;
        }
        .fb {
          border: solid 1px #484B99;
        }
        .tw {
          border: solid 1px #3A91F6;
        }
        .ln {
          border: solid 1px #236CB6;
        }
        .social-icon-img {
          height: 0.5rem;
          width: 0.5rem;
        }
        .splitter {
          color: #9A8AA8;
        }
        .fg1 {
          flex-grow: 1;
        }
        button.remove {
          border-radius: 5rem;
          height: 2rem;
          background-color: white;
          color: rgb(154, 138, 168);
          border-color: #EAD7FB;
          border-style: solid;
          border-width: 1px;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.5rem;
          font-weight:700;
        }
        input {
          outline: none;
          padding-top:0;
          padding-bottom:0;
          border-radius: 0.25rem;
          border: solid 1px #9A8AA8;
          height: 2rem;
          line-height: 2rem;
          width: auto;
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 600;
          font-size: 0.65rem;
        }
      `}
      </style>
    </BlockContainer>
  );
};

export default FacebookAccountBlock;
