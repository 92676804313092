import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import InitialState from 'components/AddNewCard/InitialState';
import FormState from 'components/AddNewCard/FormState';
import ProcessingState from 'components/AddNewCard/ProcessingState';

import {
  ADD_NEW_CARD_FORM,
  ADD_NEW_CARD_PROCESSING,
} from 'appEvents';

const AddNewCard = ({
  status,
  addNewClickHandler,
  submitCardHandler,
  cancelHandler,
}) => (
  <Fragment>
    {status === null && <InitialState addNewClickHandler={addNewClickHandler} />}
    {status === ADD_NEW_CARD_FORM && (
      <FormState
        submitCardHandler={submitCardHandler}
        cancelHandler={cancelHandler}
      />
    )}
    {status === ADD_NEW_CARD_PROCESSING && <ProcessingState />}
  </Fragment>
);

AddNewCard.propTypes = {
  status: propTypes.oneOf([null, ADD_NEW_CARD_FORM, ADD_NEW_CARD_PROCESSING]),
  addNewClickHandler: propTypes.func.isRequired,
  submitCardHandler: propTypes.func.isRequired,
  cancelHandler: propTypes.func.isRequired,
};

export default AddNewCard;
