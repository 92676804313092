import React from "react";
import {bool, func, string, number} from 'prop-types';

const StopWordInputScreen = ({
  showModal,
  addToList,
  onWordChange,
  closeModal,
  isBlackList,
  newWord,
  pageId
}) => (
  <div className={showModal === true ? "modalScreen db" : "dn"}>
    <div className="tc flex flex-column justify-center items-center h-100 mw7-ns mw5 center">
      <div className="close" onClick={() => closeModal()}>✕</div>
      <div className="white f4 pa3 pb4">
        Add a new word to the {isBlackList === true ? "black" : "white"} list:
      </div>
      <div>
        <input
          type="text"
          placeholder="Type Your Word"
          value={newWord}
          className="modalInput"
          onKeyUp={(e) => addToList(e, pageId)}
          onChange={(e) => onWordChange(e)}
        />
      </div>
      <div className="instructions mw7-ns mw5">Tap ENTER to confirm or ESCAPE to cancel.</div>
    </div>
    <style jsx="true">{`
      .close {
        position: absolute;
        top: 10%;
        right: 20%;
        color: white;
        cursor: pointer;
      }
      @media (min-width: 30em) {
        .modalInput {
          border: none;
          background-color: transparent;
          color: white;
          font-size: 45px;
          text-align: center;
          width: 100%;
        }
      }
      @media (max-width: 30em) {
        .modalInput {
          border: none;
          background-color: transparent;
          color: white;
          font-size: 30px;
          text-align: center;
          width: 100%;
        }
      }
      .modalInput:focus {
        outline: none;
      }
      .modalInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #766E8B;
        opacity: 1; /* Firefox */
      }
      .modalInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #766E8B;
      }
      .modalInput::-ms-input-placeholder { /* Microsoft Edge */
        color: #766E8B;
      }
      .instructions {
        color: #766E8B;
        position: absolute;
        bottom: 20px;
      }
      .modalScreen {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #3C315B;
        z-index: 1;
        opacity: 0.9;
      }
    `}</style>
  </div>
)

StopWordInputScreen.propTypes = {
  showModal: bool.isRequired,
  addToList: func.isRequired,
  onWordChange: func.isRequired,
  closeModal: func.isRequired,
  isBlackList: bool.isRequired,
  newWord: string,
  pageId: number.isRequired
};

export default StopWordInputScreen;
