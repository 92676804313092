import React from 'react';

const strDateToFormat = (str) => {
  const date = new Date(str);
  const yyyy = date.getFullYear();
  const mm = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return ''.concat(`${mm}-`).concat(`${dd}-`).concat(`${yyyy} `).concat(`${hh}:`)
    .concat(min);
};

const ReplayListItem = ({ replayItem }) => (
  <div className="flex flex-row justify-start-ns replay-bg-violet pa2 flex br-0-7-rem">
    <div className="flex flex-column">
      <div className="w-100">
        <span className="replay">
          "
          {replayItem.message}
          "
        </span>
      </div>
      <div className="w-100">
        {replayItem.from && <span className="replay-author-prefix">Commented on by </span>}
        <span className="replay-author">
          {replayItem.from && replayItem.from.name}
        </span>
        {replayItem.from && <span className="replay-author-prefix"> at: </span>}
        <span className="replay-time">
          {replayItem.created_time && strDateToFormat(replayItem.created_time)}
        </span>
      </div>
    </div>
    <style jsx="true">
      {`
         .replay-author-prefix {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-size: 10px;
         } 
         .replay-author {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: bold;
            font-size: 10px;
         } 
         .replay-time {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-size: 10px;
         }   
         .replay-bg-violet {
          background-color:#F6EFFE;
        }
          @media (min-width: 30em) {
            .h5rem-ns {
              height:5rem;
            }
          }
          .replay {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.75rem;
            word-break: break-word;
          }
          .word {
            font-family: 'Open Sans', sans-serif;
            color: #9A8AA8;
            font-weight: 600;
            font-size: 0.65rem;
          }
          .highlighted-word {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.65rem;
          }
          .bb-lightgrey {
            border-bottom: 1px solid rgb(241, 241, 241);
          }
          .word-menu {
            outline:none;
            font-size: 1.3rem;
            font-family: 'Open Sans', sans-serif;
            color: white;
          }
        `}
    </style>
  </div>
);

export default ReplayListItem;
