import React from 'react';
import propTypes from 'prop-types';

const PlanPricesListItem = ({listItem}) => {
    return (
        <div className="flex flex-column tc">
            <div className="price-block">
                <div className="inner-box">
                    <h2>{listItem.name}</h2>
                    <div className="price">{listItem.price} <span>per month</span></div>
                    <ul>
                        <li>Up to {listItem.amount} analyzed comments/month</li>
                    </ul>
                </div>
            </div>
            <style jsx="true">
                {`
                 .inner-box {
                    position:relative;
                    padding:1px 0px;
                    text-align:center;
                    border-radius:10px;
                    background-color:#ffffff;
                    -webkit-transition:all 300ms ease;
                    -ms-transition:all 300ms ease;
                    -o-transition:all 300ms ease;
                    -moz-transition:all 300ms ease;
                    transition:all 300ms ease;
                    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
                  }
                  .price-block {
                    padding: 0px 7px;
                  }
                .price-block .inner-box .price span{
                    position:relative;
                    color:#4e5c66;
                    font-size:12px;
                    font-weight:400;
                }

                .price-block .inner-box ul{
                    position:relative;
                    margin-bottom:40px;
                }

                .price-block .inner-box ul li{
                    position:relative;
                    color:#4e5c66;
                    font-size:14px;
                    font-weight:400;
                    margin-bottom:18px;
                }

                .price-block .inner-box ul li.line{
                    text-decoration:line-through;
                }

                .price-block .inner-box ul li:last-child{
                    margin-bottom:0px;
                }
                    .price-block .inner-box .title{
                        position:relative;
                        color:#94a1ac;
                        font-size:14px;
                        margin-bottom:36px;
                    }

                    .price-block .inner-box .price{
                        position:relative;
                        color:#293039;
                        font-size:21px;
                        font-weight:500;
                        padding-bottom:25px;
                        margin-bottom:30px;
                        border-bottom:1px solid #edecf1;
                    }
                   .price-block .inner-box h2{
                      position:relative;
                      color:#293039;
                      font-size:14px;
                      font-weight:600;
                      margin-bottom:2px;
                   }
                `}
            </style>
        </div>
    );
}

PlanPricesListItem.propTypes = {
    listItem: propTypes.shape({
        name: propTypes.string.isRequired,
        price: propTypes.string.isRequired,
        amount: propTypes.string.isRequired
    }).isRequired
}

export default PlanPricesListItem;