import React from 'react';
import {shape, string} from 'prop-types';

function AuthTopBar({location}) {
  const { pathname } = location;
  return (
    <div className="center w-100 mw8 pa3 ph4-m pt4 flex flex-row-ns flex-column justify-between items-center">
      <div className="flex h3rem mb0-ns mb3"><img width="135px" src="/sg-logo-text-black.svg"/></div>
      {pathname === "/signup" && <div className="flex flex-row items-center justify-start-l justify-end flex-wrap">
        <div className="f7 gray h3rem lh3rem">Already have an account?</div>
        <a href="/login"><button className="ttu pointer authButton ml4-ns ml3 h3rem">Log in</button></a>
      </div>}
      {pathname === "/login" && <div className="flex flex-row items-center justify-start-l justify-end flex-wrap">
        <div className="f7 gray h3rem lh3rem">{`Don't have an account?`}</div>
        <a href="/signup"><button className="ttu pointer authButton ml4-ns ml3 h3rem">Sign up</button></a>
      </div>}
      <style jsx="true">
        {`
          .h3rem {
            height: 3rem;
          }
          .lh3rem {
            line-height: 3rem;
          }
          .authButton {
            color: #7049E2;
            border: 1px solid #EAD7FB;
            border-radius: 5rem;
            background-color: transparent;
            line-height: 2.9rem;
            outline: none;
            font-family: 'Montserrat', sans-serif;
            font-size: 0.6rem;
            font-weight:600;
            width: 7rem;
          }
        `}
      </style>
    </div>
  )
}

AuthTopBar.propTypes = {
  location: shape({
    pathname: string.isRequired
  }).isRequired
};

export default AuthTopBar;
