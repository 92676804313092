import React from 'react';
import {Landing} from 'pages';

class LandingApp extends React.Component {
  render () {
    return (<Landing/>);
  }
}

export default LandingApp;
