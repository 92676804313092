import React from 'react';

export default () => (
  <div className="relative real_profit_from">
    <div className="circles_left_profit"></div>
    <div className="slider_radius_up"></div>

    <div className="mw1320px center z10000 relative ph5-l ph4-m ph3">
      <div className="four_blocks">

        <ul className="blocks_ul flex flex-row flex-wrap items-center justify-around">
          <li className="left1">
           <ul className="item center">
             <li className="title">
               6x
             </li>
             <li className="text">
               FASTER RESPONSE TIME
             </li>
             <li className="description pv4">
               Social media workers cost you
               money, but will they react as
               fast as the algorithm? The answer is: no.
             </li>
           </ul>
          </li>

          <li className="right1">
            <ul className="item center">
              <li className="title">
                5x
              </li>
              <li className="text">
                CHEAPER THAN AN AGENCY
              </li>
              <li className="description pv4">
                With our pricing model, you only
                pay for the comments you block,
                not for the full-time employee.
              </li>
            </ul>
          </li>

          <li className="left3">
            <ul className="item center">
              <li className="title">
                2x
              </li>
              <li className="text">
                GAIN OF LIKES
              </li>
              <li className="description pv4">
                Avoiding the flame wars helps in
                maintaining more approachable
                and better performing page.
              </li>
            </ul>
          </li>

          <li className="right4">
            <ul className="item center">
              <li className="title">
                ∞
              </li>
              <li className="text">
                PEACE OF MIND
              </li>
              <li className="description pv4">
                Save yourself the sleepless nights
                and tons of deleted comments
                because of the unexpected drama.
              </li>
            </ul>
          </li>
        </ul>

      </div>
      <div className="silencing center tl-l tc">
         <ul>
           <li className="title">
             Real profit from
             <div>silencing the bad guys</div>
           </li>
           <li className="text">
             Fighting the negativity brings real value to the
             performance of your Facebook page.
           </li>
           <li className="button">
             <a href="#" className="btn_transparent">LEARN MORE</a>
           </li>
         </ul>
      </div>
    </div>
    <style jsx="true">{`
      .mw1320px {
        max-width: 1320px;
      }
      .z10000 {
        z-index: 10000;
      }
      .real_profit_from {
        position: relative;
        background: #FFFFFF;
        margin-top: 100px;
        padding-bottom: 276px;
        padding-top: 40px;
      }

      .center_block {
        max-width: 1320px;
        position: relative;
        z-index: 10000;
        margin: 0 auto;
        padding: 16px;
      }

      .item {
        max-width: 345px;
        background-color: #ffffff;
        text-align: center;
        -webkit-box-shadow: 0px 18px 38px 10px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0px 18px 38px 10px rgba(0, 0, 0, 0.07);
        box-shadow: 0px 18px 38px 10px rgba(0, 0, 0, 0.07);
        padding: 32px 50px;
        margin-bottom: 60px;
      }

      .item .title {
        background: -webkit-linear-gradient(#7049E2, #B235f4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 42px;
        font-weight: 300;
      }

      .item .text {
        margin: 16px 0 0;
        color: #9892a9;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .item .description {
        color: #6d5f79;
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
      }
      .silencing {
        max-width: 400px;
      }
      @media (min-width:1199px) {
        .four_blocks {
          margin-top: 249px;
          float:left;
          width:60%;
        }


        .silencing {
          margin-top: 505px;
          float: right;
          max-width: 400px;
          width:40%;
        }
        .four_blocks .left1,
        .four_blocks .left3 {
          float: left;
          width: 45%;
        }

        .four_blocks .right1,
        .four_blocks .right4 {
          float: right;
          width: 45%;
        }
        .four_blocks .right4 {
          margin-top: 60px;
        }

        .right1 {
          margin-top: 60px;
        }
      }

      .title {
        color: #9892a9;
        font-size: 32px;
        font-family: 'Roboto',sans-serif;
        font-weight: 300;
      }

      .title div {
        margin-top: 10px;
        font-family: 'Roboto',sans-serif;
        color: #382C58;
      }

      .text {
        font-family: 'Roboto',sans-serif;
        color: #6d5f79;
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
        margin-top: 48px;
      }

      .button {
        margin-top: 60px;
      }
      @media (max-width:1199px) and (min-width: 770px) {
         .silencing{
          margin: 0 auto;
          float: none;
          clear: both;
          padding-top: 48px;
          width:100%;
        }

         .four_blocks {
          width:100%;
          padding-left:0;
        }

       .real_profit_from .four_blocks .left1,
        .four_blocks .left3,
        .four_blocks .right1,
        .four_blocks .right4 {
          width: 45%;
        }
      }
      @media (max-width:770px) {
        .real_profit_from .four_blocks .right1 {
          margin-top: 0;
        }

        .four_blocks .left1,
        .four_blocks .left3,
        .four_blocks .right1,
        .four_blocks .right4 {
          width: initial;
        }
      }
    `}</style>
  </div>
);
