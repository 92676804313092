import { Container } from 'unstated';
import _ from 'lodash';

import {
  IG_ACCOUNT_PERMISSIONS_APPROVED,
  IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
  IG_ACCOUNT_PERMISSIONS_SUBMIT,
} from 'appEvents';

const request = require('superagent');

class InstagramContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      account: null,
      permState: null,
      dataLoading: false,
    };

    this.getAccount = this.getAccount.bind(this);
    this.resetPermState = this.resetPermState.bind(this);
    this.permExists = this.permExists.bind(this);
    this.dataLoadStart = this.dataLoadStart.bind(this);
    this.dataLoadEnd = this.dataLoadEnd.bind(this);
    this.setPermStateLoading = this.setPermStateLoading.bind(this);
    this.checkPermissions = this.checkPermissions.bind(this);
  }

  checkPermissions(account) {
    const requiredScopes = __igAppScope.split(',') || ['instagram_basic', 'instagram_manage_comments'];

    if (_.has(account, 'scopes') && _.intersectionWith(requiredScopes, _.flatten(account.scopes), _.isEqual).length === requiredScopes.length) {
      this.setState({ permState: IG_ACCOUNT_PERMISSIONS_APPROVED });
    } else {
      this.setState({ permState: IG_ACCOUNT_PERMISSIONS_NOT_APPROVED });
    }
  }

  setPermStateLoading() {
    this.setState({ permState: IG_ACCOUNT_PERMISSIONS_SUBMIT });
  }

  dataLoadStart() {
    this.setState({ dataLoading: true });
  }

  dataLoadEnd() {
    this.setState({ dataLoading: false });
  }

  permExists(scopes) {
    const requiredScopes = __igAppScope.split(',') || ['instagram_basic', 'instagram_manage_comments'];
    return _.intersection(requiredScopes, scopes).length === requiredScopes.length;
  }

  resetPermState() {
    this.setState({ permState: null });
  }

  getAccount(pageId) {
    request
      .get(`/api/v1/pages/${pageId}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({
            account: null,
            error: response,
            permState: IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
          });
          console.log('Error: ', response);
        } else {
          const { scopes, igAccounts } = response.body;
          const permState = this.permExists(scopes, requiredScopes)
            ? IG_ACCOUNT_PERMISSIONS_APPROVED : IG_ACCOUNT_PERMISSIONS_NOT_APPROVED;
          const account = _.get(igAccounts, ['0']);
          this.setState({ account, permState });
        }
      });
  }
}

export default InstagramContainer;
