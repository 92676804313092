import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default ({ fbAccId, instagram }) => {
  const ColorButton = withStyles(theme => ({
    root: {
      fontSize: '70%',
      fontFamily: 'sans-serif',
      lineHeight: 'inherit',
      textTransform: 'none',
      color: '#FFF',
      backgroundColor: '#E33E5C',
      '&:hover': {
        backgroundColor: '#E33E5C',
      },
      margin: 0,
      paddingTop: '1px',
      paddingRight: '6px',
      paddingBottom: '1px',
      paddingLeft: '6px',
    },
  }))(Button);

  return (
    <div className="flex flex-row justify-start items-center pl2">
      <div className="flex flex-column justify-start items-left">
        <span className="boldText ma0 pl2 pb1 black">Approved</span>
        <div>
          <ColorButton
            className="btnIG"
            onClick={() => {
              instagram.revokeCurrentScopes(fbAccId);
            }}
          >
                        Revoke
          </ColorButton>
        </div>
      </div>
      <style jsx="true">
        {`
                .boldText {
                  font-family: 'Open Sans', sans-serif;
                  color: #0c981e;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
            `}
      </style>
    </div>
  );
};
