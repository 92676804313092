import React from 'react';
import _ from 'lodash';
import ComponentDidMount from 'containers/ComponentDidMount';
import DataTable from 'react-data-table-component';
import Loader from 'components/Loader';
import AccountIcon from 'components/AccountIcon';
import SetCurrentButton from 'components/FbAccountListDataTable/SetCurrentButton';
import RemoveButton from 'components/FbAccountListDataTable/RemoveButton';

const columns = [
  {
    name: 'Account',
    selector: row => row.name,
  },
  {
    name: 'Email',
    selector: row => row.email,
  },
  {
    name: 'Is Valid',
    selector: row => row.is_valid,
    center: true,
    compact: true,
    allowOverflow: true,
  },
  {
    name: '',
    center: true,
    compact: true,
    button: true,
    allowOverflow: true,
    selector: row => row.current,
  },
  {
    name: '',
    center: true,
    compact: true,
    button: true,
    allowOverflow: true,
    selector: row => row.removeBtn,
  },
];

const FbAccountListDataTable = ({ facebook, reloadData }) => {
  const { accounts, dataLoading } = facebook.state;

  const data = !_.isEmpty(accounts) ? accounts.map(account => ({
    id: account.id,
    name: <AccountIcon name={account.name} url={account.image} />,
    email: account.email,
    is_valid: account.is_valid ? 'Yes' : 'No',
    current: <SetCurrentButton
      facebook={facebook}
      current={account.current}
      id={account.id}
      reloadData={reloadData}
    />,
    removeBtn: account.current === true ? '' : (
      <RemoveButton
        facebook={facebook}
        id={account.id}
        reloadData={reloadData}
      />
    ),
  })) : [];

  return (
    <ComponentDidMount exec={() => {
      facebook.getAccountList();
    }}
    >
      <div className="flex flex-column w-100">
        <div className="flex flex-row justify-start items-center w-100">
          {dataLoading && <Loader center />}
          {!dataLoading && (
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="200px"
              columns={columns}
              data={data}
            />
          )}
        </div>
      </div>
      <style jsx="true">
        {`
        button.cancel {
          border-radius: 5rem;
          height: 2rem;
          background-color: white;
          color: rgb(154, 138, 168);
          border-color: #EAD7FB;
          border-style: solid;
          border-width: 1px;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.5rem;
          font-weight:700;
        }
      `}
      </style>
    </ComponentDidMount>
  );
};

export default FbAccountListDataTable;
