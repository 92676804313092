import React from 'react';

const AccountIcon = ({ name, url }) => (
  <div className="flex flex-row justify-start items-center w-100">
    <div
      style={{
        width: '2.3rem',
        height: '2.3rem',
        borderRadius: '4rem',
        background: `url(${url})`,
        backgroundPosition: 'center',
        backgroundSize: '100%',
      }}
      className="shadow-for-page mr3"
    />
    <span className="highlighted-word pr3">{name}</span>
    <style jsx="true">
      {`
          .shadow-for-page {
            -webkit-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            -moz-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
          }
          .highlighted-word {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.75rem;
          }
        `}
    </style>
  </div>
);

export default AccountIcon;
