import React from 'react';
import FacebookLogin from 'react-facebook-login';
import BlockContainer from 'components/BlockContainer';
import AuthTopBar from 'components/AuthTopBar';
import ComponentDidMount from 'containers/ComponentDidMount';

export default ({ location, fbCallBackHandler }) => (
  <ComponentDidMount exec={() => {
  }}
  >
    <div style={{ minHeight: '43rem' }} className="flex flex-column w-100 h-100 bg-lightpink">
      <AuthTopBar location={location} />
      <div className="flex flex-column justify-center align-center center mt6">
        <div className="login-block-wrapper mw6">
          <BlockContainer row>
            <div className="w-100 pa5 items-center justify-center">
              <h1 className="title ma2 mb3">Facebook Signup</h1>
              <div className="h2 mistake flex flex-row w-100 items-center justify-center" />
              <FacebookLogin
                appId={process.env.FB_APP_ID}
                autoLoad={false}
                fields="email,name"
                cookie={false}
                scope={__fbAppScope}
                textButton="Login with Facebook"
                disableMobileRedirect={true}
                onClick={() => {
                }}
                callback={(response) => {
                  fbCallBackHandler(response);
                }}
              />
              <div className="f7 mt4 moon-gray lh-copy">
                By clicking "Login with Facebook", I agree to the
                {' '}
                <b className="black">
                  Terms of
                  Service
                </b>
                {' '}
                and
                {' '}
                <b className="black">Privacy Policy</b>
                .
              </div>
            </div>
          </BlockContainer>
        </div>
      </div>
      <style global="true" jsx="true">
        {`
              .fb-token-expired {
                font-family: 'Open Sans', sans-serif;
                color: #F65763;
                font-weight: 500;
                font-size: 0.7rem;
              }
              .kep-login-facebook {
                font-size: 12px !important;
                width: 100% !important;
                background-color: #3C5A96 !important;
                border-radius: 2rem !important;
                -webkit-box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
                -moz-box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
                box-shadow: 0px 4px 14px -6px rgb(5, 28, 142);
              }
            `}
      </style>
      <style jsx="true">
        {`
              .mistake {
                font-family: 'Open Sans', sans-serif;
                color: #F65763;
                font-weight: 700;
                font-size: 0.8rem;
                padding-top:0.2rem;
              }
              .login-block-wrapper {
                width: 50rem;
              }
            `}
      </style>
    </div>
  </ComponentDidMount>
);
