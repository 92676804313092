import React from 'react';

export default () => (
    <div className="flex flex-row justify-start items-center">
        <div className="flex flex-column justify-start items-left">
            <span className="boldText ma0 pl2 pb1 black">Not Connected</span>
        </div>
        <style jsx="true">
            {`
                .boldText {
                  font-family: 'Open Sans', sans-serif;
                  color: #382C58;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
            `}
        </style>
    </div>
)