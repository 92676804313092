import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from '@stripe/react-stripe-js';

const request = require('superagent');

const createOptions = () => ({
  style: {
    base: {
      color: '#424770',
      fontFamily: 'Open Sans,sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: 'red',
    },
  },
});

class _CardFormSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empty: true,
      success: true,
      errors: [],
      planPrice: 0,
      planList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderEmptyForm = this.renderEmptyForm.bind(this);
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { errors } = this.state;
    if (this.state.errors.length === 0) {
      this.props.stripe.createToken().then((payload) => {
        if (payload.error) {
          errors.unshift(payload.error.message);
          this.setState({ errors });
        } else if (payload.token) {
          this.setState({ last4digits: payload.token.card.last4, success: true, empty: false });
        }
        return this.props.handlePayload(payload);
      });
    }
  }

  handleChange(error) {
    const { errors } = this.state;
    if (error.error && error.error.message) {
      errors.push(error.error.message);
      this.setState({ errors });
    } else {
      this.setState({ errors: [] });
    }
  }

  renderOneLineForm() {
    return (
      <CardElement
        onChange={this.handleChange.bind(this)}
        {...createOptions(this.props.fontSize)}
      />
    );
  }

  renderEmptyForm() {
    const { cancelText, cancelHandler, doneText } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          {this.state.errors.length === 0 ? '' : ''}
          {this.state.errors[0]}
        </div>
        {this.renderOneLineForm()}
        <div className="flex flex-row justify-between items-center w-100 mt3">
          {!cancelHandler && (
          <Link to="/onboarding/select-pages">
            <button className="ttu pointer outlineButton">{cancelText || 'previous step'}</button>
          </Link>
          )}
          {cancelHandler && (
          <button
            type="button"
            className="ttu pointer outlineButton"
            onClick={cancelHandler}
          >
            {cancelText || 'previous step'}
          </button>
          )}
          <button className="ttu pointer filledButton">
            {doneText || 'finish'}
          </button>
        </div>
      </form>
    );
  }

  renderSuccessConfirmation() {
    return (
      <Loader />
    );
  }

  render() {
    if (this.state.empty) {
      return this.renderEmptyForm();
    } if (this.state.success) {
      return this.renderSuccessConfirmation();
    }
    return this.renderEmptyForm();
  }
}

export default _CardFormSingle;
