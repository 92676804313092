import React from 'react';
import CommentsListItem from 'components/CommentsListItem';

const CommentsList = ({
  toggleComment,
  list,
  commentsContainer,
}) => (
  <div className="relative flex flex-column vh-100">
    <div className="overflow-y-auto fade-in">
      {list.map(listItem => (
        <div key={`coment-${listItem.id}`} className="pl2-ns pr3-ns comment-wrapper">
          <CommentsListItem
            listItem={listItem}
            toggleComment={(id) => { toggleComment(id); }}
            commentsContainer={commentsContainer}
          />
        </div>
      ))}
    </div>
    {list.length > 6 && <div className="h5rem db-l dn absolute b0 white-gradient-overlay" />}
    <style jsx="true">
      {`
          .mh-95 {
            max-height: 95%;
          }
          .comment-wrapper {
            border-radius: 9px;
          }
          .comment-wrapper:hover {
            background-color: #fbf9fd;
          }
          .h30rem {
            height: 30rem;
          }
          .h5rem {
            height: 5rem;
          }
          .b0 {
            bottom: 0;
          }
          .white-gradient-overlay {
            background: transparent;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 46%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0);
            width:70%; // to overlay scrollbar
          }

          @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }

          .fade-in {
            opacity:0.6;
            -webkit-animation:fadeIn ease-in 0.3s;
            -moz-animation:fadeIn ease-in 0.3s;
            animation:fadeIn ease-in 0.3s;
            -webkit-animation-fill-mode:forwards;
            -moz-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
            -webkit-animation-duration:0.3s;
            -moz-animation-duration:0.3s;
            animation-duration:0.3s;
          }
        `}
    </style>
  </div>
);

export default CommentsList;
