import React from 'react';
import Sidebar from 'react-sidebar';

import {shape, string, func, array, object, bool} from 'prop-types';
import MenuSidebar from 'components/MenuSidebar';

const MobileSidebar = ({
  children,
  location,
  goTo,
  subscribedPages,
  currentPage,
  openModal,
  closeSidebar,
  selectPage,
  logout,
  open,
  onSetOpen
}) => (
  <Sidebar
    sidebarClassName={open ? '' : 'toTheLeft'}
    styles={{
      sidebar: {
        width:'100%',
        boxShadow: 'none'
      },
      content: {
        position: 'relative'
      },
      root: {
        overflowY: `${open ? 'hidden' : 'scroll'}`,
        overflowX: 'hidden',
        boxShadow: 'none'
      }
    }}
    sidebar={
      <MenuSidebar
        selectPage={(id) => { selectPage(id) }}
        subscribedPages={subscribedPages}
        currentPage={currentPage}
        openModal={() => { openModal() }}
        closeSidebar={() => {closeSidebar()}}
        goTo={(v) => {goTo(v)}}
        location={location}
        logout={() => {logout()}}
      />
    }
    open={open}
    onSetOpen={(open) => onSetOpen(open)}
  >
    {children}
  </Sidebar>

)

MobileSidebar.propTypes = {
  location: shape({
    pathname: string.isRequired
  }).isRequired,
  goTo: func.isRequired,
  openModal: func.isRequired,
  selectPage: func.isRequired,
  logout: func.isRequired,
  subscribedPages: array,
  currentPage: object,
  closeSidebar: func,
  open: bool.isRequired,
  onSetOpen: func.isRequired 
}

MobileSidebar.defaultProps = {
  subscribedPages: [],
  currentPage: null,
  closeSidebar: () => {}
}

export default MobileSidebar;
