import React from 'react';
import {func} from 'prop-types';

const EmptyOverview = ({addPagesHandler}) => (
  <div className="w-100 h-100 flex flex-column items-center justify-center minH43rem">
    <div className='w-90 h-90 flex flex-column justify-center items-center bg-white shadow mw7 ph4-ns ph3 pv5-ns pv4'>
      <img className="mb2" style={{width: '45px', height: 'auto'}} src="/feather.png"/>
      <p className="title mv3">No Pages Yet</p>
      <p className="text mv3">Click "Add Pages" to start using Sour Grapes</p>
      <button className="filledButton mv4 ttu ph3 pointer" onClick={addPagesHandler}>Add Pages</button>
    </div>
    <style jsx="true">
      {`
        .minH43rem {
          min-height: 43rem;
        }
        .h-90 {
          height: 90%;
        }
        .shadow {
          -webkit-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          -moz-box-shadow: 0px 9px 51px -18px rgba(36,0,35,0.25);
          box-shadow:0px 9px 51px -15px rgba(36,0,35,0.35);
        }
        .title {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 600;
          font-size: 1.2rem;
        }
        .text {
          font-family: 'Open Sans',sans-serif;
          color: #9A8AA8;
          font-weight: 600;
          font-size: 0.8rem;
          line-height:1.9;
        }
        .filledButton {
          color: white;
          border-radius: 5rem;
          height: 3rem;
          line-height: 2.9rem;
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          border: 1px solid transparent;
          outline: none;
          -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.8rem;
          font-weight:600;
          width: 12rem;
        }
      `}
    </style>
  </div>
)

EmptyOverview.propTypes = {
  addPagesHandler: func.isRequired
}

export default EmptyOverview;
