import React from 'react';
import {arrayOf, shape, string, bool, func} from 'prop-types';

import MenuItem from 'components/MenuItem';

const Menu = ({menuItems, goTo, logout, closeSidebar}) => {
  return (
    <div className="h-100 w-100 flex flex-column-ns flex-row">
      {menuItems.map(item => (
        <MenuItem
          key={item.name}
          name={item.name}
          active={item.active}
          path={item.path}
          goTo={goTo}
          logout={logout}
          closeSidebar={closeSidebar}
        />
      ))}
    </div>
  );
}

Menu.propTypes = {
  menuItems: arrayOf(shape({
    name: string.isRequired,
    active: bool.isRequired,
    path: string.isRequired
  })).isRequired,
  goTo: func.isRequired,
  logout: func.isRequired,
  closeSidebar: func.isRequired,
};

export default Menu;
