import React from 'react';
import { func } from 'prop-types';

const InitialState = ({ addNewClickHandler }) => (
  <div className="pv4-ns pv3">
    <button onClick={addNewClickHandler} className="ttu pointer filledButton">Add New</button>
    <style jsx="true" global="true">
      {`
      .filledButton {
        color: white;
        border-radius: 5rem;
        height: 3rem;
        line-height: 2.9rem;
        background-color: #7049E2;
        background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
        background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
        background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
        background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
        background-image: linear-gradient(to right, #7049E2, #9A6FED);
        border: 1px solid transparent;
        outline: none;
        -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
        -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
        box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
        font-family: 'Montserrat', sans-serif;
        font-size: 0.6rem;
        font-weight:600;
        width: 10rem;
      }
    `}
    </style>
  </div>
);

InitialState.propTypes = {
  addNewClickHandler: func.isRequired,
};

export default InitialState;
