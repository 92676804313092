import React from 'react';
import ReplayListItem from 'components/ReplayListItem';
import _ from 'lodash';

const ReplayList = ({ comment }) => (
  <div className="relative flex flex-column">
    { !comment.replaysLoading && _.isEmpty(comment.replays) && <div className="replay-no-comments"> No Comments </div> }
    { comment.replays && comment.replays.map(replayItem => (
      <div key={`replay-${replayItem.id}`} className="pl2-ns pr3-ns replay-comment-wrapper pb1">
        { replayItem.message && <ReplayListItem replayItem={replayItem} />}
      </div>
    ))}
    <style jsx="true">
      {`
          .replay-no-comments {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.75rem;
          }
          .replay-comment-wrapper {
            border-radius: 9px;
          }
          .replay-comment-wrapper:hover {
            background-color: #fbf9fd;
          }
        `}
    </style>
  </div>
);

export default ReplayList;
