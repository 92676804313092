import React from 'react';

const ReplayForm = ({
  comment,
  commentsContainer,
}) => {
  const { comment_fbid } = comment;
  return (
    <div className="flex flex-row justify-start-ns justify-between pt2 items-center w-100">
      <div className="w-100">
        <div
          contentEditable="true"
          onKeyDown={(e) => { e.key === 'Enter' && commentsContainer.submitReplayHandler(e, comment_fbid); }}
          className="replay-input"
        />
        <style jsx="true">
          {`
           .replay-input {
            width: auto;
            word-break: break-word;
            padding: 5px;
            border-radius: 0.25rem;
            border: solid 1px #EAD7FB;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
            box-sizing: border-box;         /* Opera/IE 8+ */
            -webkit-user-modify: read-write;
            overflow-wrap: break-word;
            -webkit-line-break: after-white-space;
            outline: none;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.8rem;
          }
          .replay-input:hover {
            border: solid 1px #7049E2;
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default ReplayForm;
