import React from 'react';
import { number, bool, func } from 'prop-types';
import BlockContainer from 'components/BlockContainer';

const SentimentAnalysisBlock = ({
  sentiment,
  currentPageId,
  sentimentToggle,
}) => (
  <BlockContainer>
    <div className="flex flex-row w-100">
      <div className="flex flex-column bg-white w-70 pt4 pb-1-5-rem pl4-ns pl3 pr3 br-top-bottom-left-3">
        <p className="subTitle ma0">Automatic Sentiment Analysis</p>
        <p className="text ma0 mt3">Automatically analyze the sentiment (positive, negative, neutral) of text in comments and hide them</p>
      </div>
      <div className={`${sentiment === true ? 'bg-green' : 'bg-white'} flex flex-column items-center justify-center w-30 h100 br-top-bottom-right-3`}>
        <div style={{ height: '10px', width: '25px', position: 'relative' }} className="mv3 pointer" onClick={() => { sentimentToggle(currentPageId); }}>
          <span className={sentiment === true ? 'slider-on' : 'slider-off'} />
          <span className={sentiment === true ? 'slider-circle-on' : 'slider-circle-off'} />
        </div>
        <p className="text">
          Status:
          <span className={`${sentiment === true ? 'green' : 'projectBlack'} fw8 ttu`}>{sentiment === true ? 'on' : 'off'}</span>
        </p>
      </div>
    </div>
    <style jsx="true">
      {`
        .projectBlack {
          color: #382C58;
        }
        .slider-on {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #B8F4CF;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 34px;
        }
        .slider-off {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgb(241, 241, 241);
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 34px;
        }
        .slider-circle-on {
          position: absolute;
          content: "";
          height: 3px;
          width: 3px;
          bottom: -3px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 34px;
          left: 11px;
          border: 7px solid #57CD79;
          -webkit-box-shadow: 0px 3px 6px 1px rgba(188, 244, 208,1);
          -moz-box-shadow: 0px 3px 6px 1px rgba(188, 244, 208,1);
          box-shadow: 0px 3px 6px 1px rgba(188, 244, 208,1);
        }
        .slider-circle-off {
          position: absolute;
          content: "";
          height: 3px;
          width: 3px;
          bottom: -3px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 34px;
          left: -4px;
          border: 7px solid #382C58;
          -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 65, 178,0.2);
          -moz-box-shadow: 0px 3px 6px 1px rgba(56, 44, 88,0.2);
          box-shadow: 0px 3px 6px 1px rgba(56, 44, 88,0.2);
        }
        .pb-1-5-rem {
          padding-bottom: 1.5rem;
        }
        .bg-green {
          background-color: #EDFAF1;
        }
        .bg-violet {
          background-color: #F6EFFE;
        }
        .green {
          color: #57CD79;
        }
        .violet {
          color: #7049E2;
        }
        .br-top-bottom-left-3 {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        .br-top-bottom-right-3 {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      `}
    </style>
  </BlockContainer>
);

SentimentAnalysisBlock.propTypes = {
  currentPageId: number.isRequired,
  sentiment: bool.isRequired,
  sentimentToggle: func.isRequired,
};


export default SentimentAnalysisBlock;
