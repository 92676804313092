import React from 'react';
import BlockContainer from 'components/BlockContainer';

const ReferralLinkBlock = ({promoCode}) => {
  const host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

  return (
    <BlockContainer>
      <div className="pa4">
        <p className="subTitle ma0">Use a referral link</p>
        <div className="flex flex-row pv4 items-center justify-center">
          <input className="ph3 fg1" placeholder={`${host}/ref/${promoCode}`}/>
          <button style={{width: '7rem'}} className="ml3 ph3 ttu copy">Copy link</button>
        </div>
        <div className="flex flex-row w-100 items-center">
          <hr className="w-30 splitter"/>
          <span className="text fg1 ph2 tc">or share on social media</span>
          <hr className="w-30 splitter"/>
        </div>
        <div className="flex flex-row w-100 items-center justify-center pt3">
          <div className="social-icon fb flex flex-column items-center justify-center mh1">
            <img className="social-icon-img" src="fb.png"/>
          </div>
          <div className="social-icon tw flex flex-column items-center justify-center mh1">
            <img className="social-icon-img" src="twitter.png"/>
          </div>
          <div className="social-icon ln flex flex-column items-center justify-center mh1">
            <img className="social-icon-img" src="linkedin.png"/>
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
          .social-icon {
            border-radius: 5rem;
            height: 2rem;
            width: 2rem;
          }
          .fb {
            border: solid 1px #484B99;
          }
          .tw {
            border: solid 1px #3A91F6;
          }
          .ln {
            border: solid 1px #236CB6;
          }
          .social-icon-img {
            height: 0.5rem;
            width: 0.5rem;
          }
          .splitter {
            color: #9A8AA8;
          }
          .fg1 {
            flex-grow: 1;
          }
          button.copy {
            border-radius: 5rem;
            height: 2rem;
            background-color: white;
            color: #7049E2;
            border-color: #7049E2;
            border-style: solid;
            border-width: 1px;
            outline: none;
            font-family: 'Montserrat', sans-serif;
            font-size: 0.5rem;
            font-weight:600;
          }
          input {
            outline: none;
            padding-top:0;
            padding-bottom:0;
            border-radius: 0.25rem;
            border: solid 1px #9A8AA8;
            height: 2rem;
            line-height: 2rem;
            width: auto;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.65rem;
          }
        `}
      </style>
    </BlockContainer>
  )
}

export default ReferralLinkBlock;
