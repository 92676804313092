import React, { Fragment } from 'react';
import ComponentDidMount from 'containers/ComponentDidMount';

const SetCurrentButton = ({ facebook, current, id, reloadData }) => {
  return (
    <ComponentDidMount exec={() => {}}>
      <Fragment>
        {current && <p className="currentTxt ttu tc"> current </p>}
        {!current && (
          <button
            onClick={() => {
              facebook.accountSwitch(id, reloadData);
            }}
            className="ph3 pointer ttu cancel nowrap"
          >
            {' Set Current '}
          </button>
        )}
      </Fragment>
      <style jsx="true">
        {`
        .currentTxt {
          font-family: 'Montserrat', sans-serif;
          color: #9A8AA8;
          font-weight: 700;
          font-size: 0.6rem;
        }
      `}
      </style>
    </ComponentDidMount>
  );
};

export default SetCurrentButton;
