import { Container } from 'unstated';

import {
  ADD_NEW_CARD_FORM,
  ADD_NEW_CARD_PROCESSING,
} from 'appEvents';

const request = require('superagent');

class BillingContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      currentAccount: null,
      paymentMethods: null,
      requested: false,
      cancelErrorMessage: 'We will stop processing comments and unsubscribe from all pages. Are you sure?',
      showErrorModal: false,
      actions: {
        addNewCard: {
          status: null,
        },
        cancelSubscription: {
          status: null,
        },
      },
    };

    this.setCurrentPaymentMethod = this.setCurrentPaymentMethod.bind(this);
    this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
    this.addPaymentMethod = this.addPaymentMethod.bind(this);
    this.getAllPaymentMethods = this.getAllPaymentMethods.bind(this);
    this.showAddNewCardForm = this.showAddNewCardForm.bind(this);
    this.cancelAddNewCardForm = this.cancelAddNewCardForm.bind(this);
    this.submitNewCard = this.submitNewCard.bind(this);
    this.confirmSubscriptionCancel = this.confirmSubscriptionCancel.bind(this);
    this.cancelSubscriptionCancel = this.cancelSubscriptionCancel.bind(this);
    this.showSubscriptionCancelModal = this.showSubscriptionCancelModal.bind(this);
    this.getStripeAccounts = this.getStripeAccounts.bind(this);
    this.getUserPaymentHistory = this.getUserPaymentHistory.bind(this);
  }

  getStripeAccounts() {
    request
      .get('/api/v1/stripe_accounts')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (!error) {
          const accounts = response.body;
          this.setState({
            accounts,
            currentAccount: accounts.find(account => (account.current === true)),
          });
        } else {
          console.log('Error: ', response.body);
        }
      });
  }

  confirmSubscriptionCancel(cb) {
    this.setState({
      showErrorModal: true,
      cancelErrorMessage: 'Processing your request...',
    });
    request
      .post('/api/v1/stripe_accounts/cancel-subscription')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({
            showErrorModal: true,
            cancelErrorMessage: response.body.message,
          });
        } else {
          this.setState({ showErrorModal: false });
          this.getStripeAccounts();
          cb && cb();
        }
      });
  }

  cancelSubscriptionCancel() {
    this.setState({ showErrorModal: false });
  }

  showSubscriptionCancelModal() {
    this.setState({
      showErrorModal: true,
      cancelErrorMessage: 'We will stop processing comments and unsubscribe from all pages. Are you sure?',
    });
  }

  cancelAddNewCardForm() {
    const { actions } = this.state;
    this.setState({
      actions: { ...actions, addNewCard: { status: null } },
    });
  }

  showAddNewCardForm() {
    const { actions } = this.state;
    this.setState({
      actions: { ...actions, addNewCard: { status: ADD_NEW_CARD_FORM } },
    });
  }

  getUserPaymentHistory() {
    request
      .get('/api/v1/stripe_events?object_type=invoice&event_type[]=payment_succeeded&event_type[]=payment_failed')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, res) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({ history: res.body });
        }
      });
    return true;
  }

  submitNewCard(stripeResponse) {
    const { actions } = this.state;
    this.setState({
      actions: { ...actions, addNewCard: { status: ADD_NEW_CARD_PROCESSING } },
    });

    this.addPaymentMethod({
      stripeResponse,
      callback: ({ error }) => {
        this.getStripeAccounts();
        this.setState({
          actions: { ...actions, addNewCard: { status: null } },
        });
      },
    });
  }

  setCurrentPaymentMethod(id) {
    this.state.requested = true;
    request
      .patch(`/api/v1/payment_methods/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .send({ current: true })
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          console.log('response.body: ', response.body);
          this.setState({ paymentMethods: response.body });
        }
      });
    return true;
  }

  deletePaymentMethod(id) {
    this.state.requested = true;
    request
      .delete(`/api/v1/payment_methods/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({ paymentMethods: response.body });
        }
      });
    return true;
  }

  getAllPaymentMethods() {
    this.state.requested = true;
    request
      .get('/api/v1/payment_methods')
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({ paymentMethods: response.body });
        }
      });
    return true;
  }

  addPaymentMethod(props) {
    const {
      stripeResponse, callback,
    } = props;
    if (stripeResponse && stripeResponse.token) {

      const tokenData = () => {
        const { id, ...rest } = stripeResponse.token;
        return { token: id, ...rest };
      };

      const cardData = () => {
        const { id, ...rest } = stripeResponse.token.card;
        return { card_id: id, ...rest };
      };

      const payload = { ...tokenData(), ...cardData() };

      request
        .post('/api/v1/payment_methods')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({
          payment_method: payload,
        })
        .end((error, response) => {
          if (error) {
            this.setState({ error });
            callback && callback({ error });
          } else {
            this.setState({ paymentMethods: response.body });
            callback && callback({ user: response.body.user });
          }
        });
    }
    return true;
  }
}

export default BillingContainer;
