import React from 'react';
import { Subscribe } from 'unstated';

import BillingContainer from 'containers/BillingContainer';
import StopWordContainer from 'containers/StopWordContainer';
import CommentsContainer from 'containers/CommentsContainer';
import FanPagesContainer from 'containers/FanPagesContainer';
import RouterContainer from 'containers/RouterContainer';
import InvitationsContainer from 'containers/InvitationsContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';
import CouponCodeContainer from 'containers/CouponCodeContainer';
import UserContainer from 'containers/UserContainer';
import RegistrationContainer from 'containers/RegistrationContainer';
import InstagramContainer from 'containers/InstagramContainer';
import FacebookContainer from 'containers/FacebookContainer';

const containersCollection = [
  BillingContainer,
  StopWordContainer,
  CommentsContainer,
  FanPagesContainer,
  RouterContainer,
  InvitationsContainer,
  ForgotPasswordContainer,
  CouponCodeContainer,
  UserContainer,
  RegistrationContainer,
  InstagramContainer,
  FacebookContainer,
];

const Observer = ({ children }) => (
  <Subscribe to={containersCollection}>
    {(billing,
      stopWord,
      comments,
      fanPages,
      router,
      invitations,
      forgotPassword,
      couponCode,
      userContainer,
      registration,
      instagram,
      facebook) => (
      <div>
        {React.cloneElement(children, {
          billing,
          stopWord,
          comments,
          fanPages,
          router,
          invitations,
          forgotPassword,
          couponCode,
          userContainer,
          registration,
          instagram,
          facebook,
          selectPage: (id) => {
            if (id !== (fanPages.state.currentPage || {}).id) {
              stopWord.state.blackList = null;
              stopWord.state.whiteList = null;
              comments.state.comments = null;
              fanPages.setCurrent(id, () => {
                comments.getAll(id, 'week', 'all');
                stopWord.getBlackList(id);
                stopWord.getWhiteList(id);
              });
              userContainer.checkBillingStatus();
            }
            router.goTo('/dashboard');
          },
          getGlobalSidebarData: (cb) => {
            facebook.getAccountList((error) => {
              if(!error && facebook.state.currentAccount) {
                fanPages.getAll((error) => {
                  if (!error && fanPages.state.currentPage) {
                    stopWord.getBlackList(fanPages.state.currentPage.id);
                    stopWord.getWhiteList(fanPages.state.currentPage.id);
                    comments.getAll(fanPages.state.currentPage.id, 'week', 'all');
                    instagram.checkPermissions(facebook.state.currentAccount);
                    instagram.dataLoadEnd();
                    facebook.dataLoadEnd();
                    cb && cb();
                  } else if (error) {
                    cb && cb(error);
                    console.log("Current Page Not Set: ", error);
                    console.error(error);
                  } else {
                    cb && cb();
                  }

                });
              } else {
                console.error(error);
                cb && cb();
              }
            });
          },
          selectedFanPage: !!(fanPages.state.fanPages || []).find((fp) => (fp.selected === true || fp.subscribed === true)),
          dashboardDataIsReady: fanPages.state.currentPage
          && stopWord.state.blackList
          && stopWord.state.whiteList
          && comments.state.comments,
          noPages: fanPages.state.subscribedPages !== null && fanPages.state.subscribedPages.length === 0,
          canRenderSidebar: (fanPages.state.currentPage && fanPages.state.subscribedPages) ||
          fanPages.state.subscribedPages !== null && fanPages.state.subscribedPages.length === 0,
          userDataReady: userContainer.state.user,
        })}
      </div>
    )}
  </Subscribe>
);

export default Observer;
