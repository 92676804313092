import React from 'react';
import AuthTopLanding from 'components/AuthTopLanding';
import SliderDealingComments from 'components/SliderDealingComments';
import RealProfitFrom from 'components/RealProfitFrom';
import ManageMultiple from 'components/ManageMultiple';
import ReachYourGoals from 'components/ReachYourGoals';
import FromTheCommunity from 'components/FromTheCommunity';
import ToProtectFooter from 'components/ToProtectFooter';

function Landing({location}) {
  return (
    <div className="flex flex-column w-100 h-100 bg-lightpink">
      <AuthTopLanding location={location} />
      <SliderDealingComments/>
      <RealProfitFrom/>
      <ManageMultiple/>
      <ReachYourGoals/>
      <FromTheCommunity/>
      <ToProtectFooter/>
    </div>
  );
}
export default Landing
