import React from 'react';
import BlockContainer from 'components/BlockContainer';


const FreeTrialTier = ({
  trialDaysLeft,
  trialCommentsLeft,
}) => (
  <BlockContainer>
    <div className="pa4-ns pa3 relative h-100">
      <p className="subTitle ma0">Free Trial Tier</p>
      <div className="flex flex-row-ns flex-column pt2-ns pt2 items-center justify-between flex-wrap-m">
        <div className="flex flex-column justify-between items-start">
          <div className="flex flex-row w-100 items-center justify-start flex-wrap-m">
            <div className="flex flex-column justify-between items-start">
              <span className="wordsUsedInfo pb1-m pb1">
                Days left:
                {' '}
                { trialDaysLeft }
              </span>
              <span className="wordsUsedInfo pb1-m">
                Comments Amount Left:
                {' '}
                { trialCommentsLeft }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style jsx="true">
      {`
        .projectBlack {
          color: #382C58;
        }
        .wordsUsedInfo {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.65rem;
          font-weight: 500;
        }
        .paymentDate {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }
        .emptyBlockTitle {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.8rem;
          font-weight: 600;
        }
        .emptyBlockText {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }

        .boldText {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 700;
          font-size: 0.6rem;
        }
        .social-icon {
          border-radius: 5rem;
          height: 2rem;
          width: 2rem;
        }
        .fb {
          border: solid 1px #484B99;
        }
        .tw {
          border: solid 1px #3A91F6;
        }
        .ln {
          border: solid 1px #236CB6;
        }
        .fg1 {
          flex-grow: 1;
        }
      `}
    </style>
  </BlockContainer>
);

export default FreeTrialTier;
