import React from 'react';
import Loader from 'components/Loader';

const checkMarkIcon = (
  <svg style={{ width: '1rem', height: '1rem' }} className="caret-icon" x="0px" y="0px" width="1rem" height="1rem" viewBox="-5 -10 100 125">
    <g>
      <path
        style={{
          baselineShift: 'baseline',
          opacity: 1,
          color: '#ffffff',
          fill: '#ffffff',
          fillOpacity: 1,
          stroke: '#ffffff',
          strokeWidth: 6,
          marker: 'none',
          visibility: 'visible',
          display: 'inline',
          overflow: 'visible',
          enableBackground: 'new 0 0 100 100',
        }}
        d="M67.286,27.193c-7.854,9.545-14.788,19.67-20.696,30.445c-2.841-5.964-6.166-11.72-11.306-15.473  c-6.476-4.728-12.659,6.049-6.265,10.717c2.862,2.09,4.306,6.72,5.823,9.761l6.151,12.328c1.978,3.965,8.795,4.144,10.717,0  c6.485-13.98,14.553-27.096,24.351-39.002C81.105,29.842,72.376,21.008,67.286,27.193z"
      />
    </g>
  </svg>
);

const Checkmark = ({ isLoading, checked }) => (
  <div>
    {!isLoading && (
    <div
      className={`${checked ? 'filled' : 'empty bg-white'} flex flex-column items-center justify-center br-pill pointer`}
      style={{ height: '1.3rem', width: '1.3rem' }}
    >
      {checked && checkMarkIcon}
    </div>
    )}
    {isLoading && (<div className='flex flex-column itemds-center justify-center br-pill pointer' style={{ height: '1.3rem', width: '1.3rem' }}>
      <Loader />
    </div>)}
    <style jsx="true">
      {`
        .empty {
          border: 1.5px solid #EAD7FB;
        }
        .filled {
          background-color: #7049E2;
          border: 1.5px solid #7049E2;
        }
      `}
    </style>
  </div>
);

export default Checkmark;
