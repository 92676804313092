import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import FormState from 'components/ForgotPasswordForm/FormState';
import ProcessingState from 'components/ForgotPasswordForm/ProcessingState';
import SuccessState from 'components/ForgotPasswordForm/SuccessState';


import {
  FORGOT_PASSWORD_FORM,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from 'appEvents';

const ForgotPasswordForm = ({
  status,
  submitEmail,
  closeModal,
  onEmailChange,
  validateFormInput,
  email,
  error,
}) => (
  <Fragment>
    {(status === FORGOT_PASSWORD_FORM || status === FORGOT_PASSWORD_ERROR) && (
      <FormState
        submitEmail={submitEmail}
        closeModal={closeModal}
        onEmailChange={onEmailChange}
        validateFormInput={validateFormInput}
        email={email}
        error={error}
      />
    )}
    {status === FORGOT_PASSWORD_PROCESSING && <ProcessingState />}
    {status === FORGOT_PASSWORD_SUCCESS && <SuccessState closeModal={closeModal} />}
  </Fragment>
);

ForgotPasswordForm.propTypes = {
  status: propTypes.oneOf([
    null,
    FORGOT_PASSWORD_FORM,
    FORGOT_PASSWORD_PROCESSING,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS]).isRequired,
  submitEmail: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  onEmailChange: propTypes.func.isRequired,
  validateFormInput: propTypes.func.isRequired,
  email: propTypes.string.isRequired,
  error: propTypes.string.isRequired,
};

export default ForgotPasswordForm;
