import React, { Fragment } from 'react';
import ComponentDidMount from 'containers/ComponentDidMount';
import Approved from 'components/InstagramAccountBlock/PermissionsStatus/Approved';
import NotApproved from 'components/InstagramAccountBlock/PermissionsStatus/NotApproved';
import Loading from 'components/InstagramAccountBlock/Loading';

import {
  IG_ACCOUNT_PERMISSIONS_APPROVED,
  IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
} from 'appEvents';

const PermissionsStatus = ({ instagram, userContainer }) => {
  const { permState } = instagram.state;
  const { currentFbAccount } = userContainer.state;
  return (
    <ComponentDidMount exec={() => {
    }}
    >
      <Fragment>
        {/*{permState === null && <Loading />}*/}
        {/*{permState === IG_ACCOUNT_PERMISSIONS_APPROVED && (*/}
        {/*<Approved*/}
        {/*  fbAccId={currentFbAccount.id}*/}
        {/*  instagram={instagram}*/}
        {/*/>*/}
        {/*)}*/}
        <NotApproved
          instagram={instagram}
          userContainer={userContainer}
        />
      </Fragment>
    </ComponentDidMount>
  );
};

export default PermissionsStatus;
