import React from 'react';
import BlockContainer from 'components/BlockContainer';
import ReactSpeedometer from 'react-d3-speedometer';

const convertCoef = (score) => {
  if (score === 0) {
    return 500;
  }
  return score < 0 ? 1000 - Math.abs(score) / 0.001 : score / 0.001;
};

const calcScore = (comments = []) => {
  const total = comments.reduce((acc, comment) => acc + convertCoef(comment.sentiment_score), 0);
  return total > 0 ? parseInt(total / comments.length) : 500;
};

const PageMeter = ({ comments }) => {
  const score = calcScore(comments);
  return (
    <BlockContainer>
      <div className="ph4-ns ph3 pt4-ns pt3 w-100 min-h-200px flex flex-column">
        <div className="flex flex-row flex-nowrap-ns flex-wrap w-100 justify-between items-center pb2">
          <p className="subTitle ma0">Meter</p>
        </div>
        <div className="flex flex-row items-start justify-center">
          <ReactSpeedometer
            width={400}
            needleHeightRatio={0.8}
            value={score}
            currentValueText="Happiness Level"
            customSegmentLabels={[
              {
                text: 'Very Bad',
                position: 'INSIDE',
                color: '#555',
              },
              {
                text: 'Bad',
                position: 'INSIDE',
                color: '#555',
              },
              {
                text: 'Ok',
                position: 'INSIDE',
                color: '#555',
                fontSize: '19px',
              },
              {
                text: 'Good',
                position: 'INSIDE',
                color: '#555',
              },
              {
                text: 'Very Good',
                position: 'INSIDE',
                color: '#555',
              },
            ]}
            ringWidth={47}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
            needleColor="#90f2ff"
            textColor="#d8dee9"
          />
        </div>
      </div>
      <style jsx="true">
        {`
      .min-h-200px {
        min-height: 200px;
      }
    `}
      </style>
    </BlockContainer>
  );
};

export default PageMeter;
