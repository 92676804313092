import React from 'react';
import {func, string, bool} from 'prop-types';

import {HomeIcon, SettingsIcon, ShareIcon, LogoutIcon} from 'components/Icons';
import { Link } from 'react-router-dom';

const MenuItem = ({active, name, path, goTo, logout, closeSidebar}) => (
  <div className={`flex flex-column pointer items-center justify-center w-100 h-1-and-5-rem-ns mb-2-and-5-rem-ns mb3 ${active ? 'bl-violet-ns bt-violet' : 'bl-ns bt-white'}`}>
      {name === "Home" &&
        <div className="mt0-ns mt2 square16px">
          <Link
            to={path}
            onClick={() => { 
              closeSidebar();
              goTo(path);
            }}
          >
            <HomeIcon active={active}/>
          </Link>
        </div>
      }
      {name === "Settings" &&
        <div className="mt0-ns mt2 square24px">
          <Link
            to={path}
            onClick={() => { 
              closeSidebar();
              goTo(path);
            }}
          >
            <SettingsIcon active={active}/>
          </Link>
        </div>
      }
      {name === "Logout" &&
        <div className="mt0-ns mt2 square16px">
          <div onClick={() => { logout() }}>
            <LogoutIcon active={active}/>
          </div>
        </div>
      }
    <style jsx="true">
      {`
        .square16px {
          height: 16px;
          width: 16px;
        }
        .square24px {
          height: 24px;
          width: 24px;
        }
        @media (min-width: 30em) {
          .bl-violet-ns {
            border-left: solid 3px #7049E2;
            -webkit-border-image:
              -webkit-gradient(linear, 0 0, 0 100%, from(#7049E2), to(#B235f4)) 1 100%;
            -webkit-border-image:
              -webkit-linear-gradient(#7049E2, #B235f4) 1 100%;
            -moz-border-image:
              -moz-linear-gradient(#7049E2, #B235f4) 1 100%;
            -o-border-image:
              -o-linear-gradient(#7049E2, #B235f4) 1 100%;
            border-image:
              linear-gradient(to bottom, #7049E2, #B235f4) 1 100%;
          }
          .bl-ns {
            border-left: solid 3px white;
          }
          .h-1-and-5-rem-ns {
            height: 1.5rem;
          }
          .mb-2-and-5-rem-ns {
            margin-bottom: 2.5rem;
          }
        }
        @media (max-width: 30em) {
          .bt-violet {
            border-top: solid 3px #7049E2;
          }
          .bt-white {
            border-top: solid 3px white;
          }
        }
      `}
    </style>
  </div>
);

MenuItem.propTypes = {
  active: bool.isRequired,
  name: string.isRequired,
  path: string.isRequired,
  goTo: func.isRequired,
  logout: func.isRequired
};

export default MenuItem;
