import React, { Fragment } from 'react';
import BlockContainer from 'components/BlockContainer';
import PageListDataTable from 'components/PageListDataTable';
import FacebookLogin from 'react-facebook-login';
import ComponentDidMount from 'containers/ComponentDidMount';
import Loader from 'components/Loader';

import {
  IG_ACCOUNT_PERMISSIONS_APPROVED,
  IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
  IG_ACCOUNT_PERMISSIONS_SUBMIT,
} from 'appEvents';

const InstagramAccountBlock = ({
  fanPages,
  instagram,
  facebook,
  reloadData,
}) => {
  const { currentAccount } = facebook.state;
  const { permState } = instagram.state;
  return (
    <ComponentDidMount exec={() => {
      instagram.setPermStateLoading();
      fanPages.getAll(instagram.checkPermissions(currentAccount));
    }}
    >
      <BlockContainer>
        <div className="pa4-ns pa3">
          <p className="subTitle ma0">Connected Instagram Accounts</p>
          <div className="tc flex-row pt4-ns pt3 items-center flex-nowrap-ns flex-wrap">
            <Fragment>
              { permState === IG_ACCOUNT_PERMISSIONS_SUBMIT && <Loader center />}
              { permState === IG_ACCOUNT_PERMISSIONS_APPROVED
            && (
            <PageListDataTable
              fanPages={fanPages}
              instagram={instagram}
              facebook={facebook}
              reloadData={reloadData}
            />
            )}
              { permState === IG_ACCOUNT_PERMISSIONS_NOT_APPROVED
              && (
              <FacebookLogin
                appId={process.env.FB_APP_ID}
                autoLoad
                fields="email,name"
                cookie={false}
                scope={_.join(_.union(__fbAppScope.split(','), __igAppScope.split(',')))}
                textButton="Connect Instagram Account"
                cssClass="btnIG"
                size="large"
                onClick={() => { }}
                callback={(response) => {
                  instagram.setPermStateLoading();
                  if (_.has(response, 'accessToken')) {
                    facebook.syncIgMedia();
                    facebook.updateAccount(response, reloadData);
                  } else {
                    instagram.resetPermState();
                    console.log('Error: ', response);
                  }
                }}
              />
              )}
            </Fragment>
          </div>
        </div>
        <style jsx="true">
          {`
                .btnInstagram {
                  width: 165px;
                  height:35px;  
                  border-radius: 4px;
                  background: #3b5998;
                  color:white;
                  border:0px transparent;  
                  text-align: center;
                  margin:5px;
                  display: inline-block;
                }
                .green {
                  font-family: 'Open Sans', sans-serif;
                  color: #57CD79;
                  font-weight: 100;
                  font-size: 0.6rem;
                }
                .boldText {
                  font-family: 'Open Sans', sans-serif;
                  color: #382C58;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
                .emailText {
                  font-family: 'Open Sans', sans-serif;
                  color: #C2BECC;
                  font-weight: 700;
                  font-size: 0.6rem;
                }
                .social-icon {
                  border-radius: 5rem;
                  height: 2rem;
                  width: 2rem;
                }
                .fb {
                  border: solid 1px #484B99;
                }
                .tw {
                  border: solid 1px #3A91F6;
                }
                .ln {
                  border: solid 1px #236CB6;
                }
                .social-icon-img {
                  height: 0.5rem;
                  width: 0.5rem;
                }
                .splitter {
                  color: #9A8AA8;
                }
                .fg1 {
                  flex-grow: 1;
                }
                button.remove {
                  border-radius: 5rem;
                  height: 2rem;
                  background-color: white;
                  color: rgb(154, 138, 168);
                  border-color: #EAD7FB;
                  border-style: solid;
                  border-width: 1px;
                  outline: none;
                  font-family: 'Montserrat', sans-serif;
                  font-size: 0.5rem;
                  font-weight:700;
                }
                input {
                  outline: none;
                  padding-top:0;
                  padding-bottom:0;
                  border-radius: 0.25rem;
                  border: solid 1px #9A8AA8;
                  height: 2rem;
                  line-height: 2rem;
                  width: auto;
                  font-family: 'Open Sans', sans-serif;
                  color: #382C58;
                  font-weight: 600;
                  font-size: 0.65rem;
                }
              `}
        </style>
      </BlockContainer>

    </ComponentDidMount>
  );
};

export default InstagramAccountBlock;
