import React from 'react';
import { Redirect } from 'react-router-dom';

export default ({ children, targetPath }) => {
  const { pathname } = window.location;
  return (
    <div>
      {targetPath && pathname !== targetPath && <Redirect to={{ pathname: targetPath }} />}
      {children}
    </div>
  );
};
