import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Subscribe } from 'unstated';
import UserContainer from 'containers/UserContainer';
import Observer from 'containers/Observer';
import {
  DASHBOARD,
} from 'appEvents';

const AuthRoute = ({ component: Component, ...rest }) => {

  if (rest && rest.computedMatch && rest.computedMatch.params && rest.computedMatch.params.stackCoupon) {
    localStorage.setItem('stackCoupon', rest.computedMatch.params.stackCoupon);
  }

  if (rest && rest.computedMatch && rest.computedMatch.params && rest.computedMatch.params.referralToken) {
    localStorage.setItem('referralToken', rest.computedMatch.params.referralToken);
  }

  if (rest && rest.computedMatch && rest.computedMatch.params && rest.computedMatch.params.volume) {
    localStorage.setItem('selectedVolume', rest.computedMatch.params.volume);
  }

  return (
    <Subscribe to={[UserContainer]}>
      {userContainer => (
        <Route
          {...rest}
          render={(props) => {
            if (userContainer.state.status === DASHBOARD) {
              return (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    state: { from: props.location },
                  }}
                />
              );
            }
            return (
              <Observer>
                <Component {...props} />
              </Observer>
            );
          }}
        />
      )}
    </Subscribe>
  );
};

export default AuthRoute;
