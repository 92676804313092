import React from 'react';

export default () => (
  <div className="relative to_protect_footer">
    <div className="top_radius">
      <div className="hidden_block">
        <div className="base"></div>
      </div>
    </div>
    <div className="circles_left circles_custom">
    </div>
    
    <div className="center mw1320 ph5-l ph4-m ph3">
      
      <div className="block1">
        <div className="protected">
          Are you ready to protect
          your social media presence?
        </div>
      </div>
      <div className="block2">
        <div className="button btn_transparent">
          GET STARTED
        </div>
      </div>
      
      <div className="white_line">
      </div>
      
      <div className="footer relative pb5-l pb4">
        <div className="copyright">© 2018, Sour Grapes</div>
      </div>
    </div>
    <style jsx="true">{`
      .mw1320{
        max-width: 1320px;
      }
      .to_protect_footer {
        padding-top: 200px;
        background: #8540ea;
      }
      
      .top_radius {
        position: absolute;
        width: 100%;
        top: -100px;
        clear: both;
        height: 200px;
      }
      
      .top_radius:before {
        content: '';
        display: block;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 100px;
      }
      
      .hidden_block {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      
      .base {
        position: absolute;
        display: block;
        width: 110%;
        height: 100%;
        left: -5%;
        border-radius: 100%;
        background: #8540ea;
      }
      
      .protected {
        color: rgba(255, 255, 255, 0.8);
        font-size: 36px;
        line-height: 54px;
        color: #ffffff;
        font-weight: 400;
        max-width: 600px;
        margin-bottom: 160px;
        font-family: 'Roboto',sans-serif;
      }
      
      .block1 {
        float: left;
        width: 50%;
      }
      
      .block2 {
        float: left;
        width: 50%;
        text-align: right;
      }
      
      .button {
        color: #ffffff;
        font-size: 12px;
        line-height:1.2rem;
        font-weight: 900;
        text-transform: uppercase;
        background: #bb6df4;
        border: 0;
        position: relative;
        top: 40px;
      }
      
      .white_line {
        clear: both;
        height: 1px;
        background: #9d5cee;
        margin-bottom: 65px;
      }
      
      .copyright {
        float: left;
        width: 50%;
        color: #e6d9fa;
      }
      
      .icons {
        float: left;
        width: 50%;
        text-align: right;
        margin-bottom: 52px;
      }
      
      .icons a {
        margin-left: 48px;
      }
      
      .circles_custom {
        background-position: left 0%;
      }
      @media only screen and (max-width:700px) {
        .to_protect_footer {
          padding-top: 130px;
        }
      
        .block1,.block2 {
          width:100%;
          text-align:center;
          float:none;
        }
      
        .protected {
          margin-bottom: 80px;
        }
      
        .button {
          top: 0;
          margin-bottom: 100px;
        }
      
        .copyright{
          width:60%;
        }
      
        .icons{
          width:40%;
        }
      
        .icons a{
          margin-left: 20px;
        }
      }

    `}</style>
  </div>
);
