import React from 'react';

export default () => (
  <div className="relative manage_multiple">
  
    <div className="manage_multiple_top_radius">
      <div className="hidden_block">
        <div className="base"></div>
      </div>
    </div>
  
    <div className="manage_multiple_bottom_radius">
      <div className="hidden_block">
        <div className="base"></div>
      </div>
    </div>
    
    <div className="body_block">
      <div className="circles_right"></div>
      <div className="circles_left"></div>
      
      <div className="title">
        Manage multiple pages
        from a simple dashboard
      </div>
    
      <div className="text">
        Within a few taps, monitor & take care of
        comments on any fanpage that you own.
      </div>
    
      <div className="image">
        <img src="/graphic.png"/>
      </div>
    </div>
    <style jsx="true">{`
      .manage_multiple{
        margin-bottom: 300px;
      }
      
      .body_block {
        position: relative;
        background: #8540ea;
        padding-top: 100px;
        padding-left:16px;
        padding-right:16px;
      }
      
      .title{
        font-weight: 400;
        font-family: 'Roboto',sans-serif;
        margin: 0 auto;
        color: #ffffff;
        font-size: 36px;
        line-height: 54px;
        max-width:494px;
        text-align:center;
      }
      
      .text{
        font-family: 'Roboto',sans-serif;
        max-width:440px;
        margin:48px auto 156px;
        text-align:center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
      }
      
      .image{
        position:relative;
        text-align:center;
        height: 333px;
        width:100%;
      }
      
      .image img{
        position:relative;
        left:-100px;
      }
      
      /* --- */
      
      .manage_multiple_top_radius{
        position: absolute;
        width: 100%;
        top: -100px;
        clear: both;
        height: 200px;
        background:#ffffff;
      }
      
      .manage_multiple_top_radius::before {
        content: '';
        display: block;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 100px;
      }
      
      .manage_multiple_top_radius .hidden_block{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      
      .manage_multiple_top_radius .base {
        position: absolute;
        display: block;
        width: 110%;
        height: 100%;
        left: -5%;
        border-radius: 100%;
        background: #8540ea;
      }
      
      /* --- */
      
      .manage_multiple_bottom_radius {
        position: absolute;
        width: 100%;
        top: calc(100% - 100px);
        clear: both;
        height: 200px;
        background: #ffffff;
      }
      
      .hidden_block {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      
      .base {
        position: absolute;
        display: block;
        width: 110%;
        height: 100%;
        left: -5%;
        border-radius: 100%;
        background: #8540ea;
      }
      
      .manage_multiple_bottom_radius:after {
        display: block;
        width: 100%;
        background: #ffffff;
        content: '';
        height: 200px;
      }
      
      .manage_multiple_bottom_radius:before{
        content: '';
        display: block;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 100px;
      }
      @media only screen and (max-width:1199px) {
        .manage_multiple .image img{
          width:100%;
          left:initial;
        }
      }
      @media only screen and (max-width:800px) {
        .manage_multiple {
          margin-bottom: 110px;
        }
      }
    `}</style>
    
  </div>
);
