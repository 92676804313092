import React, { Fragment } from 'react';
import ModalError from 'components/ModalError';

// TODO: refactor
export default ({
  fanPages,
  billing,
  facebook,
  commentsContainer,
  userContainer,
  children,
}) => (
  <Fragment>
    {userContainer && (
    <ModalError
      visible={userContainer.state.showErrorModal}
      confirmHandler={() => { userContainer.closeErrorModal(); }}
      errorTitle="Error"
      errorText={userContainer.state.modalErrorMessage}
    />
    )}
    {fanPages && (
      <ModalError
        visible={fanPages.state.showErrorModal}
        confirmHandler={() => { fanPages.closeErrorModal(); }}
        errorTitle="Error"
        errorText={fanPages.state.modalErrorMessage}
      />
    )}
    {billing && (
      <ModalError
        visible={billing.state.showErrorModal}
        confirmHandler={() => {
          billing.confirmSubscriptionCancel();
        }}
        cancelHandler={() => { billing.cancelSubscriptionCancel(); }}
        errorTitle="Warning"
        errorText={billing.state.cancelErrorMessage}
      />
    )}
    {facebook && (
    <ModalError
      visible={facebook.state.showErrorModal}
      confirmHandler={() => { facebook.closeErrorModal(); }}
      errorTitle="Invalid Facebook Account"
      errorText="Your Facebook account token required to be updated.
      Please go to the Settings page and click Add or Update Facebook Account button."
      data={facebook.state.accountsInvalid}
    />
    )}
    {commentsContainer && (
    <ModalError
      visible={commentsContainer.state.showErrorModal}
      confirmHandler={() => { commentsContainer.closeErrorModal(); }}
      errorTitle="Can not hide or unhide"
      errorText={commentsContainer.state.error}
    />
    )}
    {children}
  </Fragment>
);
