import React from 'react';
import { Container } from 'unstated';

class RouterContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.goTo = this.goTo.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  goTo(pathname) {
    this.setState({ pathname });
    setTimeout(() => {
      this.setState({ pathname: null });
    }, 1000);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  openSidebar() {
    this.setState({ sidebarOpen: true });
  }

  closeSidebar() {
    this.setState({ sidebarOpen: false });
  }
}

export default RouterContainer;
