import React from 'react';
import propTypes from 'prop-types';
import ComponentDidMount from 'containers/ComponentDidMount';
import SubscribeModal from 'components/SubscribeModal';
import MobileSidebar from 'components/MobileSidebar';
import Notifications from 'components/Notifications';
import PathValidator from 'components/PathValidator';
import StopWordInputScreen from 'components/StopWordInputScreen';
import Stats from 'components/Stats';
import MenuSidebar from 'components/MenuSidebar';
import StopWords from 'components/StopWords';
import SentimentAnalysisBlock from 'components/SentimentAnalysisBlock';
import AdPostAnalysisBlock from 'components/AdPostAnalysisBlock';
import CommentsLog from 'components/CommentsLog';
import EmptyOverview from 'components/EmptyOverview';
import Loader from 'components/Loader';
import PageMeter from 'components/PageMeter';
import _ from 'lodash';

const isPlanFree = (list, selected) => {
  if (_.isEmpty(list)) {
    return undefined;
  }
  return !_.isNil(list.find(plan => plan.value === selected && parseInt(plan.price) <= 0));
};

const Dashboard = ({
  location,
  stopWord,
  fanPages,
  comments,
  router,
  selectPage,
  getGlobalSidebarData,
  dashboardDataIsReady,
  noPages,
  canRenderSidebar,
  userContainer,
  facebook,
  registration,
}) => (
  <ComponentDidMount exec={() => {
    getGlobalSidebarData();
    userContainer.connectToUpdatesChannel((ev) => {
      if (ev.type === 'billing_status') {
        userContainer.setState({
          user: {
            ...userContainer.state.user,
            billing: {
              ...userContainer.state.user.billing,
              status: ev.payload.status,
            },
          },
        });
        userContainer.checkPausedState();
      }
      if (ev.type === 'hidden_comment') {
        if (fanPages.state.currentPage
            && ev.payload.page_id === fanPages.state.currentPage.id) {
          if (ev.payload.is_hidden === true) {
            fanPages.state.currentPage.hiddenTotal += 1;
            fanPages.state.currentPage.hiddenThisWeek += 1;
          }
        }
        comments.handleNewComment(ev.payload);
      }
    });
    const { plan_list, plan_id } = registration.state;
    const isFree = isPlanFree(plan_list, plan_id);
    isFree === true && gtag('event', 'signup', { event_category: 'Signup', event_label: 'Free' });
    isFree === false && gtag('event', 'signup', { event_category: 'Signup', event_label: 'Paid' });
    registration.setState({ plan_list: [] });
  }}
  >
    <Notifications
      fanPages={fanPages}
      facebook={facebook}
      commentsContainer={comments}
      userContainer={userContainer}
    >
      <PathValidator targetPath={router.state.pathname}>
        <SubscribeModal
          showModal={fanPages.state.showModal}
          closeModal={fanPages.closeModal}
          getAll={fanPages.getAll}
          selectAll={fanPages.selectAll}
          fanPagesCollection={fanPages.state.fanPages}
          error={fanPages.state.error}
          subscribetoAllSelected={fanPages.subscribetoAllSelected}
          subscribeToggle={fanPages.subscribeToggle}
          getSubscribed={getGlobalSidebarData}
          fanPages={fanPages}
        >
          <MobileSidebar
            open={router.state.sidebarOpen}
            selectPage={(id) => { selectPage(id); }}
            subscribedPages={fanPages.state.subscribedPages}
            currentPage={fanPages.state.currentPage}
            openModal={() => { fanPages.openModal(); }}
            closeSidebar={() => { router.closeSidebar(); }}
            goTo={(v) => { router.goTo(v); }}
            location={location}
            logout={() => { userContainer.submitLogoutHandler(); }}
            onSetOpen={open => router.onSetSidebarOpen(open)}
          >
            <div className="flex flex-row w-100 h-100 bg-lightpink relative">
              {canRenderSidebar && (
                <MenuSidebar
                  className="flex-ns dn"
                  selectPage={(id) => { selectPage(id); }}
                  subscribedPages={fanPages.state.subscribedPages}
                  currentPage={fanPages.state.currentPage}
                  openModal={() => { fanPages.openModal(); }}
                  goTo={(v) => { router.goTo(v); }}
                  location={location}
                  logout={() => { userContainer.submitLogoutHandler(); }}
                />
              )}
              {dashboardDataIsReady && (
                <StopWordInputScreen
                  pageId={fanPages.state.currentPage.id}
                  isBlackList={stopWord.state.isBlackList}
                  showModal={stopWord.state.showModal}
                  addToList={(e, pageId) => { stopWord.addToList(e, pageId); }}
                  onWordChange={(e) => { stopWord.onWordChange(e); }}
                  closeModal={() => { stopWord.closeModal(); }}
                  newWord={stopWord.state.newWord}
                />
              )}
              {!dashboardDataIsReady && !noPages && (
                <div className="pa4-ns pa3 pl3-m flex flex-column w-100 vh-100-l h-100 items-center justify-center">
                  <Loader center />
                </div>
              )}
              {noPages && <EmptyOverview addPagesHandler={() => { fanPages.openModal(); }} />}
              {dashboardDataIsReady && !noPages && (
                <div className="pa4-ns pl6-m  pv3 ph2 flex flex-column w-100 h-100 vh-100-l">
                  <div className="flex flex-row justify-between pb4 items-center">
                    <div className="flex flex-row pl2">
                      <img className="w-1-and-4-rem pb-2-and-half-rem-ns pointer dn-ns dib" src="/sg-logo.svg" />
                      <h1 className="title ma2">{fanPages.state.currentPage.name}</h1>
                    </div>
                    <img
                      className="h26px w-auto dn-ns dib pl2 pt2 pointer"
                      src="/menu.svg"
                      onClick={() => router.openSidebar()}
                    />
                  </div>
                  <div className="flex flex-row-l flex-column h-100 w-100 fg1">
                    <div className="flex justify-between flex-column h-100 w-100">
                      <div className="pb3 ph2">
                        <Stats
                          all={fanPages.state.currentPage.hiddenTotal}
                          thisWeek={fanPages.state.currentPage.hiddenThisWeek}
                          sinceLastLogin={fanPages.state.currentPage.hiddenSinceLogin}
                        />
                      </div>
                      <div className="pb3 ph2 flex flex-column fg1">
                        <StopWords
                          blackList={stopWord.state.blackList}
                          whiteList={stopWord.state.whiteList}
                          isBlackList={stopWord.state.isBlackList}
                          isWhiteList={stopWord.state.isWhiteList}
                          getListsError={stopWord.state.getListsError}
                          setList={(list) => { stopWord.setList(list); }}
                          openModal={() => { stopWord.openModal(); }}
                          deleteItem={(id) => { stopWord.deleteItem(id); }}
                        />
                      </div>
                      <div className="pb3 ph2">
                        <SentimentAnalysisBlock
                          sentiment={fanPages.state.currentPage.sentiment}
                          currentPageId={fanPages.state.currentPage.id}
                          sentimentToggle={(id) => { fanPages.sentimentToggle(id); }}
                        />
                      </div>
                      <div className="pb3 ph2">
                        <AdPostAnalysisBlock
                          adPostOnly={fanPages.state.currentPage.adpostonly}
                          currentPageId={fanPages.state.currentPage.id}
                          adPostToggle={(id) => { fanPages.adPostToggle(id); }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-column w-100 h-100">
                      {/* <div className="pb3 ph2"> */}
                      {/*  <PageMeter comments={comments.state.comments} /> */}
                      {/* </div> */}
                      <div className="pb3 ph2">
                        <CommentsLog
                          comments={comments.state.comments}
                          toggleComment={(id) => { comments.toggleComment(id); }}
                          commentsContainer={comments}
                          currentEpoch={comments.state.currentEpoch}
                          currentFilter={comments.state.currentFilter}
                          currentPageId={fanPages.state.currentPage.id}
                          getAll={(pageId, timeframe, filterBy) => {
                            comments.getAll(pageId, timeframe, filterBy);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </MobileSidebar>
        </SubscribeModal>
      </PathValidator>
    </Notifications>
    <style jsx="true">
      {`
        .h26px {
          height: 26px;
        }
        .w-1-and-4-rem {
          width: 1.4rem;
        }
      `}
    </style>
    <style global="true" jsx="true">
      {`
        .h26px {
          height: 26px;
        }
        .w-1-and-4-rem {
          width: 1.4rem;
        }
        .toTheLeft {
          left: -3px !important;
        }
        div[data-react-class="App"] {
          height: 100%;
          width: 100%;
        }
        div[data-react-class="App"] > div {
          height: auto;
          width: 100%;
        }

      `}
    </style>
  </ComponentDidMount>
);

Dashboard.propTypes = {
  greeting: propTypes.string,
};

export default Dashboard;
