import React, { Fragment } from 'react';
import {
  arrayOf, shape, string, number, func,
} from 'prop-types';
import PageItem from 'components/PageItem';

const Pages = ({
  subscribedPages, currentPage, openModal, selectPage, closeSidebar,
}) => (
  <Fragment>
    <div className="h-100 flex flex-column justify-start-ns justify-center fg1-s flex-wrap w-auto overflow-y-auto">
      {subscribedPages.map(item => (
        <PageItem
          key={item.name}
          name={item.name}
          selectPage={selectPage}
          id={item.id}
          active={item.id === currentPage.id}
          image={item.image}
          closeSidebar={closeSidebar}
        />
      ))}
    </div>
    <div className="flex flex-row items-center mw10rem pt0-ns pt3 pb0-ns pb4">
      <div onClick={() => { openModal(); }} className="bg-violet pointer flex flex-column items-center justify-center size-37px-ns size-53px mb-1-and-3-rem-ns br-0-7-rem">
        <span className="violet f4-ns f3">+</span>
      </div>
      <div className="dn-ns dib pl2">Add new</div>
    </div>
    <style jsx="true">
      {`
        .mw10rem {
          max-width: 10rem;
        }
        @media (min-width: 30em) {
          .size-37px-ns {
            width: 37px;
            height: 37px;
          }
          .mb-1-and-3-rem-ns {
            margin-bottom: 1.3rem;
          }
        }
        @media (max-width: 30em) {
          .size-53px {
            width: 53px;
            height: 53px;
          }
          .fg1-s {
            flex-grow:1;
          }
        }
        .violet {
          color: #7049E2;
        }
        .br-0-7-rem {
          border-radius: 0.7rem;
        }
        .bg-violet {
          background-color:#F6EFFE;
        }
        .bg-violet:hover, .bg-violet:focus {
          background-color: #7049E2;
        }
        .bg-violet:hover .violet, .violet:hover, .violet:focus {
          color: #F6EFFE;
        }
      `}
    </style>
  </Fragment>
);

Pages.propTypes = {
  subscribedPages: arrayOf(shape({
    name: string.isRequired,
    image: string.isRequired,
    id: number.isRequired,
  })),
  currentPage: shape({
    name: string.isRequired,
    image: string.isRequired,
    id: number.isRequired,
  }),
  openModal: func.isRequired,
  selectPage: func.isRequired,
  closeSidebar: func.isRequired,
};

Pages.defaultProps = {
  subscribedPages: [],
  currentPage: null,
};

export default Pages;
