import React from 'react';
import propTypes from 'prop-types';
import PathValidator from 'components/PathValidator';
import UserLoginForm from 'components/UserLoginForm';
import ForgotPasswordModal from 'components/ForgotPasswordModal';

const UserLogin = ({
  location,
  userContainer,
  router,
}) => (
  <PathValidator targetPath={router.state.pathname}>
    <ForgotPasswordModal
      userContainer={userContainer}
    />
    <UserLoginForm
      location={location}
      userContainer={userContainer}
    />
  </PathValidator>
);

UserLogin.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }).isRequired,
};

export default UserLogin;
