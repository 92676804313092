import React from 'react';
import FbLogin from 'components/FbLogin';
import ComponentDidMount from 'containers/ComponentDidMount';

const FbLoginState = ({userContainer, location, error}) => {
    return (
        <ComponentDidMount exec={()=>{userContainer.getUserData()}}>
            <FbLogin submitLoginHandler={userContainer.submitLoginHandler}
                     location={location}
                     error={error}/>
        </ComponentDidMount>)
};

export default FbLoginState;

