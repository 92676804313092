import React from 'react';
import propTypes from 'prop-types';
import BlockContainer from 'components/BlockContainer';

const SendEmail = ({invite, emailChangeHandler, emailInput}) => {
  return (
    <BlockContainer>
      <div className="pa4">
        <p className="subTitle ma0">Send an email</p>
        <div className="flex flex-row pt4 items-center justify-center">
          <input onChange={emailChangeHandler} value={emailInput} className="ph3 fg1" placeholder="Use commas to invite several people"/>
          <button onClick={invite} style={{width: '7rem'}} className="pointer ml3 ph3 ttu white invite">Invite</button>
        </div>
      </div>
      <style jsx="true">
        {`
          .fg1 {
            flex-grow: 1;
          }
          button.invite {
            border-radius: 5rem;
            height: 2rem;
            background-color: #7049E2;
            background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
            background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
            background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
            background-image: linear-gradient(to right, #7049E2, #9A6FED);
            border: transparent;
            outline: none;
            -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
            box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
            font-family: 'Montserrat', sans-serif;
            font-size: 0.5rem;
            font-weight:600;
          }
          input {
            outline: none;
            border-radius: 0.25rem;
            border: solid 1px #9A8AA8;
            height: 2rem;
            padding-top:0;
            padding-bottom:0;
            line-height: 2rem;
            width: auto;
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.65rem;
          }
        `}
      </style>
    </BlockContainer>
  )
}

export default SendEmail;
