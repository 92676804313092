import { Container } from 'unstated';
import _ from 'lodash';

import {
  LOGIN_FB_FORM,
  DASHBOARD,
  IG_ACCOUNT_PERMISSIONS_APPROVED,
  IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
  IG_ACCOUNT_PERMISSIONS_SUBMIT,
} from 'appEvents';

const request = require('superagent');

const revokePermission = (fbAccId, permission) => new Promise((resolve, reject) => {
  request
    .patch(`api/v1/fb_accounts/${fbAccId}/revoke-permission`)
    .set('Content-Type', 'application/json')
    .send({ fb_account: { permission } })
    .set('Authorization', localStorage.getItem('authToken'))
    .end((error, response) => {
      if (error) {
        console.log('Error: ', response);
        reject();
      } else {
        resolve(true);
      }
    });
});

class FacebookContainer extends Container {
  constructor(props) {
    super(props);

    this.state = {
      authToken: localStorage.getItem('authToken'),
      accounts: [],
      accountsInvalid: [],
      currentAccount: null,
      permissionIG: null,
      error: null,
      dataLoading: false,
      showErrorModal: false,
      igMedia: false,
    };

    this.submitLoginHandler = this.submitLoginHandler.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.getAccountList = this.getAccountList.bind(this);
    this.accountsCallback = this.accountsCallback.bind(this);
    this.revokeIGPermissions = this.revokeIGPermissions.bind(this);
    this.accountSwitch = this.accountSwitch.bind(this);
    this.accountRemove = this.accountRemove.bind(this);
    this.dataLoadStart = this.dataLoadStart.bind(this);
    this.dataLoadEnd = this.dataLoadEnd.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.syncIgMedia = this.syncIgMedia.bind(this);
  }

  syncIgMedia() {
    this.setState({ igMedia: true });
  }

  closeErrorModal() {
    this.setState({ showErrorModal: false });
  }

  dataLoadStart() {
    this.setState({ dataLoading: true });
  }

  dataLoadEnd() {
    this.setState({ dataLoading: false });
  }

  accountRemove(id, cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    request
      .delete(`/api/v1/fb_accounts/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', authToken)
      .end((error, response) => {
        if (error) {
          console.log('Error: ', error);
          console.log('Response: ', response);
          this.setState({ error });
        } else {
          cb && cb();
        }
      });
  }

  accountSwitch(id, cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    request
      .patch(`/api/v1/fb_accounts/${id}/switch-account`)
      .set('Content-Type', 'application/json')
      .set('Authorization', authToken)
      .send({
        fb_account: { current: true },
      })
      .end((error, response) => {
        if (error) {
          console.log('Error: ', error);
          console.log('Response: ', response);
          this.setState({ error });
        } else {
          cb && cb();
        }
      });
  }

  revokeIGPermissions(cb) {
    const { currentAccount } = this.state;
    this.setState({ permissionIG: null });

    if (currentAccount) {
      const igPromises = __igAppScope.split(',').map(scope => (revokePermission(currentAccount.id, scope)));
      Promise.all(igPromises)
        .then((response) => {
          cb && cb();
        })
        .catch((e) => {
          console.log('revokePermissions error: ', e);
          this.setState({ permissionIG: IG_ACCOUNT_PERMISSIONS_NOT_APPROVED });
          cb && cb();
        });
    } else {
      console.log('revokeIGPermissions error: current FB account not set');
      this.setState({ permissionIG: IG_ACCOUNT_PERMISSIONS_NOT_APPROVED });
    }
  }

  accountsCallback(newItem) {
    const { accounts } = this.state;
    const newList = _.isArray(newItem) ? newItem : [...accounts.filter(el => el.user_id !== newItem.user_id), newItem];
    const currentAccount = newList.find(el => el.current === true);
    const accountsInvalid = accounts.filter(el => el.is_valid === false && el.sync_in_progress === false);
    this.setState({
      error: null,
      accounts: newList,
      accountsInvalid,
      currentAccount,
      status: currentAccount ? DASHBOARD : LOGIN_FB_FORM,
      showErrorModal: !!_.size(accountsInvalid),
    });
  }

  submitLoginHandler(response) {
    this.createAccount(response, this.accountsCallback);
  }

  getAccountList(cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    this.setState({ error: null });
    if (authToken) {
      request
        .get('/api/v1/fb_accounts')
        .set('Content-Type', 'application/json')
        .set('Authorization', authToken)
        .end((error, response) => {
          if (error) {
            console.log('getAccountList ERROR: ', error);
            this.setState({ error });
          } else {
            this.accountsCallback(response.body);
            cb && cb();
          }
        });
    }
    return true;
  }

  updateAccount(params, cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    const { currentAccount, accounts, igMedia } = this.state;
    const {
      name, email, accessToken, userID, expiresIn, data_access_expiration_time,
    } = params;
    if (currentAccount && currentAccount.id) {
      request
        .patch(`/api/v1/fb_accounts/${currentAccount.id}`)
        .set('Content-Type', 'application/json')
        .set('Authorization', authToken)
        .send({
          fb_account: {
            name,
            email,
            accessToken,
            userID,
            expiresIn,
            data_access_expiration_time,
            igMedia,
          },
        })
        .end((error, response) => {
          if (error) {
            console.log('Error: ', error);
            console.log('------> Response: ', response);
            this.setState({ error, igMedia: false });
          } else {
            const acc = response.body;
            this.setState({
              error: null,
              currentAccount: acc,
              status: acc ? DASHBOARD : LOGIN_FB_FORM,
              accounts: accounts.map(el => (el.id === acc.id
                ? { ...el, acc } : el)),
              igMedia: false,
            });
            cb && cb();
          }
        });
    } else {
      console.log('Error: No Current Account has been Set!');
      console.log('LIST: ', this.state);
    }
  }

  createAccount(params, cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    const {
      name, email, accessToken, userID, expiresIn,
    } = params;
    request
      .post('/api/v1/fb_accounts')
      .set('Content-Type', 'application/json')
      .set('Authorization', authToken)
      .send({
        name, email, accessToken, userID, expiresIn,
      })
      .end((error, response) => {
        if (error) {
          this.setState({
            status: LOGIN_FB_FORM,
            error: 'Account already exists, please try another FB account.',
          });
        } else {
          const { accounts } = this.state;
          const list = this.handleAccountListChange(accounts, response.body);
          cb && cb(list);
        }
      });
  }
}

export default FacebookContainer;
