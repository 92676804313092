import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import {
  InitialState,
  SignupProcessingState,
  FbSignupState,

} from 'components/SignupPage';

import {
  SESSION_SIGNUP_FORM_SUBMIT,
  SESSION_SIGNUP_FORM_SUCCESS,
  SESSION_SIGNUP_FORM_ERROR,
  SESSION_SUBMIT_FB_AUTH,
  SESSION_SUBMIT_FB_ERROR,
  SESSION_SUBMIT_FB_SUCCESS,
} from 'appEvents';


const SignupForm = ({ location, session }) => (
  <Fragment>
    {session.state.status === null && (
      <InitialState
        location={location}
        session={session}
        submitSignUpHandler={() => session.submitSignUpHandler()}
      />
    )}
    {session.state.status === SESSION_SIGNUP_FORM_SUBMIT && <SignupProcessingState />}
    {session.state.status === SESSION_SIGNUP_FORM_ERROR && (
      <InitialState
        location={location}
        session={session}
        submitSignUpHandler={() => session.submitSignUpHandler()}
        error={session.state.error}
        errorType={session.state.errorType}
      />
    )}
    {session.state.status === SESSION_SIGNUP_FORM_SUCCESS && (
      <FbSignupState
        location={location}
        session={session}
      />
    )}
    {session.state.status === SESSION_SUBMIT_FB_AUTH && (
      <FbSignupState
        location={location}
        session={session}
      />
    )}
    {session.state.status === SESSION_SUBMIT_FB_ERROR && (
      <FbSignupState
        location={location}
        session={session}
        error={session.state.error}
        errorType={session.state.errorType}
      />
    )}
    {session.state.status === SESSION_SUBMIT_FB_SUCCESS && <div />}
  </Fragment>
);

SignupForm.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }).isRequired,
};

export default SignupForm;
