import { Container } from 'unstated';

import {
  ADD_COUPON_CODE_PROCESSING,
  ADD_COUPON_CODE_FORM,
  ADD_COUPON_CODE_ERROR,
  ADD_COUPON_CODE_SUCCESS,
} from 'appEvents';

const request = require('superagent');

class CouponCodeContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      coupon: '',
      stackCoupon: '',
      status: null,
      error: '',
      errorType: '',
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.varifyHandler = this.varifyHandler.bind(this);
    this.submitStackCouponHandler = this.submitStackCouponHandler.bind(this);
  }

  changeHandler(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    this.setState({ [name]: value });
  }

  submitStackCouponHandler() {
    const { stackCoupon } = this.state;
    if (stackCoupon) {
      this.setState({ status: ADD_COUPON_CODE_PROCESSING });
      request
        .post('/api/coupon_codes')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({ coupon_code: { code: stackCoupon } })
        .end((error, response) => {
          if (error) {
            this.setState({ status: ADD_COUPON_CODE_ERROR, errorType: ADD_COUPON_CODE_ERROR, error: response.body.error });
          } else {
            this.setState({ status: ADD_COUPON_CODE_SUCCESS, errorType: '', error: '' });
          }
        });
    }
    return true;
  }

  submitHandler() {
    const { coupon, stackCoupon } = this.state;
    const reqFunc = code => new Promise((resolve, reject) => {
      request
        .post('/api/coupon_codes')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({ coupon_code: { code } })
        .end((error, response) => {
          error && reject(error);
          !error && resolve(response);
        });
    });
    const codesReqs = [];
    coupon && codesReqs.push(reqFunc(coupon));
    stackCoupon && codesReqs.push(reqFunc(stackCoupon));
    Promise.all(codesReqs).then((values) => {
      console.log('Finished: ', values);
      // cb && cb();
    }).catch((err) => {
      console.log('Catch err: ', err);
      // cb && cb(err)
    });
    return true;
  }

  varifyHandler(cb) {
    const { coupon, stackCoupon } = this.state;
    const reqFunc = code => new Promise((resolve, reject) => {
      request
        .get(`/api/coupon_codes/${code}`)
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .end((error, response) => {
          error && reject(error);
          !error && resolve(response);
        });
    });
    const codesReqs = [];
    coupon && codesReqs.push(reqFunc(coupon));
    stackCoupon && codesReqs.push(reqFunc(stackCoupon));
    Promise.all(codesReqs).then((response) => {
      cb && cb(response);
    }).catch((err) => {
      cb && cb(err);
    });
  }
}

export default CouponCodeContainer;
