import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import {
  InitialState,
  PwProcessingState,
  FbAuthState,
} from 'components/LoginPage';

import {
  SESSION_SUBMIT_PW_LOGIN,
  SESSION_SUBMIT_PW_ERROR,
  SESSION_SUBMIT_PW_SUCCESS,
  SESSION_SUBMIT_FB_AUTH,
  SESSION_SUBMIT_FB_ERROR,
  SESSION_SUBMIT_FB_SUCCESS,
  INVALID_CREDENTIALS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_FORM,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_SUCCESS,
  SESSION_SUBMIT_PW_NO_CCARD,
  ADD_NEW_CARD_FORM,
} from 'appEvents';

const Login = ({ location, session, forgotPassword }) => (
  <Fragment>
    <ForgotPasswordModal
      status={forgotPassword.state.status}
      showModal={forgotPassword.state.showModal}
      email={forgotPassword.state.email}
      closeModal={forgotPassword.closeModal}
      submitEmail={forgotPassword.submitEmailHandler}
      onEmailChange={forgotPassword.onEmailChange}
      error={forgotPassword.state.error}
      errorType={forgotPassword.state.errorType}
    />
    {session.state.status === SESSION_SUBMIT_PW_NO_CCARD && (
      <NoCCardState
        email={session.state.email}
        status={session.state.actions.addNewCard.status}
        submitCardHandler={(e) => { session.submitNewCard(e); }}
        cancelHandler={() => { session.cancelAddNewCardForm(); }}
      />
    )}
    {session.state.status === null && (
      <InitialState
        location={location}
        session={session}
        forgotPassword={forgotPassword}
        submitPasswordHandler={() => session.submitPasswordHandler()}
        inputChangeHandler={(e) => { session.handleInputChange(e); }}
        email={session.state.email}
        password={session.state.password}
        error={session.state.error}
        errorType={session.state.errorType}
      />
    )}
    {session.state.status === SESSION_SUBMIT_PW_LOGIN && <PwProcessingState />}
    {session.state.status === SESSION_SUBMIT_PW_ERROR && (
      <InitialState
        forgotPassword={forgotPassword}
        submitPasswordHandler={() => session.submitPasswordHandler()}
        inputChangeHandler={(e) => { session.handleInputChange(e); }}
        email={session.state.email}
        password={session.state.password}
        error={session.state.error}
        errorType={session.state.errorType}
      />
    )}
    {session.state.status === SESSION_SUBMIT_FB_AUTH && (
      <FbAuthState
        location={location}
        session={session}
      />
    )}
    {session.state.status === SESSION_SUBMIT_FB_SUCCESS && <div />}
  </Fragment>
);

Login.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }).isRequired,
};

export default Login;
