import React from 'react';
import BlockContainer from 'components/BlockContainer';

const AppSumoCommentsCounter = ({ volume }) => (
  <BlockContainer className="overflow-y-hidden">
    <div className="ph4-ns ph3 pt4-ns pt3">
      <p className="subTitle ma0 pb1">Comments Counter</p>
      <div className="flex flex-column items-center justify-center">
        <div className="pb2 w-100">
          <p className="label innerSubTitle">
            Comments Left: {volume}
          </p>
        </div>
      </div>
    </div>
    <style jsx="true">
      {`
        .innerSubTitle {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 500;
          font-size: 0.7rem;
        }
      `}
    </style>
  </BlockContainer>
);

export default AppSumoCommentsCounter;
