import React from 'react';
import BlockContainer from 'components/BlockContainer';

const Friend = ({friend}) => {
  return (
    <div className="h-2-5-rem flex flex-row justify-between items-center bb-lightgrey">
      <span className="word">{friend.recipient_email}</span>
      <span className={friend.status === "Registered" ? 'friendInfo registered' : 'friendInfo invited'}>{friend.status}</span>
      <style jsx="true">
        {`
          .h-2-5-rem {
            height: 2.5rem;
          }
          .friendInfo {
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 0.65rem;
          }
          .registered {
            color: #7049E2;
          }
          .invited {
            color: #9A8AA8;
          }
          .bb-lightgrey {
            border-bottom: 1px solid rgb(241, 241, 241);
          }
          .word-menu {
            outline:none;
            font-size: 1.3rem;
            font-family: 'Open Sans', sans-serif;
            color: #9A8AA8;
          }
        `}
      </style>
    </div>
  );
}

const FriendsList = ({friendsList}) => {
  return (
    <div className="relative">
      <div className="h22rem overflow-y-auto mt3 fade-in">
        {friendsList.map((friend, i) => {
          return (
            <div key={`friend-${i}`} className="pr3">
              <Friend friend={friend}/>
            </div>
          )
        })}
      </div>
      {friendsList.length > 8 && <div className="h2 absolute b0 white-gradient-overlay"/>}
      <style jsx="true">
        {`
          .h22rem {
            height: 22rem;
          }
          .b0 {
            bottom: 0;
          }
          .white-gradient-overlay {
            background: transparent;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 46%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 46%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            width: 101%; // to overlay the scrollbar
          }
          @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
          .fade-in {
            opacity:0.6;
            -webkit-animation:fadeIn ease-in 0.3s;
            -moz-animation:fadeIn ease-in 0.3s;
            animation:fadeIn ease-in 0.3s;
            -webkit-animation-fill-mode:forwards;
            -moz-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
            -webkit-animation-duration:0.3s;
            -moz-animation-duration:0.3s;
            animation-duration:0.3s;
          }
        `}
      </style>
    </div>
  );
}

class InvitedFriends extends React.Component {
  render () {
    const {friendsList} = this.props;
    return (
      <BlockContainer>
        <div className="pl4 pr3 pt4">
          <p className="subTitle ma0">Invited friends</p>
          <FriendsList friendsList={friendsList}/>
        </div>
        <style jsx="true">
          {`
            .pv-1-5-rem {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
            }
            button.subTitle, button.inactiveSubTitle {
              background-color:white;
              border:transparent;
              outline:none;
            }
            button.addWord {
              border-radius: 5rem;
              height:2rem;
              width: 2rem;
              background-color: #7049E2;
              background-image:
                -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
              background-image:
                -webkit-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                -moz-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                -o-linear-gradient(left, #7049E2, #9A6FED);
              background-image:
                linear-gradient(to right, #7049E2, #9A6FED);
              border:transparent;
              outline:none;
              -webkit-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              -moz-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
              box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            }
            .subTitle {
              font-family: 'Open Sans', sans-serif;
              color: #382C58;
              font-weight: 700;
              font-size: 0.75rem;
            }
            .inactiveSubTitle {
              font-family: 'Open Sans', sans-serif;
              color: #9A8AA8;
              font-weight: 700;
              font-size: 0.75rem;
            }
            .bt-violet {
              border-top: solid 2px #7049E2;
            }
            .bt-none {
              border-top: solid 2px transparent;
            }
            @-webkit-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            @-moz-keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            @keyframes fadeIn { from { opacity:0.6; } to { opacity:1; } }
            .fade-in {
              opacity:0.6;
              -webkit-animation:fadeIn ease-in 0.3s;
              -moz-animation:fadeIn ease-in 0.3s;
              animation:fadeIn ease-in 0.3s;

              -webkit-animation-fill-mode:forwards;
              -moz-animation-fill-mode:forwards;
              animation-fill-mode:forwards;
              -webkit-animation-duration:0.3s;
              -moz-animation-duration:0.3s;
              animation-duration:0.3s;
            }
          `}
        </style>
      </BlockContainer>
    )
  }
}

export default InvitedFriends;
