import React from 'react';
import propTypes from 'prop-types';
import ComponentDidMount from 'containers/ComponentDidMount';
import PathValidator from 'components/PathValidator';
import SubscribeModal from 'components/SubscribeModal';
import Sidebar from 'components/Sidebar';
import Notifications from 'components/Notifications';

import SendEmail from 'components/SendEmail';
import ReferralLinkBlock from 'components/ReferralLinkBlock';
import InvitedFriends from 'components/InvitedFriends';

const Referral = ({
  location,
  getGlobalSidebarData,
  router,
  fanPages,
  canRenderSidebar,
  selectPage,
  session,
  billing,
  userDataReady,
  invitations
}) => (
  <ComponentDidMount exec={()=> { getGlobalSidebarData(); invitations.getFriends()  }}>
    <PathValidator targetPath={router.state.pathname}><Notifications session={session} billing={billing} fanPages={fanPages}>
      <SubscribeModal
        showModal={fanPages.state.showModal}
        closeModal={fanPages.closeModal}
        getAll={fanPages.getAll}
        selectAll={fanPages.selectAll}
        fanPagesCollection={fanPages.state.fanPages}
        error={fanPages.state.error}
        subscribetoAllSelected={fanPages.subscribetoAllSelected}
        subscribeToggle={fanPages.subscribeToggle}
        getSubscribed={getGlobalSidebarData}
      >
        <div style={{minHeight: '43rem'}} className="flex flex-row w-100 h-100 bg-lightpink">
          {canRenderSidebar && <Sidebar
            selectPage={(id) => { selectPage(id) }}
            subscribedPages={fanPages.state.subscribedPages}
            currentPage={fanPages.state.currentPage}
            openModal={() => { fanPages.openModal() }}
            goTo={(v) => { router.goTo(v) }}
            location={location}
            logout={() => { session.logout() }}
          />}
          <div className="pa4 flex flex-column w-100">
            <div className="flex flex-row pb2">
              <h1 className="title ma2">Referral program</h1>
            </div>
            <div className="flex flex-row pb4">
              <span className="word ma2">Invite your friends and expand your tier! Receive 100 words for every signed up friend.</span>
            </div>
            <div className="flex flex-row w-100">
              <div className="flex justify-between flex-column w-100">
                <div className="pb3 ph2">
                  <SendEmail
                    emailInput={invitations.state.emailInput}
                    emailChangeHandler={invitations.emailChangeHandler}
                    invite={invitations.invite}
                  />
                </div>
                <div className="ph2 pt2">
                  <ReferralLinkBlock promoCode={session.state.user.promo_code} />
                </div>
              </div>
              <div className="flex flex-column h-auto w-100">
                <div className="pl3">
                  <InvitedFriends friendsList={invitations.state.friends}/>
                </div>
              </div>
            </div>
          </div>
          <style global jsx="true">
            {`
              ::placeholder {
                font-family: 'Open Sans', sans-serif;
                color: #9A8AA8;
                font-weight: 600;
                font-size: 0.55rem;
              }
            `}
          </style>
        </div>
        </SubscribeModal>
      </Notifications>
    </PathValidator>
  </ComponentDidMount>
)

export default Referral;
