import React from 'react';
import BlockContainer from 'components/BlockContainer';

const CurrentTier = ({
  usedWords,
  usedWordsTotal,
  totalWords,
  nextPayment,
  nextPaymentDate,
  subscriptionCancel,
  accountStatus,
  showSubscriptionCancelModalHandler,
}) => (
  <BlockContainer>
    <div className="pa4-ns pa3 relative h-100">
      <p className="subTitle ma0">Current Tier</p>
      { accountStatus === 'active' && (
      <div className="flex flex-row-ns flex-column pt4-ns pt3 items-center justify-between flex-wrap-m">
        <div className="flex flex-column justify-between items-start">
          <div className="flex flex-row w-100 items-center justify-start flex-wrap-m">
            <div className="flex flex-column justify-between items-start">
              <span className="wordsTotal pr2 pb1-m">{totalWords}</span>
              <span className="wordsUsedInfo pb1-m pb1">
                Lifetime Comments Analyzed:
                {usedWordsTotal}
              </span>
              <span className="wordsUsedInfo pb1-m">
                Comments Analyzed This Month
                {' '}
                {usedWords}
                {' '}
                out of
                {' '}
                {totalWords}
                {' '}
                words
              </span>
            </div>
          </div>
          <p className="paymentDate ma0 mt2">
            { subscriptionCancel ? 'Account will be cancelled on:' : 'Upcoming Payment on:' }
            {' '}
            <span className="fw7 projectBlack mh1">{nextPaymentDate}</span>
          </p>
          { !subscriptionCancel && (
          <p className="paymentDate ma0 mt2">
            Amount:
            {' '}
            <span className="fw7 projectBlack mh1">{nextPayment}</span>
          </p>
          )}
        </div>
          { !subscriptionCancel && (
          <button onClick={showSubscriptionCancelModalHandler} className="ml3-ns mt3 ph3 fw6 pointer ttu cancel nowrap">
            Cancel subscription
          </button>
          ) }
      </div>
      )}
      { accountStatus === 'canceled' && (
        <span className="wordsUsedInfo pr2 pb2 pb1-m">Canceled</span>
      )}
      <div className="absolute wordsTotalStatusBar">
        <div
          style={{
            width: `${(usedWords / totalWords) * 100}%`,
          }}
          className="wordsUsedStatusBar"
        />
      </div>
    </div>
    <style jsx="true">
      {`
        .wordsTotalStatusBar {
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #EDDDFB;
          height: 4px;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
        .wordsUsedStatusBar {
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          height: 4px;
        }
        .projectBlack {
          color: #382C58;
        }
        .wordsTotal {
          font-family: 'Montserrat', sans-serif;
          font-size: 0.8rem;
          font-weight: 600;
          background: -webkit-linear-gradient(#7049E2, #B235f4);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .wordsUsedInfo {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.65rem;
          font-weight: 500;
        }
        .paymentDate {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }
        .emptyBlockTitle {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.8rem;
          font-weight: 600;
        }
        .emptyBlockText {
          color: rgb(154, 138, 168);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight: 500;
        }

        .boldText {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 700;
          font-size: 0.6rem;
        }
        .social-icon {
          border-radius: 5rem;
          height: 2rem;
          width: 2rem;
        }
        .fb {
          border: solid 1px #484B99;
        }
        .tw {
          border: solid 1px #3A91F6;
        }
        .ln {
          border: solid 1px #236CB6;
        }
        .fg1 {
          flex-grow: 1;
        }
        button.cancel {
          border-radius: 5rem;
          height: 2rem;
          background-color: white;
          color: rgb(154, 138, 168);
          border-color: #EAD7FB;
          border-style: solid;
          border-width: 1px;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.5rem;
          font-weight:700;
        }
      `}
    </style>
  </BlockContainer>
);

export default CurrentTier;
