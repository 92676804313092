import React from 'react';
import propTypes from 'prop-types';
import SubscribeScreen from 'components/SubscribeScreen';

const SubscribeModal = ({
  children,
  showModal,
  closeModal,
  subscribeToggle,
  getAll,
  selectAll,
  fanPagesCollection,
  subscribetoAllSelected,
  getSubscribed,
  error,
  fanPages,
}) => (
  <div>
    {showModal
        && (
        <SubscribeScreen
          showModal={showModal}
          closeModal={closeModal}
          getAll={getAll}
          getSubscribed={getSubscribed}
          selectAll={selectAll}
          fanPagesCollection={fanPagesCollection}
          error={error}
          subscribeToggle={subscribeToggle}
          subscribetoAllSelected={subscribetoAllSelected}
          fanPages={fanPages}
        />
        )
        }
    {children}
  </div>
);

SubscribeModal.propTypes = {
  showModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  subscribeToggle: propTypes.func.isRequired,
  subscribetoAllSelected: propTypes.func.isRequired,
  getAll: propTypes.func.isRequired,
  selectAll: propTypes.func.isRequired,
  getSubscribed: propTypes.func.isRequired,
  fanPagesCollection: propTypes.array,
  error: propTypes.object,
  children: propTypes.node,
  fanPages: propTypes.object,
};

export default SubscribeModal;
