import { Container } from 'unstated';

const request = require('superagent');

class StopWordContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isBlackList: true,
      isWhiteList: null,
      blackList: [],
      whiteList: [],
      getListsError: null,
      loadingWhiteList: true,
      loadingBlackList: true,
      newWord: '',
    };
    this.getBlackList = this.getBlackList.bind(this);
    this.getWhiteList = this.getWhiteList.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addToList = this.addToList.bind(this);
    this.setList = this.setList.bind(this);
    this.onWordChange = this.onWordChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    const close = this.closeModal;
    window.addEventListener('keydown', (e) => {
      if ((e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27)) {
        close();
        return false;
      }
      return true;
    }, true);
  }

  getWhiteList(pageId) {
    request
      .get(`/api/v1/stop_words/?is_white=true&page_id=${pageId}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({ whiteList: response.body, loadingWhiteList: false });
        }
      });
  }

  getBlackList(pageId) {
    request
      .get(`/api/v1/stop_words/?is_black=true&page_id=${pageId}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error, response) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({ blackList: response.body, loadingWhiteList: false });
        }
      });
  }

  setList(list) {
    this.setState({
      isBlackList: list === 'blacklist',
      isWhiteList: list === 'whitelist',
    });
  }

  openModal() {
    this.setState({
      showModal: true,
      newWord: '',
    });
  }

  deleteItem(id) {
    const { blackList, whiteList } = this.state;
    request
      .delete(`/api/v1/stop_words/${id}`)
      .set('Authorization', localStorage.getItem('authToken'))
      .end((error) => {
        if (error) {
          this.setState({ error });
        } else {
          this.setState({
            blackList: [...blackList.filter(el => (el.id !== id))],
            whiteList: [...whiteList.filter(el => (el.id !== id))],
          });
        }
      });
  }

  addToList(e, pageId) {
    const {
      isBlackList, isWhiteList, blackList, whiteList, newWord,
    } = this.state;
    if (e.key === 'Enter') {
      request
        .post('/api/v1/stop_words')
        .set('Content-Type', 'application/json')
        .set('Authorization', localStorage.getItem('authToken'))
        .send({
          stop_word: {
            page_id: pageId,
            expression: newWord,
            is_black: isBlackList || !isWhiteList,
          },
        })
        .end((error, response) => {
          if (error) {
            this.setState({ error });
          } else if (isBlackList) {
            this.setState({ showModal: false, newWord: '', blackList: [...blackList, response.body] });
          } else if (isWhiteList) {
            this.setState({ showModal: false, newWord: '', whiteList: [...whiteList, response.body] });
          }
        });
    }
  }

  onWordChange(e) {
    this.setState({ newWord: e.target.value });
  }

  closeModal() {
    this.setState({ showModal: false, newWord: '' });
  }
}

export default StopWordContainer;
