import React from 'react';
import { TiSocialFacebookCircular, TiSocialInstagramCircular } from 'react-icons/ti';
import ReplayForm from 'components/ReplayForm';
import ReplayList from 'components/ReplayList';
import Loader from 'components/Loader';

const CommentsListItem = ({
  listItem,
  toggleComment,
  commentsContainer,
}) => (
  <div className="flex flex-row-ns flex-column w-100 bb-lightgrey pv2 justify-between h-auto">
    <div className="flex flex-column w-100 justify-center">
      <div className="flex flex-row items-center w-100">

        <div className="fl w-90">
          {listItem.is_hidden && <span className="hidden-flag pa1 ph2 br2 ttu white w-auto-ns w4 tc mr2">hidden</span>}
          {!listItem.is_hidden && <span className="unhidden-flag pa1 ph2 br2 ttu white w-auto-ns w4 tc mr2">passed</span>}
          <span className="comment">
            <a
              className="highlighted-word pl2 no-underline linkText"
              href={`${listItem.direct_comment_link}`}
              target="_blank"
              rel="noreferrer"
            >
              {`"${listItem.text}"`}
            </a>
          </span>
          <span className="pl1">
            { listItem.social === 'page' && <TiSocialFacebookCircular /> }
            { listItem.social === 'instagram' && <TiSocialInstagramCircular /> }
          </span>
        </div>

        <div className="fl w-10">
          <div
            onClick={() => {
              toggleComment(listItem);
            }}
            className="unhide-button pa1 ph2 br2 pointer ttu white w-auto-ns w4 tc"
          >
            {listItem.is_hidden && 'unhide'}
            {!listItem.is_hidden && 'hide'}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-start-ns justify-between pt2 items-center w-100">
        <div
          style={{
            width: '25px',
            height: '25px',
            borderRadius: '100%',
            background: `url(${listItem.avatar})`,
            backgroundPosition: 'center',
            backgroundSize: '100%',
          }}
          className="mr2 avatar"
        />
        <span className="highlighted-word pr2-ns pr1">{listItem.author}</span>
        <span className="word">commented on</span>
        <span className="flex">
          <a
            className="highlighted-word pl2 no-underline linkText"
            href={listItem.post}
            target="_blank"
            rel="noreferrer"
          >
            View Post
          </a>
        </span>
      </div>
      <div className="flex flex-row justify-start-ns justify-between pt2 items-center w-100">
        <div className="flex items-start">
          <div className="pa1">
            <a href="#" onClick={() => { commentsContainer.showReplayFormHandler(listItem); }} className="no-underline">
              <span className="word">Replay To Comment</span>
            </a>
          </div>
          <div className="flex pa1">
            <a href="#" onClick={(e) => { commentsContainer.showReplaysListHandler(e, listItem.id); }} className="no-underline">
              <span className="word">{ listItem.replayListDisplay ? 'Close' : 'View Comments' }</span>
            </a>
            {listItem.replaysLoading && (
                <div className="flex flex-column itemds-center justify-center br-pill pointer" style={{ height: '1.3rem', width: '1.3rem' }}>
                  <Loader />
                </div>
            )}
          </div>
        </div>
      </div>
      { listItem.replayListDisplay && <ReplayList comment={listItem} /> }
      { listItem.replayFormDisplay && <ReplayForm comment={listItem} commentsContainer={commentsContainer} /> }
    </div>
    <style jsx="true">
      {`
          @media (min-width: 30em) {
            .h5rem-ns {
              height:5rem;
            }
          }
          .hidden-flag {
            background-image: linear-gradient(to right,#FF0000,#FF0000);
            border: transparent;
            outline: none;
            font-size: 10px;
          }
          .unhidden-flag {
            background-image: linear-gradient(to right,#228B22,#228B22);
            border: transparent;
            outline: none;
            font-size: 10px;
          }
          .unhide-button {
            background-image: linear-gradient(to right,#7049E2,#9A6FED);
            -webkit-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            -moz-box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            box-shadow: 0px 6px 12px 1px rgba(224,198,249,1);
            border: transparent;
            outline: none;
            font-size: 10px;
          }
          .comment {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 600;
            font-size: 0.75rem;
          }
          .word {
            font-family: 'Open Sans', sans-serif;
            color: #9A8AA8;
            font-weight: 600;
            font-size: 0.65rem;
          }
          .highlighted-word {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.65rem;
          }
          .bb-lightgrey {
            border-bottom: 1px solid rgb(241, 241, 241);
          }
          .word-menu {
            outline:none;
            font-size: 1.3rem;
            font-family: 'Open Sans', sans-serif;
            color: white;
          }
        `}
    </style>
  </div>
);

export default CommentsListItem;
