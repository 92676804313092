import React from 'react';
import propTypes from 'prop-types';
import Menu from 'components/Menu';
import Pages from 'components/Pages';

const Sidebar = ({
  location,
  goTo,
  subscribedPages,
  currentPage,
  openModal,
  selectPage,
  logout
}) => {
  const menuItems = [
    {
      name: 'Home',
      active: location.pathname === '/dashboard',
      path: '/dashboard'
    },
    {
      name: 'Settings',
      active: location.pathname === '/settings',
      path: '/settings'
    },
    {
      name: 'Share',
      active: location.pathname === '/referral',
      path: '/referral'
    },
    {
      name: 'Logout',
      active: location.pathname === '/logout',
      path: '/logout'
    }
  ];

  return (
    <div className="h-100 left-sidebar justify-between bg-white sidebar-shadow flex flex-column items-center pt-2-and-half-rem" style={{minHeight: "43rem", height: "100vh"}}>
      <div className="flex flex-column items-center mh3" style={{minHeight: "15rem"}}>
        <a onClick={() => { goTo('/dashboard') }}>
          <img className="w-1-and-4-rem pb-2-and-half-rem pointer" src='/sg-logo.svg'/>
        </a>
        <Pages
          subscribedPages={subscribedPages}
          currentPage={currentPage}
          openModal={openModal}
          selectPage={selectPage}
        />
      </div>
      <div className="flex flex-column w-100 justify-end" style={{minHeight: "15rem"}}>
        <Menu
          menuItems={menuItems}
          goTo={(l) => { goTo(l) }}
          logout={() => { logout() }}
        />
      </div>
      <style jsx="true">
        {`
          .left-sidebar {
            width: 105px;
          }
          .pt-2-and-half-rem {
            padding-top: 2.5rem;
          }
          .pb-2-and-half-rem {
            padding-bottom: 2.5rem;
          }
          .w-1-and-4-rem {
            width: 1.4rem;
          }
          .sidebar-shadow {
            -webkit-box-shadow: 5px 0px 6px 0px rgb(240,238,246);
            -moz-box-shadow: 5px 0px 6px 0px rgb(240,238,246);
            box-shadow: 5px 0px 6px 0px rgb(240,238,246);
          }
        `}
      </style>
    </div>
  );
}

Sidebar.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string.isRequired
  }).isRequired,
  goTo: propTypes.func.isRequired,
  openModal: propTypes.func.isRequired,
  selectPage: propTypes.func.isRequired,
  logout: propTypes.func.isRequired,
  subscribedPages: propTypes.array,
  currentPage: propTypes.object
}

Sidebar.defaultProps = {
  subscribedPages: [],
  currentPage: null
}

export default Sidebar;
