import React, { Fragment } from 'react';
import ComponentDidMount from 'containers/ComponentDidMount';
import Connected from 'components/InstagramAccountBlock/AccountView/Connected';
import Disconnected from 'components/InstagramAccountBlock/AccountView/Disconnected';
import Loading from 'components/InstagramAccountBlock/Loading';
import _ from 'lodash';

const AccountView = ({ currentPage, instagram }) => {
  const { account, permState } = instagram.state;
  return (
    <ComponentDidMount exec={() => {
      instagram.getAccount(currentPage.id);
    }}
    >
      <Fragment>
        {_.isNil(account) && <Disconnected />}
        {!_.isNil(account) && (
          <Connected
            profileUrl={account.profile_pic}
            profileName={account.username}
            permState={permState}
          />
        )}
      </Fragment>
    </ComponentDidMount>
  );
};

export default AccountView;
