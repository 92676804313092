import React from 'react';
import _ from 'lodash';
import ComponentDidMount from 'containers/ComponentDidMount';
import DataTable from 'react-data-table-component';
import Loader from 'components/Loader';
import PageIcon from 'components/PageIcon';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

const columns = [
  {
    name: 'Facebook Page',
    selector: row => row.fbName,
  },
  {
    name: 'Subscribed',
    selector: row => row.subscribed,
  },
  {
    name: 'Instagram Account',
    selector: row => row.igAccount,
  },
];

const getAccount = (page) => {
  const firstEl = _.first(page.igAccounts);
  return _.isEmpty(page.igAccounts)
    ? '-' : _.isNull(firstEl)
      ? 'No name' : { name: _.startCase(_.toLower(firstEl.username)), url: firstEl.profile_pic };
};

const PageListDataTable = ({
  fanPages,
  instagram,
  facebook,
  reloadData
}) => {
  const { list } = fanPages.state;
  const { currentAccount } = facebook.state;
  const { dataLoading } = instagram.state;

  const data = !_.isEmpty(list) ? list.map(page => ({
    id: page.id,
    fbName: <PageIcon
      name={page.name}
      url={page.image}
    />,
    subscribed: page.subscribed ? <CheckIcon /> : <RemoveIcon />,
    igAccount: _.isEmpty(_.first(page.igAccounts)) ? <RemoveIcon /> : (
      <PageIcon
        name={getAccount(page).name}
        url={getAccount(page).url}
      />
    ),
  })) : [];

  return (
    <ComponentDidMount exec={() => {
    }}
    >
      <div className="flex flex-column w-100">
        <div className="flex flex-row justify-start items-center w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="200px"
            columns={columns}
            data={data}
            progressPending={dataLoading}
            progressComponent={<Loader center />}
          />
        </div>
        <div className="tr">
          <button
            onClick={() => {
              instagram.dataLoadStart();
              facebook.revokeIGPermissions(reloadData);
            }}
            className="ml3-ns mt3 ph3 fw6 pointer ttu cancel nowrap"
          >
            Revoke Instagram Permissions
          </button>
        </div>
      </div>
      <style jsx="true">
        {`
        button.cancel {
          border-radius: 5rem;
          height: 2rem;
          background-color: white;
          color: rgb(154, 138, 168);
          border-color: #EAD7FB;
          border-style: solid;
          border-width: 1px;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.5rem;
          font-weight:700;
        }
      `}
      </style>
    </ComponentDidMount>
  );
};

export default PageListDataTable;
