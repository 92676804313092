

const ADD_COUPON_CODE_FORM = 'ADD_COUPON_CODE_FORM';
const ADD_COUPON_CODE_ERROR = 'ADD_COUPON_CODE_ERROR';
const ADD_COUPON_CODE_SUCCESS = 'ADD_COUPON_CODE_SUCCESS';
const ADD_COUPON_CODE_PROCESSING = 'ADD_COUPON_CODE_PROCESSING';
const ADD_NEW_CARD_FORM = 'ADD_NEW_CARD_FORM';
const ADD_NEW_CARD_PROCESSING = 'ADD_NEW_CARD_PROCESSING';
const SESSION_SUBMIT_PW_NO_CCARD = 'SESSION_SUBMIT_PW_NO_CCARD';
const SESSION_SUBMIT_PW_LOGIN = 'SESSION_SUBMIT_PW_LOGIN';
const SESSION_SUBMIT_PW_ERROR = 'SESSION_SUBMIT_PW_ERROR';
const SESSION_SUBMIT_PW_SUCCESS = 'SESSION_SUBMIT_PW_SUCCESS';
const SESSION_SIGNUP_FORM_SUBMIT = 'SESSION_SIGNUP_FORM_SUBMIT';
const SESSION_SIGNUP_FORM_SUCCESS = 'SESSION_SIGNUP_FORM_SUCCESS';
const SESSION_SIGNUP_FORM_ERROR = 'SESSION_SIGNUP_FORM_ERROR';
const SIGNUP_FORM_INVALID_CODE_ERROR = 'SIGNUP_FORM_INVALID_CODE_ERROR';
const SESSION_SUBMIT_FB_SUCCESS = 'SESSION_SUBMIT_FB_SUCCESS';
const SESSION_SUBMIT_FB_AUTH = 'SESSION_SUBMIT_FB_AUTH';
const SESSION_SUBMIT_FB_ERROR = 'SESSION_SUBMIT_FB_ERROR';
const SESSION_PERMISSION_FB_GRANT_ERROR = 'SESSION_PERMISSION_FB_GRANT_ERROR';
const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
const INVALID_FACEBOOK = 'INVALID_FACEBOOK';
const SIGNUP_FORM_ACCOUNT_EXISTS_ERROR = 'SIGNUP_FORM_ACCOUNT_EXISTS_ERROR';
const UPDATE_NEW_SGEMAIL_FORM = 'UPDATE_NEW_SGEMAIL_FORM';
const UPDATE_NEW_SGEMAIL_PROCESSING = 'UPDATE_NEW_SGEMAIL_PROCESSING';
const UPDATE_NEW_SGPASSWORD_FORM = 'UPDATE_NEW_SGPASSWORD_FORM';
const UPDATE_NEW_SGPASSWORD_PROCESSING = 'UPDATE_NEW_SGPASSWORD_PROCESSING';
const INVALID_OLD_SGPASSWORD = 'INVALID_OLD_SGPASSWORD';
const INVALID_CONFIRM_SGPASSWORD = 'INVALID_CONFIRM_SGPASSWORD';
const UPDATE_NEW_SGEMAIL_EXISTS_ERROR = 'UPDATE_NEW_SGEMAIL_EXISTS_ERROR';
const FORGOT_PASSWORD_PROCESSING = 'FORGOT_PASSWORD_PROCESSING';
const FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM';
const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const LOGIN_USER_FORM_PROCESSING = 'LOGIN_FORM_PROCESSING';
const LOGIN_FB_FORM = 'LOGIN_FB_FORM';
const DASHBOARD = 'DASHBOARD';
const IG_ACCOUNT_CONNECTED = 'IG_ACCOUNT_CONNECTED';
const IG_ACCOUNT_DISCONNECTED = 'IG_ACCOUNT_DISCONNECTED';
const IG_ACCOUNT_PERMISSIONS_APPROVED = 'IG_ACCOUNT_PERMISSIONS_APPROVED';
const IG_ACCOUNT_PERMISSIONS_NOT_APPROVED = 'IG_ACCOUNT_PERMISSIONS_NOT_APPROVED';
const IG_ACCOUNT_PERMISSIONS_SUBMIT = 'IG_ACCOUNT_PERMISSIONS_SUBMIT';
const IG_ACCOUNTS_LOADING = 'IG_ACCOUNTS_LOADING';
const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING';
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export {
  RESET_PASSWORD_FORM,
  RESET_PASSWORD_PROCESSING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  IG_ACCOUNTS_LOADING,
  IG_ACCOUNT_CONNECTED,
  IG_ACCOUNT_DISCONNECTED,
  IG_ACCOUNT_PERMISSIONS_APPROVED,
  IG_ACCOUNT_PERMISSIONS_NOT_APPROVED,
  IG_ACCOUNT_PERMISSIONS_SUBMIT,
  ADD_COUPON_CODE_FORM,
  ADD_COUPON_CODE_ERROR,
  ADD_COUPON_CODE_SUCCESS,
  ADD_COUPON_CODE_PROCESSING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_FORM,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_SUCCESS,
  ADD_NEW_CARD_FORM,
  ADD_NEW_CARD_PROCESSING,
  SESSION_SUBMIT_PW_NO_CCARD,
  SESSION_SUBMIT_PW_LOGIN,
  SESSION_SUBMIT_PW_ERROR,
  SESSION_SUBMIT_PW_SUCCESS,
  SESSION_SIGNUP_FORM_SUBMIT,
  SESSION_SIGNUP_FORM_SUCCESS,
  SESSION_SIGNUP_FORM_ERROR,
  SIGNUP_FORM_ACCOUNT_EXISTS_ERROR,
  SIGNUP_FORM_INVALID_CODE_ERROR,
  SESSION_SUBMIT_FB_AUTH,
  SESSION_SUBMIT_FB_ERROR,
  SESSION_SUBMIT_FB_SUCCESS,
  SESSION_PERMISSION_FB_GRANT_ERROR,
  INVALID_CREDENTIALS,
  INVALID_FACEBOOK,
  UPDATE_NEW_SGEMAIL_FORM,
  UPDATE_NEW_SGEMAIL_PROCESSING,
  UPDATE_NEW_SGEMAIL_EXISTS_ERROR,
  UPDATE_NEW_SGPASSWORD_FORM,
  UPDATE_NEW_SGPASSWORD_PROCESSING,
  INVALID_OLD_SGPASSWORD,
  INVALID_CONFIRM_SGPASSWORD,
  LOGIN_USER_FORM_PROCESSING,
  LOGIN_FB_FORM,
  DASHBOARD,
};
