import React from 'react';
import { func } from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardFormSettings from 'components/CardFormSettings';

const FormState = ({ submitCardHandler, cancelHandler }) => {
  const stripePromise = loadStripe(__stPk);
  return (
    <div className="pv4-ns pv3">
      <Elements stripe={stripePromise}>
        <CardFormSettings
          cancelText="Cancel"
          cancelHandler={cancelHandler}
          handlePayload={(pl) => {
            submitCardHandler(pl);
          }}
        />
      </Elements>
      <style jsx="true" global="true">
        {`
        .StripeElement {
          padding: 14px;
          border-radius: 0.25rem;
          border: solid 1px #EAD7FB;
        }
        .StripeElement:hover {
          border: solid 1px #7049E2;
        }
      `}
      </style>
      <style jsx="true" global="true">
        {`
        .step {
          width: 28px;
          height: 28px;
          padding-top: 3px;
          border: 2px solid white;
          border-radius: 2rem;
        }
        .active-step {
          color: #A94AEF;
          border: 2px solid #A94AEF;
          border-radius: 2rem;
        }
        .outlineButton {
          margin-right:0.5rem;
          color: #7049E2;
          border: 1px solid #EAD7FB;
          border-radius: 5rem;
          background: transparent;
          height: 3rem;
          line-height: 2.9rem;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        .filledButton {
          margin-left:0.5rem;
          color: white;
          border-radius: 5rem;
          height: 3rem;
          line-height: 2.9rem;
          background-color: #7049E2;
          background-image: -webkit-gradient(linear, left top, right top, from(#7049E2), to(#9A6FED));
          background-image: -webkit-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -moz-linear-gradient(left, #7049E2, #9A6FED);
          background-image: -o-linear-gradient(left, #7049E2, #9A6FED);
          background-image: linear-gradient(to right, #7049E2, #9A6FED);
          border: 1px solid transparent;
          outline: none;
          -webkit-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          -moz-box-shadow: 0px 4px 14px -1px rgba(224,198,249,1);
          box-shadow: 0px 4px 14px -1px  rgba(224,198,249,1);
          font-family: 'Montserrat', sans-serif;
          font-size: 0.6rem;
          font-weight:600;
          width: 10rem;
        }
        input {
          outline: none;
          padding-top:0;
          padding-bottom:0;
          width: auto;
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 600;
          font-size: 0.65rem;
          border: none;
          height: 1rem;
          line-height: 1rem;
        }
        div.inputWrapper {
          height: 3rem;
          padding-left: 1rem;
          padding-right:1rem;
          border-radius: 0.25rem;
          border: solid 1px #EAD7FB;
        }
        div.valid {
          border: solid 1px #EAD7FB;
        }
        div.inputWrapper:hover {
          border: solid 1px #7049E2;
        }
        div.active {
          border: solid 1px #7049E2;
        }
        div.invalid {
          border: solid 1px #F65763;
        }
        .mistake {
          font-family: 'Open Sans', sans-serif;
          color: #F65763;
          font-weight: 700;
          font-size: 0.6rem;
          padding-top:0.2rem;
        }
        .label {
          font-family: 'Open Sans', sans-serif;
          color: #382C58;
          font-weight: 700;
          font-size: 0.6rem;
          padding-top:0.2rem;
        }
        .login-block-wrapper {
          width: 50rem;
        }
      `}
      </style>
    </div>
  );
};

FormState.propTypes = {
  submitCardHandler: func.isRequired,
  cancelHandler: func.isRequired,
};

export default FormState;
