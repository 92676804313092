import React from 'react';

const FbPageView = ({ page, name, pageIconUrl }) => (
  <div className="flex flex-column justify-start items-center pr3">
    <p className="boldText ma0 pb1">Current FB Page</p>
    <div
      style={{
        width: '5rem',
        height: '5rem',
        borderRadius: '4rem',
        background: `url(${pageIconUrl(page)})`,
        backgroundPosition: 'center',
        backgroundSize: '100%',
      }}
      className="shadow-for-page"
    />
    <span className="boldText ma0 pt2 pb1">{name}</span>
    <style jsx="true">
      {`
          .boldText {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.6rem;
           }
          .shadow-for-page {
            -webkit-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            -moz-box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
            box-shadow: 0px 7px 25px -3px rgba(224,198,249,1);
          }
          .highlighted-word {
            font-family: 'Open Sans', sans-serif;
            color: #382C58;
            font-weight: 700;
            font-size: 0.75rem;
          }
          .word-menu {
            outline:none;
            font-size: 1.3rem;
            font-family: 'Open Sans', sans-serif;
            color: #9A8AA8;
          }
        `}
    </style>
  </div>
);

export default FbPageView;
