import { Container } from 'unstated';
import _ from 'lodash';

import {
  LOGIN_USER_FORM_PROCESSING,
  FORGOT_PASSWORD_FORM,
  FORGOT_PASSWORD_PROCESSING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FORM,
  RESET_PASSWORD_PROCESSING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  DASHBOARD,
} from 'appEvents';

const request = require('superagent');
const ActionCable = require('actioncable');

class UserContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      authToken: localStorage.getItem('authToken'),
      user: null,
      userRequested: false,
      status: null,
      error: null,
      email: '',
      password: '',
      connectedToUpdates: false,
      forgotPassword: {
        show: false,
        email: '',
        error: '',
        status: FORGOT_PASSWORD_FORM,
      },
      resetPassword: {
        password: '',
        confirmPassword: '',
        error: '',
        status: RESET_PASSWORD_FORM,
      },
      showErrorModal: false,
      modalErrorMessage: '',
    };
    this.getUserData = this.getUserData.bind(this);
    this.changeLoginHandler = this.changeLoginHandler.bind(this);
    this.submitLoginHandler = this.submitLoginHandler.bind(this);
    this.submitLogoutHandler = this.submitLogoutHandler.bind(this);
    this.setUserStatus = this.setUserStatus.bind(this);
    this.connectToUpdatesChannel = this.connectToUpdatesChannel.bind(this);
    this.showForgotPassword = this.showForgotPassword.bind(this);
    this.hideForgotPassword = this.hideForgotPassword.bind(this);
    this.submitForgotEmail = this.submitForgotEmail.bind(this);
    this.onForgotEmailChange = this.onForgotEmailChange.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onconfirmPasswordChange = this.onconfirmPasswordChange.bind(this);
    this.validateFormInput = this.validateFormInput.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.checkBillingStatus = this.checkBillingStatus.bind(this);
    this.checkPausedState = this.checkPausedState.bind(this);
  }

  checkPausedState() {
    const { status } = this.state.user.billing;
    if (status === 'paused') {
      this.setState({
        showErrorModal: true,
        modalErrorMessage: 'Free Trial Period ended. Please add your payment information in the Settings page.',
      });
    } else {
      this.setState({
        showErrorModal: false,
        modalErrorMessage: '',
      });
    }
  }

  checkBillingStatus() {
    const { status, payment } = this.state.user.billing;

    if (status === 'trial_ended') {
      this.setState({
        showErrorModal: true,
        modalErrorMessage: `Free trial period ended ${payment === false ? ', please add your payment method in the Settings page.' : ', please check your payment method.'}`,
      });
    }

    if (status === 'past_due') {
      this.setState({
        showErrorModal: true,
        modalErrorMessage: `Account Past Due, ${payment === false ? 'please Add at least one Payment Method in the Setting page.' : 'we are not able to process your payment method.'}`,
      });
    }

    if (status === 'unpaid') {
      this.setState({
        showErrorModal: true,
        modalErrorMessage: `Your Account has been Suspended, ${payment === false ? 'Please Add at least one Payment Method in the Setting page.' : 'We are not able to process your payment method.'}`,
      });
    }

    if (status === 'canceled') {
      this.setState({
        showErrorModal: true,
        modalErrorMessage: 'Your Account Canceled!',
      });
    }
  }

  closeErrorModal() {
    this.setState({
      showErrorModal: false,
      modalErrorMessage: '',
    });
  }

  validateFormInput(e) {
    const { name, value } = e.target;
    const pwdPattern = new RegExp('^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){1,}).{6,}$');
    const emailPattern = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$');
    switch (name) {
      case 'email':
        if (!emailPattern.test(value)) {
          this.setState({ forgotPassword: { ...this.state.forgotPassword, error: 'Wrong Email' } });
          return;
        }
        this.setState({ forgotPassword: { ...this.state.forgotPassword, error: '' } });
        break;
      case 'password':
      case 'confirmPassword':
        if (value.length < 6) {
          this.setState({ resetPassword: { ...this.state.resetPassword, error: _.capitalize(`${name} is to short`) } });
          return;
        }
        if (value.length > 50) {
          this.setState({ resetPassword: { ...this.state.resetPassword, error: _.capitalize(`${name} is to long`) } });
          return;
        }
        if (!pwdPattern.test(value)) {
          this.setState({ resetPassword: { ...this.state.resetPassword, error: _.capitalize(`${name} not secure`) } });
          return;
        }
        if (this.state.resetPassword.confirmPassword.length > 1
            && this.state.resetPassword.confirmPassword !== this.state.resetPassword.password) {
          this.setState({ resetPassword: { ...this.state.resetPassword, error: _.capitalize('Passwords does not match') } });
          return;
        }
        this.setState({ resetPassword: { ...this.state.resetPassword, error: '' } });
        break;
      default:
        this.setState({ resetPassword: { ...this.state.resetPassword, error: '' } });
    }
  }

  onconfirmPasswordChange(e) {
    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        confirmPassword: e.target.value,
      },
    });
  }

  onPasswordChange(e) {
    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        password: e.target.value,
      },
    });
  }

  submitResetPassword(token) {
    const { password, confirmPassword } = this.state.resetPassword;
    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        status: RESET_PASSWORD_PROCESSING,
        error: '',
      },
    });
    request
      .post('/password/reset')
      .set('Content-Type', 'application/json')
      .send({
        forgot: { token, password, confirmPassword },
      })
      .end((error, response) => {
        if (error) {
          this.setState({
            resetPassword: {
              ...this.state.resetPassword,
              status: RESET_PASSWORD_ERROR,
              error: response.body.error && response.body.error,
            },
          });
        } else {
          this.setState({
            resetPassword: {
              ...this.state.resetPassword,
              status: RESET_PASSWORD_SUCCESS,
              error: '',
            },
          });
        }
      });
  }

  showForgotPassword() {
    this.setState({ forgotPassword: { ...this.state.forgotPassword, show: true } });
  }

  hideForgotPassword() {
    this.setState({ forgotPassword: { ...this.state.forgotPassword, show: false } });
  }

  submitForgotEmail() {
    console.log('submitForgotEmail');

    const { email } = this.state.forgotPassword;
    this.setState({
      forgotPassword: {
        ...this.state.forgotPassword,
        status: FORGOT_PASSWORD_PROCESSING,
        error: '',
      },
    });
    request
      .post('/password/forgot')
      .set('Content-Type', 'application/json')
      .send({
        forgot: { email },
      })
      .end((error, response) => {
        if (error) {
          console.log('response.body: ', response.body);
          this.setState({
            forgotPassword: {
              ...this.state.forgotPassword,
              status: FORGOT_PASSWORD_ERROR,
              error: response.body.error && response.body.error,
            },
          });
        } else {
          this.setState({
            forgotPassword: {
              ...this.state.forgotPassword,
              status: FORGOT_PASSWORD_SUCCESS,
              error: '',
            },
          });
        }
      });
  }

  onForgotEmailChange(e) {
    this.setState({ forgotPassword: { ...this.state.forgotPassword, email: e.target.value } });
  }

  connectToUpdatesChannel(eventHandler) {
    const { connectedToUpdates } = this.state;
    if (connectedToUpdates === false) {
      const host = location.hostname + (location.port ? `:${location.port}` : '');
      const protocol = (location.protocol === 'https:') ? 'wss' : 'ws';
      const cable = ActionCable.createConsumer(`${protocol}://${host}/cable?wst=${localStorage.getItem('authToken')}`);
      cable.subscriptions.create('UpdatesChannel', {
        connected: () => {
          this.setState({ connectedToUpdates: true });
        },
        disconnected: () => {
          this.setState({ connectedToUpdates: false });
        },
        received: (ev) => {
          ev.payload = JSON.parse(ev.payload);
          eventHandler(ev);
        },
      });
    }
  }

  setUserStatus(status) {
    this.setState({ status });
  }

  getUserData(cb) {
    const authToken = this.state.authToken || localStorage.getItem('authToken');
    if (authToken) {
      request
        .get('/api/users/current')
        .set('Content-Type', 'application/json')
        .set('Authorization', authToken)
        .end((error, res) => {
          if (error) {
            this.setState({
              error,
            });
            cb && cb(error);
          } else {
            this.setState({
              error: null,
              user: res.body,
              status: DASHBOARD,
            });
            cb && cb();
            this.checkBillingStatus();
            this.checkPausedState();
          }
        });
    }
    return true;
  }

  changeLoginHandler(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  submitLoginHandler() {
    const { email, password } = this.state;
    this.setState({ status: LOGIN_USER_FORM_PROCESSING });
    request
      .post('/api/v1/authentication/create')
      .set('Content-Type', 'application/json')
      .send({ credentials: { email, password } })
      .end((error, response) => {
        const { token } = response.body;
        if (error) {
          localStorage.removeItem('authToken');
          this.setState({
            email,
            password,
            error: response.body.error,
            status: null,
            authToken: null,
          });
        } else {
          localStorage.setItem('authToken', token);
          this.setState({
            error: null,
            email,
            password,
            status: DASHBOARD,
            authToken: token,
          });
        }
      });
  }

  submitLogoutHandler() {
    localStorage.removeItem('authToken');
    this.setState({
      user: null, authToken: null, userRequested: false, error: null, status: null,
    });
  }
}

export default UserContainer;
