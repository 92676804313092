import React from 'react';

const CouponCodeElement = ({registration}) =>
    <div className="StripeElement mb3">
        <label className="Input">
            <input type="text"
                   autoCorrect="off"
                   spellCheck="false"
                   placeholder="Coupon code"
                   aria-placeholder="Coupon code"
                   aria-label="Coupon code"
                   aria-invalid="false"
                   name="coupon_id"
                   className="InputElementCoupon"
                   value={registration.state.coupon_id}
                   onChange={registration.changeUserDetailsHandler}/>
        </label>
        <style jsx="true">
          {`
           .InputElementCoupon:-moz-placeholder {
                color: #aab7c4;
                font-size: 14px;
                font-family: "Montserrat, sans-serif";
                font-weight: 100;
                opacity: 1;
           }
           .InputElementCoupon::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #aab7c4;
                font-size: 14px;
                font-family: "Montserrat, sans-serif";
                font-weight: 100;
                opacity: 1;
           }
           .InputElementCoupon:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #aab7c4;
                font-size: 14px;
                font-family: "Montserrat, sans-serif";
                font-weight: 100;
                opacity: 1;
           }
           .InputElementCoupon::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: #aab7c4;
                font-size: 14px;
                font-family: "Montserrat, sans-serif";
                font-weight: 100;
                opacity: 1;
              }
          `}
        </style>
    </div>;

export default CouponCodeElement;