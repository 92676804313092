import React from 'react';

class ComponentDidMount extends React.Component {

  componentDidMount() {
    const { exec } = this.props;
    exec();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ComponentDidMount;
