import React from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { plan_list, plan_id } = props.registration.state;

    if (!stripe || !elements) {
      console.log('Stripe.js has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then((result) => {
      props.registration.stripeCallbackHandler(result,
        props.fanPagesList,
        props.userContainer);
    });

    fbq('track', 'Purchase', {
      value: plan_list.find(plan => plan.value === plan_id).price,
      currency: 'USD',
    });
  };

  const prevStep = () => {
    props.registration.prevStep();
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              color: '#424770',
              fontFamily: 'Open Sans,sans-serif',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: 'red',
            },
          },
        }}
      />

      <div className="flex flex-row justify-between items-center w-100 mt3">
        <button className="ttu pointer outlineButton" onClick={() => { prevStep(); }}>previous step</button>
        <button className="ttu pointer filledButton">finish</button>
      </div>
    </form>
  );
};

export default CardForm;
